import React, {useState} from 'react';
import {Platform, View, ViewStyle, Image, Linking} from 'react-native';
import {useSelector} from 'react-redux';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {faUser} from '@fortawesome/pro-solid-svg-icons/faUser';
import {faMapLocationDot} from '@fortawesome/pro-solid-svg-icons/faMapLocationDot';
import {DrawerNavigationProp, useDrawerStatus} from '@react-navigation/drawer';
import {useNavigation} from '@react-navigation/native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Theme, Font, Components, Utils} from '@oneamerica/dxp-ui-components';
import {Images} from '../../../assets';
import responsiveStyles from './styles';
import RouteConstants from '../../constants/RouteConstants';
import {getCookieValue, getDecodedCookie} from '../../utils/cookies';
import {
  ACCOUNT_NAVIGATOR_URL_PH,
  AEM_API_BASE_URL,
} from '../../config/getEnvironmentVars';
import ColorConstants from '../../constants/ColorConstants';
import {trackCustomEvent} from '../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';
import {formatDate} from '../../utils';
interface Props {
  isBackEnabled: boolean;
  collapse: boolean;
  headerVisible: boolean;
  menu: boolean;
  currentRoute: string;
  authLogout: () => void;
}

const Header = ({
  menu,
  isBackEnabled,
  collapse,
  currentRoute,
  authLogout,
}: Props) => {
  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const onBack = () => navigation.goBack();
  const onMenu = () => navigation?.openDrawer();
  const {top} = useSafeAreaInsets();
  const topInset = top ?? 0;
  const styles = responsiveStyles(topInset);
  const isDrawerOpen = useDrawerStatus() === 'open';
  const [hoverIndex, setHoverIndex] = useState(-1);
  const {
    isError = false,
    loading = true,
    ...policyData
  } = useSelector((state: any) => state.policies);
  const {list, error} = useSelector((state: any) => state.policyLists);

  const investmentsData =
    policyData.getPoliciesListData?.data?.policySummary?.policyHighlights[0]
      .investments;
  const investmentFlag =
    policyData.getPoliciesListData?.data?.policySummary?.policyHighlights[0]
      ?.accountConfig.allowMyInvestments === true;
  const statusInfo =
    policyData.getPoliciesListData?.data?.policySummary?.policyHighlights[0]
      .statusInfo;
  const strategiesData =
    policyData.getPoliciesListData?.data?.policySummary?.policyHighlights[0]
      ?.accountConfig.allowMyStrategies === true;
  const reportFlag =
    policyData.getPoliciesListData?.data?.policySummary?.policyHighlights[0]
      ?.accountConfig.allowReports === true;
  // Import in icons
  const {arrowLeft, xMark, bars, logOff, house} = Utils.icons;

  const isNavVisible = () =>
    !isError && !loading && !error && list !== undefined;

  // Get last login date from cookie
  const decodedCookie = getDecodedCookie('ACCOUNT_DETAILS');
  const lastLoginDateTime = decodedCookie?.lastLoginDateTime;

  const mapDisplayPolicyHolder = getDecodedCookie('ACCOUNT_DETAILS');

  const accountNavigate = mapDisplayPolicyHolder?.BacktoAccNav;

  const accountToken = getCookieValue('ACCOUNT_DETAILS_TOKEN');

  // Format the last login date
  const lastLoginDate = formatDate(lastLoginDateTime, 'MM/DD/YYYY');

  const isPolicyActive = !['Terminated', 'Suspended'].includes(
    statusInfo?.policyStatus,
  );

  const showManageAccountSubmenu =
    investmentFlag && investmentsData?.length > 0;
  const showAccntNavigatorLink = accountToken ?? accountNavigate;

  const tabs: any[] = [
    ...(isPolicyActive && isNavVisible()
      ? [
          {
            id: RouteConstants.policydetails,
            name: 'POLICY DETAILS',
          },
        ]
      : []),
    isNavVisible() && {
      id: 'manage-account',
      name: 'MANAGE ACCOUNT',
      dropdown: [
        ...(showManageAccountSubmenu
          ? [
              {
                title: 'My Investments',
                route: RouteConstants.myinvestments,
                bold: true,
              },
              {
                title: 'Current Balance',
                route: RouteConstants.myinvestments,
                params: {tab: 'Current-Balance'},
              },
              {
                title: 'Future Elections',
                route: RouteConstants.myinvestments,
                params: {tab: 'Future-Elections'},
              },
              {divider: true},
            ]
          : []),
        ...(strategiesData
          ? [
              {
                title: 'My Strategies',
                route: RouteConstants.mystrategies,
                bold: true,
              },
              {divider: true},
            ]
          : []),
        ...(reportFlag
          ? [
              {
                title: 'My Reports',
                route: RouteConstants.myreports,
                bold: true,
              },
              {divider: true},
            ]
          : []),

        {
          title: 'Policy Service Request',
          route: RouteConstants.policyservicerequest,
          bold: true,
        },
      ],
    },
    {
      id: RouteConstants.contactus,
      name: 'CONTACT US',
    },
  ];

  const linkStyle = (dropdown) => {
    const style = [
      {
        fontSize: dropdown.bold ? 16 : 14,
        fontFamily: dropdown.bold
          ? Font.medium.fontFamily
          : Font.regular.fontFamily,
        color: dropdown.bold ? Theme.Color.ice6 : Theme.Color.text,
      },
      styles.cursorPointer,
    ] as ViewStyle;
    return {
      hoverStyle: style,
      linkHoverStyle: style,
      linkStyle: style,
    };
  };

  const openURL = (url) => {
    //@ts-ignore
    Linking.openURL(url, '_self').catch((err) =>
      console.error('An error occurred', err),
    );
  };
  const [tooltipVisibleProfile, setTooltipVisibleProfile] = useState(false);
  const [tooltipVisibleLogoff, setTooltipVisibleLogoff] = useState(false);
  const [tooltipVisibleAccountNav, setTooltipVisibleAccountNav] =
    useState(false);

  const addAnalytics = (pageName) => {
    if (pageName === 'POLICY DETAILS') {
      trackCustomEvent(analyticsTags.CapturePolicyDetailsIconTags);
    } else if (pageName === 'CONTACT US') {
      trackCustomEvent(analyticsTags.CaptureContactUsTags);
    } else if (pageName === 'HOME') {
      trackCustomEvent(analyticsTags.CaptureHomeIconTags);
    } else if (pageName === 'My Investments') {
      trackCustomEvent(analyticsTags.CaptureHomeInvestmentViewDetailsTags);
    } else if (pageName === 'My Strategies') {
      trackCustomEvent(analyticsTags.CaptureMyStrategiesTags);
    } else if (pageName === 'Policy Service Request') {
      trackCustomEvent(analyticsTags.CapturePolicyServiceRequestTags);
    } else if (pageName === 'Current Balance') {
      trackCustomEvent(analyticsTags.CaptureCurrentBalanceTags);
    } else if (pageName === 'Future Elections') {
      trackCustomEvent(analyticsTags.CaptureFutureElectionTags);
    } else if (pageName === 'My Profile') {
      trackCustomEvent(analyticsTags.CaptureMyProfileIconTags);
    }
  };

  const renderHamburgerMenu = () => {
    return (
      <View className="md:hidden mr-3">
        <Components.Link
          onPress={onMenu}
          accessibilityRole="button"
          accessibilityLabel={
            !isDrawerOpen
              ? 'Click the hamburger menu to open side menu'
              : 'Close the side menu'
          }
          focusStyle={
            {
              outlineWidth: 1,
              outlineColor: Theme.Color.white,
              outlineStyle: 'solid',
              outlineOffset: 1,
              padding: 0,
            } as ViewStyle
          }
          testID="header:menu:menu_icon">
          {isDrawerOpen ? (
            <FontAwesomeIcon icon={xMark as IconProp} size={20} />
          ) : (
            <FontAwesomeIcon icon={bars as IconProp} size={20} />
          )}
        </Components.Link>
      </View>
    );
  };
  const renderOALogo = () => {
    return (
      <Components.Link
        accessibilityLabel="One America logo Image"
        accessibilityRole="button"
        onPress={() => {
          trackCustomEvent(analyticsTags.CaptureOneAmericaFinanciallogoTags);
          openURL(AEM_API_BASE_URL);
        }}
        testID="header:logo"
        focusStyle={
          {
            outlineWidth: 1,
            outlineColor: Theme.Color.ice4,
            outlineStyle: 'solid',
            outlineOffset: 1,
            padding: 0,
          } as ViewStyle
        }>
        <Image
          source={Images.oaLogo}
          alt="OneAmerica logo"
          style={{
            width: 159,
            height: 40,
          }}
          resizeMode="contain"
        />
      </Components.Link>
    );
  };

  const renderSubmenu = (item) => {
    return (
      <View style={styles.cardShadow}>
        {item?.dropdown?.map((dropdown, index) =>
          dropdown?.divider ? (
            <View
              key={`${dropdown.title}_${index}`}
              className="my-2"
              style={styles.horizontalDivider}
            />
          ) : (
            <Components.Link
              key={`${dropdown.title}_${index}`}
              onPress={() => {
                addAnalytics(dropdown.title);
                if (dropdown?.route) {
                  navigation.navigate(dropdown.route, dropdown.params);
                }
              }}
              accessibilityRole="button"
              accessibilityLabel={dropdown.title + 'navigation submenu'}
              className="py-2"
              {...linkStyle(dropdown)}>
              {dropdown.title}
            </Components.Link>
          ),
        )}
      </View>
    );
  };
  const renderMenuLinks = () => {
    return (
      Platform.OS === 'web' && (
        <View className="hidden md:flex flex-row items-center">
          <Components.Link
            key={RouteConstants.home}
            onPress={() => {
              navigation.navigate(RouteConstants.home);
              addAnalytics('HOME');
            }}
            className={`border-b-[6px] border-t-transparent-[6px] px-5 h-full`}
            style={{
              borderColor: Theme.Color.white,
            }}
            color={Theme.Color.black}
            hoverStyle={styles.navHover}
            focusStyle={{textDecorationLine: 'none'}}
            accessibilityRole="button"
            accessibilityLabel="Home navigation icon"
            testID={`header:desktop_menu:link_${RouteConstants.home}`}>
            <FontAwesomeIcon
              icon={house as IconProp}
              color={Theme.Color.gray6}
              size={18}
              style={styles.outlineNone as ViewStyle}
            />
          </Components.Link>
          {tabs.map((item, idx) => {
            return (
              <View
                className="h-full"
                key={`${item.id}_${idx}`}
                {...(Platform.OS === 'web' && {
                  onMouseEnter: () => {
                    if (item.dropdown) {
                      setHoverIndex(idx);
                    }
                  },
                })}
                {...(Platform.OS === 'web' && {
                  onMouseLeave: () => {
                    hoverIndex !== -1 && setHoverIndex(-1);
                  },
                })}>
                <Components.Link
                  onPress={() => {
                    addAnalytics(item.name);
                    if (!item.dropdown) {
                      navigation.navigate(item.id);
                    }
                  }}
                  onFocus={() => {
                    if (item.dropdown) {
                      setHoverIndex(idx);
                    } else if (hoverIndex + 1 === idx) {
                      hoverIndex !== -1 && setHoverIndex(-1);
                    }
                  }}
                  onOut={() => {
                    if (
                      Platform.OS === 'web' &&
                      ('ontouchstart' in window ||
                        //@ts-ignore
                        navigator.maxTouchPoints > 0 ||
                        //@ts-ignore
                        navigator.msMaxTouchPoints > 0)
                    ) {
                      hoverIndex !== -1 && setHoverIndex(-1);
                    }
                  }}
                  className={`border-b-[6px] border-t-transparent-[6px] mx-5 h-full${
                    idx === tabs.length - 1 ? ' mr-0' : ''
                  }`}
                  style={{
                    borderColor: Theme.Color.white,
                  }}
                  color={Theme.Color.gray6}
                  hoverStyle={styles.navHover}
                  focusStyle={{textDecorationLine: 'none'}}
                  accessibilityLabel={item.name + 'navigation menu'}
                  accessibilityRole="button"
                  testID={`header:desktop_menu:link_${item.id}`}>
                  <Components.Text
                    className="uppercase"
                    testID={`header:desktop_menu:link-child-${item.id}`}
                    size={14}>
                    {item.name}
                  </Components.Text>
                </Components.Link>
                {item.dropdown && hoverIndex === idx && (
                  <View className="z-[100]" style={styles.menuItem}>
                    {renderSubmenu(item)}
                  </View>
                )}
              </View>
            );
          })}
        </View>
      )
    );
  };

  const renderMyProfile = () => {
    return (
      <View
        className="mr-6"
        {...(Platform.OS === 'web' && {
          onMouseEnter: () => {
            setTooltipVisibleProfile(true);
          },
        })}
        {...(Platform.OS === 'web' && {
          onMouseLeave: () => {
            setTooltipVisibleProfile(false);
          },
        })}
        accessibilityLabel="My Profile"
        accessibilityRole="button">
        <Components.Link
          onPress={() => {
            navigation.navigate(RouteConstants.profile);
            addAnalytics('My Profile');
          }}
          onFocus={() => setTooltipVisibleProfile(true)}
          onBlur={() => setTooltipVisibleProfile(false)}
          hoverStyle={{textDecorationLine: 'none'}}
          focusStyle={
            {
              outlineWidth: 1,
              outlineColor: Theme.Color.white,
              outlineStyle: 'solid',
              outlineOffset: 1,
              textDecorationLine: 'none',
            } as ViewStyle
          }>
          {!accountToken && (
            <FontAwesomeIcon
              icon={faUser as IconProp}
              color={Theme.Color.white}
              size={16}
            />
          )}
          {!accountToken && tooltipVisibleProfile && Platform.OS === 'web' && (
            <Components.Text
              style={
                {
                  position: 'absolute',
                  top: -3,
                  right: 20,
                  transform: 'none',
                  maxWidth: 120,
                  whiteSpace: 'nowrap',
                  overflow: 'visible',
                  backgroundColor: ColorConstants.lightBlue,
                  padding: 5,
                  borderRadius: 4,
                  fontSize: 12,
                  elevation: 5,
                  color: ColorConstants.oaBlueTint,
                  fontFamily: 'sans-serif',
                } as ViewStyle
              }>
              My Profile
            </Components.Text>
          )}
        </Components.Link>
      </View>
    );
  };
  const renderLogout = () => {
    return (
      <View
        className="h-full"
        {...(Platform.OS === 'web' && {
          onMouseEnter: () => {
            setTooltipVisibleLogoff(true);
          },
        })}
        {...(Platform.OS === 'web' && {
          onMouseLeave: () => {
            setTooltipVisibleLogoff(false);
          },
        })}
        accessibilityLabel="Log off"
        accessibilityRole="button">
        <Components.Link
          onPress={() => {
            trackCustomEvent(analyticsTags.CaptureLogoutIconTags);
            authLogout();
            trackCustomEvent(analyticsTags.CaptureLogoutSuccessTags);
          }}
          onFocus={() => setTooltipVisibleLogoff(true)}
          onBlur={() => setTooltipVisibleLogoff(false)}
          hoverStyle={{textDecorationLine: 'none'}}
          focusStyle={
            {
              outlineWidth: 1,
              outlineColor: Theme.Color.white,
              outlineStyle: 'solid',
              outlineOffset: 1,
              padding: 0,
              textDecorationLine: 'none',
            } as ViewStyle
          }>
          <FontAwesomeIcon
            icon={logOff as IconProp}
            color={Theme.Color.white}
            size={16}
          />
          {tooltipVisibleLogoff && Platform.OS === 'web' && (
            <Components.Text
              style={
                {
                  position: 'absolute',
                  top: -3,
                  right: 20,
                  transform: 'none',
                  maxWidth: 120,
                  whiteSpace: 'nowrap',
                  overflow: 'visible',
                  backgroundColor: ColorConstants.lightBlue,
                  padding: 5,
                  borderRadius: 4,
                  fontSize: 12,
                  elevation: 5,
                  color: ColorConstants.oaBlueTint,
                  fontFamily: 'sans-serif',
                } as ViewStyle
              }>
              Logout
            </Components.Text>
          )}
        </Components.Link>
      </View>
    );
  };
  return (
    <View testID="header" className="fixed w-full z-50">
      {/* Even though this is hidden on web mobile, prevent the render on native */}
      {Platform.OS === 'web' && (
        <View
          className="hidden md:py-2 md:w-full md:flex md:flex-row md:px-6 lg:px-8 md:items-center md:justify-between md:h-[50px]"
          style={styles.infoHeader}>
          {menu ? (
            <View
              style={{flexDirection: 'row'}}
              className="mr-6"
              {...(Platform.OS === 'web' && {
                onMouseEnter: () => {
                  setTooltipVisibleAccountNav(true);
                },
                onMouseLeave: () => {
                  setTooltipVisibleAccountNav(false);
                },
              })}>
              <Components.Link
                className="md:items-center"
                accessibilityLabel="Account Navigator"
                accessibilityRole="button"
                onFocus={() => setTooltipVisibleAccountNav(true)}
                onBlur={() => setTooltipVisibleAccountNav(false)}
                onPress={() => {
                  trackCustomEvent(
                    analyticsTags.CaptureAccountNavigatorIconTags,
                  );
                  openURL(ACCOUNT_NAVIGATOR_URL_PH);
                }}
                hoverStyle={{textDecorationLine: 'none'}}
                focusStyle={
                  {
                    outlineWidth: 1,
                    outlineColor: Theme.Color.white,
                    outlineStyle: 'solid',
                    outlineOffset: 1,
                    textDecorationLine: 'none',
                  } as ViewStyle
                }>
                {showAccntNavigatorLink && (
                  <FontAwesomeIcon
                    icon={faMapLocationDot as IconProp}
                    color={Theme.Color.white}
                    size={20}
                  />
                )}
                {showAccntNavigatorLink &&
                  tooltipVisibleAccountNav &&
                  Platform.OS === 'web' && (
                    <Components.Text
                      style={
                        {
                          position: 'absolute',
                          top: -3,
                          left: 25,
                          transform: 'none',
                          maxWidth: 120,
                          whiteSpace: 'nowrap',
                          overflow: 'visible',
                          backgroundColor: ColorConstants.lightBlue,
                          padding: 5,
                          borderRadius: 4,
                          fontSize: 12,
                          elevation: 5,
                          color: ColorConstants.oaBlueTint,
                          fontFamily: 'sans-serif',
                        } as ViewStyle
                      }>
                      Account Navigator
                    </Components.Text>
                  )}
              </Components.Link>
            </View>
          ) : (
            // Just render a spacer
            <View className="flex-1" />
          )}
          <View className="flex-row align-middle items-center">
            {!accountToken && lastLoginDateTime && (
              <Components.Text
                className="mr-6"
                color={Theme.Color.white}
                size={14}>
                LAST LOGIN: {lastLoginDate}
              </Components.Text>
            )}
            <View className="flex-row">
              {renderMyProfile()}
              {renderLogout()}
            </View>
          </View>
        </View>
      )}
      <View
        className={`w-full bg-white h-[${
          topInset + 60
        }px] md:border-t-0 md:h-[92px] px-6 lg:px-8 py-8 md:py-0`}
        style={[styles.header]}
        pointerEvents="box-none">
        <View
          className={`${
            collapse ? 'absolute' : 'relative'
          } w-full flex-row justify-around max-w-[1250px] mx-auto h-full items-center`}
          pointerEvents="box-none">
          <View className="flex-row flex-1 items-center">
            {isBackEnabled && Theme.isMobile() && (
              <Components.Link
                testID="header:icon:back_arrow"
                className="mx-3 md:hidden lg:pt-4"
                onPress={onBack}
                accessibilityLabel="Go Back"
                accessibilityRole="button">
                <FontAwesomeIcon icon={arrowLeft as IconProp} size={20} />
              </Components.Link>
            )}
            {menu && renderHamburgerMenu()}
            {renderOALogo()}
          </View>
          <View className="flex-row h-full">{renderMenuLinks()}</View>
        </View>
      </View>
    </View>
  );
};

export default Header;
