import React, {ReactElement} from 'react';
import {View} from 'react-native';
import {Text} from '@oneamerica/dxp-ui-components';
import responsiveStyles from './styles';

export interface InputHelperProps {
  message?: string;
  testID?: string;
  type?: 'info' | 'error';
}

const InputHelperText = ({
  message,
  testID,
  type = 'info',
}: InputHelperProps): ReactElement => {
  const styles = responsiveStyles();

  return (
    <View
      accessibilityRole={type === 'error' ? 'alert' : 'none'}
      style={styles.container}
      testID={testID}>
      <Text
        style={[
          styles.inputHelperText,
          type === 'error' && styles.inputHelperTextError,
        ]}>
        {message}
      </Text>
    </View>
  );
};

export {InputHelperText};
