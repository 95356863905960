import React, {useContext} from 'react';
import {View} from 'react-native';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import responsiveStyles from './styles';

const Stepper = ({steps, currentStep}) => {
  const {width} = useContext(Theme.ResponsiveContext);
  const style = responsiveStyles(width);
  return (
    <View
      className="flex-row justify-center mt-8"
      style={style.stepperContainer}>
      {steps?.map((step, i) => (
        <View
          className="relative flex flex-col justify-center items-center w-[118px] md:w-56 lg:w-56"
          style={style.stepItem}
          key={`${step}_${i}`}>
          <View
            className="w-10 h-10 flex items-center justyfy-center z-10 relative rounded-full"
            style={[
              style.stepNumber,
              currentStep >= i + 1 && style.activeNumber,
            ]}>
            {i + 1}
          </View>
          <Components.Text style={style.stepName}>{step}</Components.Text>
          {i !== 0 && (
            <View
              style={[
                style.leftBar,
                currentStep >= i + 1 && style.activeBorder,
              ]}
            />
          )}
          {i !== steps.length - 1 && (
            <View
              style={[
                style.rightBar,
                currentStep > i + 1 && style.activeBorder,
              ]}
            />
          )}
        </View>
      ))}
    </View>
  );
};

export default Stepper;
