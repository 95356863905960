import React, {ReactElement, Dispatch, SetStateAction} from 'react';
import {Linking, View} from 'react-native';
import {Components, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../constants/ColorConstants';
import {formatCurrency, formatDate, titleCase} from '../../utils';
import responsiveStyle from '../Home/style';

export const displaybleBillingAndPremiumKeys = [
  'billingAmount',
  'billToDate',
  'billingMode',
  'totalPremiumPaidToDate',
  'billingMethod',
  'bankDraftDate',
  'bankDraftControlNumber',
  'paidToDate',
  'lastPremReceived',
  'billRiderAmt',
  'plannedPDRPremium',
];

export const termOrder = [
  'Primary Insured',
  'Joint Insured',
  'Annuitant',
  'Joint Annuitant',
  'Owner',
  'Payor',
  'Spouse',
  'Beneficiary',
  'First Beneficiary',
  'Second Beneficiary',
  'Insured Beneficiary',
  'Assignee',
  'Eligible Person',
  'Third Party',
  'Dependent',
  'Other Insured',
  'Guardian',
  'Conservator',
  'Employer',
];

export const getButtonColors = (buttonText) => {
  switch (buttonText) {
    case 'Active':
    case 'Reduced Paid-Up':
    case 'Paid-Up':
    case 'Income Payment':
      return {
        backgroundColor: ColorConstants.lightGreen,
        borderColor: ColorConstants.darkGreen,
      };
    case 'Disability':
    case 'Reinstatement pendin':
    case 'Reinstatement pending':
    case 'Extended Insurance':
    case 'Non-Forfeiture':
    case 'Premium Waiver':
      return {
        backgroundColor: ColorConstants.lightBlue,
        borderColor: ColorConstants.darkBlue,
      };
    case 'Back Billed':
      return {
        backgroundColor: ColorConstants.lightRed,
        borderColor: ColorConstants.darkRed,
      };
    case 'Terminated':
    case 'Suspended':
      return {
        backgroundColor: ColorConstants.lightGrey,
        borderColor: ColorConstants.darkGrey,
      };
    default:
      return {backgroundColor: 'white', borderColor: 'black'};
  }
};

export const getAffiliate = (affiliate?: string) => {
  switch (affiliate) {
    case 'AUL':
      return 'American United Life';
    case 'SL':
      return 'State Life';
    case 'PML':
      return 'Pioneer Mutual Life';
    default:
      return '';
  }
};
export const getType = (type?: string, affiliate?: string) => {
  switch (type) {
    case 'WL':
      if (affiliate === 'SL') {
        return 'Asset Care Whole Life Insurance';
      } else {
        return 'Whole Life Insurance';
      }
    case 'TERM':
      return 'Term Life Insurance';
    case 'HEALTH':
      return 'Health';
    case 'RA':
      return 'Retirement Annuity';
    case 'ISL':
      return 'Interest Sensitive Life Insurance';
    case 'SPVA':
      return 'Variable Annuity';
    case 'FPVA':
      return 'Variable Annuity';
    case 'FPVUL':
      return 'Variable Universal Life Insurance';
    case 'SPVUL':
      return 'Variable Universal Life Insurance';
    case 'FDIA':
      return 'Flexible Deferred Income Annuity';
    case 'SPFA':
      if (affiliate === 'SL') {
        return 'Asset Care Annuity';
      } else {
        return 'Fixed Annuity';
      }
    case 'FPFA':
      return 'Fixed Annuity';
    case 'UL':
      return 'Universal Life Insurance';
    case 'INXAN':
      return 'Indexed Annuity';
    default:
      return '';
  }
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const regex = /^(\d{3})(\d{3})(\d{4})$/;
  const match = regex.exec(cleaned);
  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3];
  }
  return '';
};

export const getTerminatedReason = (terminatedReason: string) => {
  switch (terminatedReason) {
    case 'Surrender':
      return 'Surrendered';
    case 'Lapse':
      return 'Lapsed';
    case 'Maturity':
      return 'Matured';
    case 'Death':
      return 'Death Claim';
    case 'Free Look':
    case 'Not Taken':
      return 'Coverage Not Accepted';
    case 'Expired':
      return 'Expired';
    case 'Converted':
      return 'Converted';
    case 'Replaced':
      return 'Replaced';
    default:
      return '';
  }
};

export const validateElement = (
  condition: boolean | null | undefined | string,
  ele: ReactElement,
  empty: ReactElement = <View className="py-2 px-2 flex-1" />,
) => {
  if (condition) {
    return ele;
  } else {
    return empty;
  }
};

const getBillingMode = (mode: string) => {
  if (mode === 'APP Monthly') {
    return 'Monthly';
  }
  if (mode === 'Semi-Annually') {
    return 'Semi-Annual';
  }
  return mode;
};

const getBillingMethod = (method: string) => {
  if (['Preauthorized Check', 'Preauthorized check'].includes(method)) {
    return 'Automatic Draft';
  }
  if (method === 'Direct Pay') {
    return 'Direct Bill';
  }
  return method;
};

const titleValueMap = {
  billingAmount: (value: number) => ({
    title: 'Billing Amount',
    value: formatCurrency(value),
  }),
  billToDate: (value: string) => ({
    title: 'Next Payment Due',
    value: formatDate(value),
  }),
  billingMode: (value: string) => ({
    title: 'Payable',
    value: getBillingMode(value),
  }),
  billingMethod: (value: string) => ({
    title: 'Payment Method',
    value: getBillingMethod(value),
  }),
  bankDraftDate: (value: string) => ({
    title: 'Bank Draft Date',
    value: formatDate(value),
  }),
  bankDraftControlNumber: (value: string) => ({
    title: 'Bank Draft Control #',
    value: value.toString(),
  }),
  paidToDate: (value: string) => ({
    title: 'Paid to Date',
    value: formatDate(value),
  }),
  lastPremReceived: (value: string) => ({
    title: 'Last Premium Received',
    value: formatDate(value),
  }),
  billRiderAmt: (value: number) => ({
    title: 'PUA Rider Premium',
    value: formatCurrency(value),
  }),
  plannedPDRPremium: (value: number) => ({
    title: 'Planned PDR Premium',
    value: formatCurrency(value),
  }),
  totalPremiumPaidToDate: (value: number) => ({
    title: 'Total Policy Contributions',
    value: formatCurrency(value),
  }),
};

export const getPolicyTitleValue = (generalPolicyData: any, key: string) => {
  const handler = titleValueMap[key];
  return handler ? handler(generalPolicyData[key]) : {title: '', value: ''};
};

export const getPolicyNotes = (policyNotes?: string) => {
  switch (policyNotes) {
    case 'Claim Pending':
      return 'Claim Pending - Online financial transactions are restricted for policy records with death claim pending status.';
    case 'ACCOUNTING INCOMPLETE':
      return 'Financial Update Pending - We are in the process of performing financial updates on this policy.';
    case 'Assignment':
      return 'Policy is assigned.';
    case ', ASSIGNMENT':
      return 'Policy is assigned.';
    case 'Restricted Policy':
      return 'Restricted Policy - Policy has some type of restriction, eg. Juvenile restriction, etc.';
    case 'Incomplete Financials':
      return 'Financial Update Pending - We are in the process of performing financial updates on this policy.';
  }
};

export const getRepresentative = (policyHighlights) => {
  try {
    const relationships: Array<any> = policyHighlights[0]?.policyRelationships;
    const csa = relationships.find(
      (obj, idx) => obj?.relationType === 'Current Servicing Agent',
    );
    if (csa !== undefined && csa !== null) {
      return csa;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export const formatAddress = (policyHighlights) => {
  const style = responsiveStyle();
  const representative = getRepresentative(policyHighlights);
  const address = representative?.addresses[0];

  if (!address) {
    return null; // Return null if there's no address
  }

  const {line1, line2, city, state, zip} = address;
  let formattedAddress = line1 ?? '';

  if (line2) {
    formattedAddress += `\n${line2}`;
  }
  if (city) {
    formattedAddress += `,\n${titleCase(city)}`;
  }
  if (state) {
    formattedAddress += `, ${state}`;
  }
  if (zip) {
    formattedAddress += ` ${titleCase(zip)}`;
  }

  const phoneNumber = representative?.phoneNumbers[0]?.number;

  return (
    <View>
      <Components.Text>{formattedAddress}</Components.Text>
      {phoneNumber && (
        <Components.Link
          onPress={() => {
            Linking.openURL(`tel:${phoneNumber}`);
          }}
          style={[
            style.contactUsContentText,
            {
              color: ColorConstants.oaBlueTint,
              fontFamily: Font.medium.fontFamily,
            },
          ]}>
          {formatPhoneNumber(phoneNumber)}
        </Components.Link>
      )}
    </View>
  );
};

export const getTaxQualification = (policyHighlights) => {
  try {
    const {
      taxQualification,
      taxQualificationType,
      render: generalPolicyDataRender,
    } = policyHighlights[0]?.generalPolicyData ?? {};
    if (
      generalPolicyDataRender.taxQualification &&
      taxQualification === 'Qualified' &&
      generalPolicyDataRender.taxQualificationType
    ) {
      return `${taxQualification} | ${taxQualificationType}`;
    } else if (generalPolicyDataRender.taxQualification) {
      return `${taxQualification}`;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

const getPolicyTitle = (data) => {
  if (data === 'First Beneficiary') {
    return 'Primary Beneficiary';
  }
  if (data === 'Second Beneficiary') {
    return 'Contingent Beneficiary';
  }
  return data;
};

export const makepolicyRelationShipData = (
  data: string,
  policyRelationshipsData: string | undefined[] | PolicyRelationship,
  setModalTitles: Dispatch<SetStateAction<string>>,
  setRelationType: Dispatch<
    SetStateAction<PolicyRelationshipClick | undefined>
  >,
): void => {
  const title: string = getPolicyTitle(data);

  if (policyRelationshipsData) {
    setModalTitles(title + ' Personal Details');
    const policyRelationshipResponse: any = (
      policyRelationshipsData as PolicyRelationship
    ).find((rel?: PolicyRelationship) => {
      const relationType = rel?.relationType;

      return relationType === data;
    });

    const getValue = (obj, path, defaultValue = '-') => {
      return (
        path.split('.').reduce((acc, key) => acc?.[key], obj) ?? defaultValue
      );
    };

    const formatAddress = (addresses, key) => {
      return getValue(addresses, `0.${key}`);
    };

    const data2 = [
      [
        'Name',
        getValue(policyRelationshipResponse, 'personDetails.name.first'),
      ],
      ['Issue Age', getValue(policyRelationshipResponse, 'personDetails.age')],
      [
        'Date of Birth',
        formatDate(
          policyRelationshipResponse?.personDetails?.dob,
          'MMM DD, YYYY',
          '-',
        ),
      ],
      ['Gender', getValue(policyRelationshipResponse, 'personDetails.sex')],
      [
        'SSN',
        policyRelationshipResponse?.id
          ? maskId(policyRelationshipResponse.id)
          : '-',
      ],
      [
        'Address',
        formatAddress(policyRelationshipResponse?.addresses, 'line1'),
      ],
      ['City', formatAddress(policyRelationshipResponse?.addresses, 'city')],
      ['State', formatAddress(policyRelationshipResponse?.addresses, 'state')],
      ['Zip', formatAddress(policyRelationshipResponse?.addresses, 'zip')],
    ];
    setRelationType(data2);
  }
};

export const maskId = (idNumber) => {
  const idStr = String(idNumber);

  if (idStr.length < 4) {
    return 'ID too short!';
  }

  return `***-**-${idStr.slice(-4)}`;
};

export const getLoanItem = (policyValue) => {
  return policyValue?.policyValues?.filter(
    (value) => value.type === 'Loan Balance',
  )[0];
};

export const truncatedText = (
  text: string,
  maxLength: number = Number.MAX_SAFE_INTEGER,
): string => {
  const shouldTruncate: boolean = text?.length > maxLength;
  return shouldTruncate ? `${text.slice(0, maxLength)} ...` : text;
};

export const truncatedTextWithLineBreaks = (
  text: string,
  lineLength: number,
): string => {
  const shouldTruncate: boolean = text?.length > lineLength;

  if (shouldTruncate) {
    let truncatedText = text;

    const regex = new RegExp(`.{1,${lineLength}}`, 'g');
    truncatedText = truncatedText.replace(regex, (match, offset) =>
      offset === 0 ? match : `\n${match}`,
    );

    return truncatedText;
  } else {
    return text;
  }
};

export const setDOBMonth = (data: any) => {
  data.forEach((item: any, index: number) => {
    if (index > 0) {
      const dobString = item.personDetails?.dob;
      if (dobString !== null) {
        const dobDate: Date = new Date(dobString);
        const monthNames: string[] = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ];

        const month: string = monthNames[dobDate.getUTCMonth()];
        const day: number = dobDate.getUTCDate();
        const year: number = dobDate.getUTCFullYear();

        const formattedDay: string = day < 10 ? `0${day}` : `${day}`;

        const formattedDOB = `${month} ${formattedDay}, ${year}`;
        item.personDetails.dob = formattedDOB;
      }
    }
  });
  return data;
};

export const getAddressField = (item, field) => {
  return item?.addresses?.[0]?.[field] ?? '-';
};

export const getLimitWidthArray = (data: any) => {
  const truncateCharLimit = 30;

  const relationshipTypes: string[] = [];
  const names: string[] = [];
  const issueAges: (string | number)[] = [];
  const dobs: string[] = [];
  const genders: string[] = [];
  const ssns: (string | null)[] = [];
  const addresses: string[] = [];
  const cities: string[] = [];
  const states: string[] = [];
  const zips: string[] = [];
  const processField = (value, defaultValue = '-', processor = (v) => v) => {
    return (
      truncatedTextWithLineBreaks(
        value === null || value === 0 ? defaultValue : processor(value),
        truncateCharLimit,
      ) || ''
    );
  };

  const mapRelationType = (type) => {
    const typeMap = {
      'First Beneficiary': 'Primary Beneficiary',
      'Second Beneficiary': 'Contingent Beneficiary',
    };
    return typeMap[type] || type;
  };

  data.forEach((item) => {
    relationshipTypes.push(
      processField(item.relationType, '-', mapRelationType),
    );
    names.push(processField(item.personDetails?.name?.first));
    issueAges.push(processField(item.personDetails?.age, '0'));
    dobs.push(processField(item.personDetails?.dob));
    genders.push(processField(item.personDetails?.sex));
    ssns.push(processField(item.id, '-', maskId));
    addresses.push(processField(getAddressField(item, 'line1')));
    cities.push(processField(getAddressField(item, 'city')));
    states.push(processField(getAddressField(item, 'state')));
    zips.push(processField(getAddressField(item, 'zip')));
  });

  const newData = [
    relationshipTypes,
    names,
    issueAges,
    dobs,
    genders,
    ssns,
    addresses,
    cities,
    states,
    zips,
  ];

  const widthArr: number[] = [];
  newData.forEach((columnData) => {
    const maxLength = Math.max(
      ...columnData.map((value) => String(value).length),
    );
    maxLength > truncateCharLimit
      ? widthArr.push(truncateCharLimit * 6.5 + 33)
      : widthArr.push(maxLength * 9.2 + 40);
  });
  return widthArr;
};
export const getEleSpacing = (row) => {
  const marginSpacing = 4; //tailwind margin spacing, 4 -> 16 px

  return row > 1 ? `px-${marginSpacing} mt-6 lg:mt-8` : `px-${marginSpacing}`;
};
export const aulOrPmWLPolicies = (addCoverages, policyValue) => {
  const excludedTypes = [
    'Guaranteed Cash Value',
    'Cash Surrender Value',
    'Net Cash Value',
  ];
  return {
    totalDeathBenefit:
      Array.isArray(addCoverages) &&
      addCoverages.length > 0 &&
      getCoverageBenefit('Total Death Benefit', addCoverages),
    guaranteedCashValue: getPolicyValue('Guaranteed Cash Value', policyValue),
    totalCashSurrenderValue: getPolicyValue(
      'Cash Surrender Value',
      policyValue,
    ),
    netCashValue: getPolicyValue('Net Cash Value', policyValue),
    otherFields: getOtherFields(policyValue, excludedTypes),
  };
};
export const slWLPolicies = (baseCoverages: any[], policyValue) => {
  const excludedTypes = ['Current Account Value', 'Cash Surrender Value'];
  return {
    monthlyLTCBenefit:
      baseCoverages &&
      baseCoverages.length > 0 &&
      baseCoverages[0].monthlyLTCBenefit,
    deathBenefit:
      baseCoverages &&
      baseCoverages.length > 0 &&
      baseCoverages[0].deathBenefit,
    currentAccountValue: getPolicyValue('Current Account Value', policyValue),
    totalCashSurrenderValue: getPolicyValue(
      'Cash Surrender Value',
      policyValue,
    ),
    otherFields: getOtherFields(policyValue, excludedTypes),
  };
};

export const annuityPolicies = (ltcSection: any, policyValue) => {
  const excludedTypes = ['Account Value', 'Cash Surrender Value'];
  return {
    ltcBenefitBalance: ltcSection && ltcSection?.ltcBenefitBalance,
    ltcMonthlyMaximum: ltcSection && ltcSection?.ltcMonthlyMaximum,
    accountValue: getPolicyValue('Account Value', policyValue),
    totalCashSurrenderValue: getPolicyValue(
      'Cash Surrender Value',
      policyValue,
    ),
    otherFields: getOtherFields(policyValue, excludedTypes),
  };
};
export const otherPolicies = (policyValue) => {
  const excludedTypes = ['Account Value', 'Net Cash Value'];
  return {
    accountValue: getPolicyValue('Account Value', policyValue),
    netCashValue: getPolicyValue('Net Cash Value', policyValue),
    otherFields: getOtherFields(policyValue, excludedTypes),
  };
};
function getCoverageBenefit(coverageType, addCoverages) {
  const coverage = addCoverages.find((c) => c.name === coverageType);
  return coverage ? coverage.coverageBenefit : null;
}
function getPolicyValue(valueType, policyValue) {
  const policyValueData = policyValue.policyValues.find(
    (v) => v.type === valueType,
  );
  return policyValueData ? policyValueData.amount : null;
}
const getOtherFields = (policyValue, excludedTypes) => {
  const otherFields = {};
  policyValue.policyValues.forEach((value) => {
    if (!excludedTypes.includes(value.type)) {
      otherFields[value.type] = value.amount;
    }
  });
  return otherFields;
};
export const flattenObject = (obj, parentKey = '') => {
  let result = {};

  for (const key in obj) {
    const newKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      result = {...result, ...flattenObject(obj[key], newKey)};
    } else {
      result[newKey] = obj[key];
    }
  }
  return result;
};
export const removeFalsyFromObject = (object) => {
  return Object.fromEntries(
    Object.entries(object).filter(([_, value]) => value !== null),
  );
};
