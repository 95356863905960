import React, {ReactNode} from 'react';
import {TextStyle, View} from 'react-native';
import {Components} from '@oneamerica/dxp-ui-components';
import responsiveStyle from './styles';

interface InfoWrapperProps {
  centerAlign?: boolean;
  centerAlignSmall?: boolean;
  title?: string;
  information?: ReactNode | null;
  subTitle?: boolean;
  subTitleText?: string | null;
  boldInfo?: boolean;
  width?: string;
  infoTextStyle?: TextStyle;
  titleTextStyle?: TextStyle;
  statusbuttonText?: string | null;
  statusButtonBorder?: string;
  statusButtonBackground?: string;
  statusReason?: string | null;
}

const InfoWrapper = ({
  centerAlign = false,
  centerAlignSmall = false,
  title,
  information,
  subTitle = false,
  subTitleText,
  boldInfo = false,
  width,
  infoTextStyle = {},
  titleTextStyle = {},
  statusbuttonText,
  statusButtonBorder,
  statusButtonBackground,
  statusReason,
}: InfoWrapperProps) => {
  const style = responsiveStyle();
  return (
    <View
      className={`py-2 px-2 ${
        width !== undefined && width !== '' ? width : 'flex-1'
      }${centerAlign ? 'flex items-center' : ''} ${
        centerAlignSmall
          ? 'items-center text-center lg:text-left lg:items-start'
          : ''
      }`}>
      <Components.Text style={[style.infoWrapperTitle, titleTextStyle]}>
        {title}
      </Components.Text>
      {subTitle && (
        <View
          className={`${
            centerAlignSmall
              ? 'items-center text-center lg:text-left lg:items-start'
              : ''
          } `}>
          <Components.Text style={style.subTextInformation}>
            {subTitleText}
          </Components.Text>
          <Components.Text
            style={[style.infoWrapperInformationSub, infoTextStyle]}>
            {information}
          </Components.Text>
        </View>
      )}
      {boldInfo && (
        <Components.Text
          style={
            boldInfo
              ? style.infoWrapperInformationBold
              : style.infoWrapperInformation
          }>
          {information}
        </Components.Text>
      )}
      {statusbuttonText && (
        <>
          <View style={style.policyStatusBtnWrapper}>
            <Components.Text
              style={[
                style.policyStatusButton,
                {
                  borderColor: statusButtonBorder,
                  backgroundColor: statusButtonBackground,
                },
              ]}>
              {statusbuttonText}
            </Components.Text>
          </View>
          {statusReason && (
            <View className="pt-2">
              <Components.Text style={style.statusReason}>
                {statusReason}
              </Components.Text>
            </View>
          )}
        </>
      )}
    </View>
  );
};

export default InfoWrapper;
