import React, {useContext, useMemo, JSX} from 'react';
import {View} from 'react-native';
import {useSelector} from 'react-redux';
import {
  Components,
  Theme,
  List,
  Accordion,
} from '@oneamerica/dxp-ui-components';
import responsiveStyle from './../../style';
import withAuth from '../../../../auth/withAuth';
import BreakpointConstants from '../../../../constants/BreakpointConstants';
import {
  columnConfiguration,
  childRowsColumnConfig,
  columnWidthArr,
} from './columnConfig';
import {formatDate, prepareInvestmentOptionsData} from '../../../../utils';
import InvestmentChart from '../../../../components/InvestmentTable';
import notes from '../../../../mock/notes.json';
import {useHomeSelector} from '../../../Home/hooks/home';

const EditElections = (props) => {
  const {width} = useContext(Theme.ResponsiveContext);
  const style = responsiveStyle(width);
  const investments = useSelector(
    (state: any) => state?.investments?.getInvestmentOptionData,
  );
  const {policyValue} = useHomeSelector(props);
  const asOfDate = formatDate(policyValue.valueAsOfDate, 'MMM DD, YYYY', '-');

  const selectedTab = props?.route?.params?.tab === 'Future-Elections' ? 1 : 0;
  const isLargeScreen = BreakpointConstants.isLG(width);
  const isMediumScreen = BreakpointConstants.isMD(width);
  let screenType;
  if (isLargeScreen) {
    screenType = 'LG';
  } else if (isMediumScreen) {
    screenType = 'MD';
  } else {
    screenType = 'SM';
  }

  const renderCards = useMemo(() => {
    const elements: JSX.Element[] = [];
    if (investments?.data?.items[0]?.investments?.length) {
      const investmentFormattedData = prepareInvestmentOptionsData(
        investments?.data?.items[0]?.investments,
      );

      const mappedData = investmentFormattedData.AllAssets;
      elements.push(
        <InvestmentChart
          className="mt-0"
          key={'edit-ioe-table'}
          data={props.previousData?.length ? props.previousData : mappedData}
          accountBalance={100}
          showChildRows={false}
          columnWidthArr={columnWidthArr}
          columnConfig={columnConfiguration}
          childRowsColumnConfig={childRowsColumnConfig}
          assetTotal={investmentFormattedData.percentRoundTotal}
          horizontal={!isLargeScreen}
          legendTableTitle={{
            text: '',
            titleStyle: style.headingTable,
          }}
          showPieChart={false}
          showLegendTable={true}
          showAlternateRowColors={true}
          showBorder={false}
          fullHeight={true}
          setIsErrorInTable={props.setIsErrorInTable}
          setIsDirty={props.setIsDirty}
          getEditedData={props.getEditedData}
          previousClicked={props.previousClicked}
          isMobileTableFooter={!isLargeScreen}
        />,
      );
    }
    return elements;
  }, [screenType, selectedTab, investments]);

  return (
    <View>
      <Components.Text.H2
        style={[
          style.tableTitle,
          isLargeScreen ? style.topMargin : style.noTopMargin,
        ]}>
        Edit Future Elections
      </Components.Text.H2>
      <Components.Text style={style.placeholderText}>
        Your last allocation change was on {asOfDate}.
      </Components.Text>
      {/* Tablet and Mobile Notes */}
      <View style={[style.hideLg, style.topMargin]}>
        <Accordion open title="Notes" style={style.accordionBg}>
          <List
            textSize={14}
            style={style.bulletListStyle}
            flatList={notes.data.notesListIOE.item}
          />
        </Accordion>
      </View>
      {renderCards}
    </View>
  );
};

export default withAuth(EditElections);
