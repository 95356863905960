import React, {
  Key,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  forwardRef,
} from 'react';
import {
  Animated,
  Easing,
  TextInput as NativeInput,
  NativeSyntheticEvent,
  TextInputProps as NativeTextInputProps,
  StyleSheet,
  TextInputFocusEventData,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {Text, Color, icons, Link} from '@oneamerica/dxp-ui-components';
import {InputHelperText} from './components/InputHelperText';
import responsiveStyles from './styles';
import {AnimatedLabel} from './components/AnimatedLabel';
import {TextInputLabel} from './components/TextInputLabel';
import {PlatformInput} from './components/PlatformInput';

export interface ErrorListProps {
  id?: Key | null;
  errorItem?: string;
}
export interface TextInputProps extends NativeTextInputProps {
  border?: boolean;
  className?: string;
  Controls?: ReactElement;
  editable?: boolean;
  ErrorList?: ErrorListProps[];
  focusable?: boolean;
  helperText?: string;
  hideLabel?: string;
  id?: string;
  Input?: (arg0: ViewStyle) => ReactElement;
  label?: string;
  placeholder?: string;
  pointerEvents?: 'none' | 'auto' | 'box-none' | 'box-only';
  rounded?: boolean;
  showErrors?: boolean;
  showLabel?: string;
  small?: boolean;
  style?: ViewStyle;
  innerStyle?: ViewStyle | TextStyle;
  testID?: string;
  onFocus?: () => void;
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
}

// External ref clearing/setting
export interface TextInputRef {
  clear(): void;
  set(value: string): void;
}

const TextInput = forwardRef<TextInputRef, TextInputProps>((props, ref) => {
  const {
    autoComplete = 'off',
    border,
    className,
    Controls,
    editable = true,
    ErrorList = [],
    focusable,
    helperText = '',
    hideLabel,
    id,
    Input,
    keyboardType = 'default',
    label = '',
    onChangeText,
    placeholder,
    pointerEvents,
    rounded = true,
    secureTextEntry,
    showErrors = false,
    showLabel,
    small = false,
    style,
    innerStyle,
    testID,
    value,
    onFocus = () => {},
    onBlur = () => {},
    contextMenuHidden = false,
  } = props;
  const inputRef = useRef<NativeInput | null>(null);
  const [selectedValue, setSelectedValue] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  const styles = responsiveStyles(border, editable, small, rounded);
  const [isSecure, setIsSecure] = useState(secureTextEntry);

  // Allows textInput ref value to be cleared/set externally
  useImperativeHandle(ref, () => ({
    ...inputRef.current,
    clear: () => {
      inputRef.current?.clear();
    },
    set: (newValue) => {
      setSelectedValue(newValue);
      inputRef.current?.setNativeProps?.({text: newValue});
    },
  }));

  const handleFocus = () => {
    onFocus();
    setIsFocused(true);
  };
  const handleBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    onBlur(e);
    setIsFocused(false);
  };

  const handleChange = (e: string) => {
    setSelectedValue(e);
    onChangeText?.(e);
  };

  //animation for label
  const focusAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(focusAnim, {
      toValue: isFocused || !!selectedValue ? 1 : 0,
      duration: 150,
      easing: Easing.bezier(0.4, 0, 0.2, 1),
      useNativeDriver: false,
    }).start();
  }, [focusAnim, isFocused, selectedValue]);

  useEffect(() => {
    setSelectedValue(value);
    inputRef?.current?.setNativeProps?.({text: value});
  }, [value]);

  // Value is stored in selectedValue state. Removing value and defaultValue from props to prevent state errors
  const scrubbedProps = {...props, ...{style: {}}};
  delete scrubbedProps.value;
  delete scrubbedProps.defaultValue;

  return (
    <>
      <View
        className={className}
        style={[styles.container, style]}
        pointerEvents={pointerEvents}>
        <TextInputLabel
          style={
            (!Controls ? styles.inputStack : styles.row, {display: 'flex'})
          }>
          <PlatformInput
            isFocused={isFocused}
            small={small}
            secureTextEntry={secureTextEntry}
            Input={Input}
            scrubbedProps={scrubbedProps}
            autoComplete={autoComplete}
            inputRef={inputRef}
            label={label}
            focusable={focusable}
            editable={editable}
            styles={styles}
            innerStyle={innerStyle}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
            handleChange={handleChange}
            isSecure={isSecure}
            testID={testID}
            selectedValue={selectedValue}
            id={id}
            contextMenuHidden={contextMenuHidden}
            keyboardType={keyboardType}
            placeholder={placeholder}
          />
          <AnimatedLabel
            styles={styles}
            focusAnim={focusAnim}
            small={small}
            label={label}
            isFocused={isFocused}
            editable={editable}
            ErrorList={ErrorList}
          />
          {Input?.(
            StyleSheet.flatten([
              styles.input,
              label === '' && styles.centeredInput,
            ]),
          )}
          {secureTextEntry && (
            <Link
              style={styles.sideControlText}
              size={12}
              onPress={() => setIsSecure(!isSecure)}>
              {isSecure ? showLabel : hideLabel}
            </Link>
          )}
          {Controls}
          <View
            style={[
              styles.innerInputContainer,
              styles.border,
              isFocused && styles.borderFocused,
              ErrorList.length > 0 && styles.borderError,
              !editable && styles.border,
            ]}
            pointerEvents="none"
          />
        </TextInputLabel>
        {secureTextEntry && (
          <Link
            onPress={() => setIsSecure(!isSecure)}
            style={styles.iconLink}
            accessibilityLabel="link"
            testID={`${testID}-toggleIcon`}>
            <FontAwesomeIcon
              icon={(isSecure ? icons.eye : icons.eyeSlash) as IconProp}
              size={20}
              color={Color.input.focused}
            />
          </Link>
        )}
      </View>
      {helperText ? (
        <View style={styles.helperContainer}>
          <Text>
            <InputHelperText message={helperText} type="info" />
          </Text>
        </View>
      ) : null}
      {ErrorList.length && showErrors ? (
        <View style={styles.errorContainer}>
          {ErrorList.map((item, i) => (
            <InputHelperText
              key={`error-${i}`} //NOSONAR
              message={item.errorItem}
              type="error"
              testID={`${testID}-error-${i}`}
            />
          ))}
        </View>
      ) : null}
    </>
  );
});

export {TextInput};
