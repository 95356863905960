import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../../../constants/ColorConstants';

export default () =>
  Theme.Responsive.create({
    cardShadow: {
      borderRadius: 5,
      shadowRadius: 6,
      backgroundColor: Theme.Color.background,
      shadowColor: Theme.Color.backgroundShadow,
      shadowOffset: {width: 0, height: 3},
      paddingHorizontal: Theme.Metrics.small,
      paddingVertical: Theme.Metrics.small,
    },

    cardHeader: {
      flexDirection: 'row',
      alignItems: 'center',
    },

    contentWrapper: {
      flex: 1,
      justifyContent: 'space-between',
    },

    horizontalDivider: {
      backgroundColor: ColorConstants.grey200,
      height: 1,
      width: '100%',
    },

    Details: {
      height: 16,
      width: 16,
      borderRadius: 50,
      lineHeight: 15,
      textAlign: 'center',
      fontFamily: 'info-solid',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: ColorConstants.darkBlue,
      color: '#002C77',
      fontWeight: 'bold',
      fontSize: 12,
      alignSelf: 'center',
      marginRight: 4,
    },
    tableHeadingCell: {
      borderRightWidth: 1,
      borderRightColor: '#EAEAEA',
      paddingHorizontal: 16,
      textAlign: 'left',
      fontSize: 14,
      fontFamily: Font.medium.fontFamily,
      paddingVertical: 10,
      color: ColorConstants.iron,
      backgroundColor: '#F3F9FE',
    },
    tableHeadingCell1: {
      borderRightWidth: 1,
      borderRightColor: '#EAEAEA',
      paddingHorizontal: 16,
      textAlign: 'left',
      fontSize: 14,
      fontFamily: Font.medium.fontFamily,
      paddingVertical: 10,
      color: ColorConstants.iron,
      backgroundColor: '#F3F9FE',
    },
    tableCell2: {
      borderRightWidth: 1,
      borderRightColor: '#EAEAEA',
      paddingHorizontal: 16,
      textAlign: 'left',
      fontSize: 14,
      fontFamily: Font.regular.fontFamily,
      paddingVertical: 10,
      color: '#000000',
    },
    tableHeadingCellText: {
      fontSize: 14,
      fontFamily: Font.medium.fontFamily,
      color: '#333333',
    },
    tableCellText: {
      fontSize: 14,
      fontFamily: Font.regular.fontFamily,
      color: '#000000',
    },

    tableCell1: {
      borderRightWidth: 1,
      borderRightColor: '#EAEAEA',
      paddingHorizontal: 16,
      textAlign: 'left',
      fontSize: 14,
      fontFamily: Font.regular.fontFamily,
      paddingVertical: 10,
      color: '#000000',
    },
    placeholderWrapper: {
      minHeight: 244,
      backgroundColor: '#EFEFEF',
      justifyContent: 'center',
      alignItems: 'center',
    },
    placeholderWrapperText: {
      alignItems: 'center',
      fontSize: 16,
      fontFamily: Font.regular.fontFamily,
      color: '#333333',
    },
    tableCellText1: {
      fontSize: 14,
      fontFamily: Font.regular.fontFamily,
      color: '#000000',
    },

    cardHeaderTitle: {
      color: ColorConstants.iron,
      lineHeight: 36,
      fontFamily: Font.medium.fontFamily,
    },

    flex: {flex: 1},
    legendRows: {
      fontSize: 16,
      lineHeight: 28,
      fontFamily: Font.medium.fontFamily,
      letterSpacing: 0.08,
      color: '#333333',
      opacity: 1,
      textAlign: 'left',
    },

    scrollView: {
      width: '230%',
    },
  })();
