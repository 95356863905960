import React from 'react';
import {View, useWindowDimensions} from 'react-native';
import {Components, Font} from '@oneamerica/dxp-ui-components';
import FlatListTable, {
  FlatListColProps,
} from '../../../../../components/FlatListTable/FlatListTable';
import responsiveStyle from '../style';
import ColorConstants from '../../../../../constants/ColorConstants';

const AdditionalCoverage = ({addCoveragesData}) => {
  const style = responsiveStyle();
  const {width} = useWindowDimensions();
  const maxMobileScreenWidth = 768;
  const headerColumns: FlatListColProps[] = [];
  const totalItems = Object.keys(addCoveragesData[0]).length;
  const desiredSequence = [
    'name',
    'personInsured',
    'coverageBenefit',
    'annualPremium',
  ];
  const getColumnTitle = (key: string) => {
    switch (key) {
      case 'name':
        return 'Rider/Benefit Name';
      case 'personInsured':
        return 'Person Insured';
      case 'coverageBenefit':
        return 'Coverage Benefit';
      case 'annualPremium':
        return 'Annual Premium';
      default:
        return '';
    }
  };
  let columnWidths: string[] = [];
  if (totalItems === 1) {
    columnWidths = ['100%'];
  } else if (totalItems === 2) {
    columnWidths = ['50%', '50%'];
  } else if (totalItems === 3) {
    columnWidths = ['50%', '25%', '25%'];
  } else if (totalItems === 4) {
    columnWidths = ['40%', '20%', '20%', '20%'];
  }
  desiredSequence.forEach((key, index) => {
    if (Object.keys(addCoveragesData[0]).includes(key)) {
      headerColumns.push({
        key: key,
        title: getColumnTitle(key),
        // @ts-ignore
        style: {width: columnWidths[index]},
        className: 'pl-2 md:pl-4',
      });
    }
  });
  const headerColumnRider: FlatListColProps[] = [
    {
      key: 'riderInfo',
      title: 'Rider/Benefit Name',
      style: {width: '60%'},
      className: 'pl-4 md:pl-4',
    },
    {
      key: 'annualPremium',
      title: 'Annual Premium',
      style: {width: '40%'},
      className: 'pl-4 md:pl-4',
    },
  ];
  const horizontalScrollEnabled = width < maxMobileScreenWidth;
  return (
    <View className="flex-col lg:flex-row">
      <View className="w-full p-0">
        <View style={style.cardHeader}>
          <Components.Text
            style={{
              color: ColorConstants.iron,
              fontFamily: Font.medium.fontFamily,
              fontSize: 16,
              flex: 1,
              marginLeft: 8,
            }}>
            Additional Coverage
          </Components.Text>
        </View>
        <View style={style.contentWrapper}>
          <View className="mt-4 flex-row lg:mx-2 md:mb-2">
            <FlatListTable
              columns={
                'riderInfo' in addCoveragesData[0]
                  ? headerColumnRider
                  : headerColumns
              }
              data={addCoveragesData}
              horizontal={horizontalScrollEnabled}
              scrollContainerStyle={horizontalScrollEnabled && style.scrollView}
              altRow
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default AdditionalCoverage;
