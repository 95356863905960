import React, {FC} from 'react';
import {View, useWindowDimensions} from 'react-native';
import {Components} from '@oneamerica/dxp-ui-components';
import FlatListTable, {
  FlatListColProps,
} from '../../../../components/FlatListTable/FlatListTable';
import responsiveStyle from './style';
import {formatCurrency, formatDate} from '../../../../utils';
interface PremiumsReceivedProps {
  premiumData: any[];
}

const PremiumsReceived: FC<PremiumsReceivedProps> = ({premiumData}) => {
  const style = responsiveStyle();
  const {width} = useWindowDimensions();
  const maxMobileScreenWidth = 768;
  const headerColumns: FlatListColProps[] = [
    {
      key: 'paymentDate',
      title: 'Date of Additional Premium',
      style: {width: '22%'},
      className: 'pl-2 md:pl-4',
    },
    {
      key: 'premium',
      title: 'Additional Premium Amount',
      style: {width: '22%'},
      className: 'pl-2 md:pl-4',
    },
    {
      key: 'incomePayment',
      title: 'Income Payment Purchased by Additional Premium',
      style: {width: '31%'},
      className: 'pl-2 md:pl-4',
    },
    {
      key: 'taxableAmount',
      title: 'Taxable Amount of Income Payment',
      style: {width: '25%'},
      className: 'pl-2 md:pl-4',
    },
  ];

  const formattedData = premiumData.map((item) => ({
    paymentDate: formatDate(item.paymentDate, 'MMM DD, YYYY', '-'),
    premium: formatCurrency(item.premium),
    incomePayment: formatCurrency(item.incomePayment),
    taxableAmount: formatCurrency(item.taxableAmount),
  }));

  //enable horizontal scrolling for mobile device
  const horizontalScrollEnabled = width < maxMobileScreenWidth;

  return (
    <View className="flex-col lg:flex-row mt-6">
      <View className="w-full p-0">
        <View style={style.cardShadow}>
          <View style={style.cardHeader}>
            <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
              Premiums Received
            </Components.Text.H3>
          </View>

          <View className="mt-4" style={style.horizontalDivider} />
          <View style={style.contentWrapper}>
            <View className="mt-4 flex-row lg:mx-2 md:mb-2">
              <FlatListTable
                columns={headerColumns}
                data={formattedData}
                horizontal={horizontalScrollEnabled}
                scrollContainerStyle={
                  horizontalScrollEnabled && style.scrollView
                }
                altRow
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default PremiumsReceived;
