import React, {useState, useEffect, useRef, FC} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Animated,
  TextStyle,
  ViewStyle,
  Pressable,
} from 'react-native';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {Color, Utils} from '@oneamerica/dxp-ui-components';
import {styles} from './styles';

interface MultiSelectProps {
  label: string;
  options: {label: string; value: string}[];
  onSelect: (values: string[]) => void;
  maxSelections?: number;
  style?: ViewStyle;
  testID?: string;
  selectedOptions?: {label: string; value: string}[];
}

const AutocompleteMultiple: FC<MultiSelectProps> = ({
  label,
  options,
  onSelect,
  maxSelections = 10,
  style,
  testID,
  selectedOptions: initialSelectedOptions,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<
    {label: string; value: string}[]
  >([]);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [showOptions, setShowOptions] = useState(false);
  const focusAnim = useRef(new Animated.Value(0)).current;
  const [hoveredOption, setHoveredOption] = useState<string | null>(null); // State to track hovered option
  const dropdownRef = useRef<View | null>(null); // Ref for the dropdown

  // Handle selection of an option
  const handleSelect = (value: string) => {
    const option = options.find((opt) => opt.value === value);
    if (!option) {
      return;
    }

    const alreadySelected = selectedOptions.some((opt) => opt.value === value);
    const newSelections = alreadySelected
      ? selectedOptions.filter((opt) => opt.value !== value)
      : [...selectedOptions, option].slice(0, maxSelections);

    setSelectedOptions(newSelections);
    onSelect(newSelections.map((opt) => opt.value));
  };

  const backgroundColor = (
    isHover: boolean,
    isSelected: boolean,
    label: string,
  ): string => {
    let _backgroundColor = 'transparent';

    if (isHover || isSelected) {
      _backgroundColor = Color.backgroundGray;
    }

    return _backgroundColor;
  };

  // Handle removal of a selected option
  const handleRemove = (value: string) => {
    const newSelections = selectedOptions.filter((opt) => opt.value !== value);
    setSelectedOptions(newSelections);
    onSelect(newSelections.map((opt) => opt.value));
  };

  useEffect(() => {
    if (initialSelectedOptions) {
      setSelectedOptions(initialSelectedOptions);
    }
  }, [initialSelectedOptions]);

  useEffect(() => {
    const newFilteredOptions = options
      .filter(
        (option) => !selectedOptions.some((opt) => opt.value === option.value),
      )
      .sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically

    setFilteredOptions(newFilteredOptions);
  }, [selectedOptions, options]);

  // Change the animation condition to only lift the label when selectedOptions has at least one item
  useEffect(() => {
    Animated.timing(focusAnim, {
      toValue: selectedOptions.length > 0 ? 1 : 0, // Change here
      duration: 150,
      useNativeDriver: false,
    }).start();
  }, [selectedOptions]);

  // Click outside detection
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      //@ts-ignore
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    if (showOptions) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showOptions]);

  // Animated label style
  const labelStyle: Animated.AnimatedProps<TextStyle> = {
    position: 'absolute',
    left: 2,
    top: focusAnim.interpolate({
      inputRange: [0, 1],
      outputRange: [0, -20], // Adjust for lifting effect
    }),
    fontSize: focusAnim.interpolate({
      inputRange: [0, 1],
      outputRange: [15, 12], // Changes font size when focused
    }),
    color: Color.text,
    backgroundColor: Color.background,
    paddingHorizontal: 4,
    zIndex: 1,
  };

  const containerStyle: ViewStyle = {
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderColor: Color.gray3,
    backgroundColor: Color.background,
    borderWidth: 1,
    paddingVertical: 10,
    paddingHorizontal: 10,
    borderRadius: 5,
    justifyContent: 'flex-start',
    maxHeight: 700, // Set a maximum height for the container
    minHeight: 50, // Optional: Set a minimum height
    position: 'relative', // Needed for absolute positioning of the caret icon
    flexWrap: 'wrap',
  };

  const caretIconStyle: ViewStyle = {
    position: 'relative',
  };

  return (
    <View
      ref={dropdownRef}
      testID={testID}
      style={[styles.mainContainer, style]}>
      <TouchableOpacity
        onPress={() => {
          setShowOptions(!showOptions);
        }}
        style={containerStyle}
        testID="autocomplete-multiple-touchable">
        <View style={styles.inputWrapper}>
          {selectedOptions.map((option, index) => (
            <View key={`${option.value}_${index}`} style={styles.tag}>
              <Text style={styles.tagText}>{option.label}</Text>
              <TouchableOpacity
                onPress={() => handleRemove(option.value)}
                testID={`remove-${option.value}`}
                activeOpacity={1}
                style={{
                  padding: 0,
                  backgroundColor: 'transparent',
                  borderWidth: 0,
                }}>
                <FontAwesomeIcon
                  icon={Utils.icons.xMark as IconProp}
                  size={12}
                  color={Color.ice6}
                  style={[styles.iconStyle, styles.noBorder]}
                />
              </TouchableOpacity>
            </View>
          ))}
          <Animated.Text style={labelStyle}>{label}</Animated.Text>
        </View>
        {selectedOptions.length < maxSelections && (
          <View style={caretIconStyle}>
            <TouchableOpacity
              onPress={() => setShowOptions(!showOptions)}
              activeOpacity={1} // Prevents visual feedback
              accessible={false} // Prevent accessibility focus
              style={{
                padding: 0,
                backgroundColor: 'transparent',
                borderWidth: 0, // Ensure no border width
                borderColor: 'transparent',
                elevation: 0,
                shadowOpacity: 0,
              }}>
              <FontAwesomeIcon
                icon={Utils.icons.caretDown as IconProp}
                size={20}
                style={[
                  styles.noBorder,
                  {
                    transform: [
                      {rotate: showOptions ? '180deg' : '0deg'}, // Rotate only when dropdown is open
                    ],
                  },
                ]}
              />
            </TouchableOpacity>
          </View>
        )}
      </TouchableOpacity>
      {showOptions && (
        <ScrollView style={styles.optionsList} testID="options-list">
          {filteredOptions.map((item) => {
            const isSelected = selectedOptions.some(
              (opt) => opt.value === item.value,
            );
            const isHovered = hoveredOption === item.value;

            return (
              <Pressable
                key={item.value}
                onHoverIn={() => setHoveredOption(item.value)}
                onHoverOut={() => setHoveredOption(null)}
                onPress={() => handleSelect(item.value)}
                testID={`option-${item.value}`}>
                <View
                  style={[
                    styles.option,
                    {
                      backgroundColor: backgroundColor(
                        isHovered,
                        isSelected,
                        item.label,
                      ),
                    },
                  ]}>
                  <Text style={{marginLeft: 1}}>{item.label}</Text>
                </View>
              </Pressable>
            );
          })}
        </ScrollView>
      )}
    </View>
  );
};

export default AutocompleteMultiple;
