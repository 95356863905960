import React, {useContext, useMemo, JSX} from 'react';
import {View} from 'react-native';
import {useSelector} from 'react-redux';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import responsiveStyle from './../style';
import withAuth from '../../../../auth/withAuth';
import BreakpointConstants from '../../../../constants/BreakpointConstants';
import {getScreenType, prepareTransferBalanceData} from '../../../../utils';
import InvestmentChart from '../../../../components/InvestmentTable';
import {
  columnConfiguration,
  columnWidthArr,
} from '../ReviewTransfer/columnConfig';

const ConfirmPercentTransfer = (props) => {
  const {width} = useContext(Theme.ResponsiveContext);
  const style = responsiveStyle(width);
  const transferBalanceResponseData = useSelector(
    (state: any) => state.transferBalance.getTransferBalanceData,
  );
  const transferBalanceData =
    transferBalanceResponseData?.data?.items[0]?.investmentOptionChanges ?? [];
  const selectedTab = props?.route?.params?.tab === 'Current-Balance' ? 1 : 0;
  const screenType = getScreenType(width);

  const getPieChartSize = (width) => {
    if (BreakpointConstants.isLG(width)) {
      return {height: 319, width: 319};
    }
    return {height: 290, width: 290};
  };

  const renderCards = useMemo(() => {
    const elements: JSX.Element[] = [];
    if (transferBalanceData.length) {
      const transferBalanceData = prepareTransferBalanceData(
        props.editedData,
        false,
      );
      props.previousData = transferBalanceData;
      const mappedData = transferBalanceData.AllAssets;
      elements.push(
        <InvestmentChart
          className="mt-0"
          key={'confirm-percent-transfer-table'}
          data={mappedData}
          accountBalance={100}
          showChildRows={false}
          columnWidthArr={columnWidthArr}
          columnConfig={columnConfiguration}
          assetTotal={transferBalanceData.percentRoundTotal}
          horizontal={!BreakpointConstants.isLG(width)}
          legendTableTitle={{
            text: '',
            titleStyle: style.headingTable,
          }}
          showPieChart={false}
          showAlternateRowColors={false}
          fullHeight={true}
          setIsErrorInTable={props.setIsErrorInTable}
          setIsDirty={props.setIsDirty}
          showBorder={false}
          showLegendTable={true}
          showPercentLeftToTransfer={true}
          isMobileTableFooter={!BreakpointConstants.isLG(width)}
        />,
      );
    }
    return elements;
  }, [screenType, selectedTab, transferBalanceData]);

  const renderCharts = useMemo(() => {
    const elements: JSX.Element[] = [];
    if (transferBalanceData.length) {
      const tempEditedData = [...props.editedData];
      const investmentFormattedData = prepareTransferBalanceData(
        props.editedData,
        false,
      );
      const newElectionsData = prepareTransferBalanceData(tempEditedData, true);
      props.previousData = investmentFormattedData;
      const mappedData = investmentFormattedData.mappedData;
      const newElectionsMappedData = newElectionsData.mappedData;

      const pieChartSize = getPieChartSize(width);
      const isMobile = screenType === 'SM';
      elements.push(
        <View
          className="lg:pr-5"
          key={'confirm-percent-current-allocation-chart'}
          style={style.pieChartColumn}>
          <View>
            <Components.Text style={style.piechartHeading}>
              Current Allocations
            </Components.Text>
          </View>
          <InvestmentChart
            className="mt-0"
            data={mappedData}
            accountBalance={100}
            showChildRows={false}
            pieChartHeight={pieChartSize.height}
            pieChartWidth={pieChartSize.width}
            columnWidthArr={columnWidthArr}
            columnConfig={columnConfiguration}
            assetTotal={investmentFormattedData.percentRoundTotal}
            horizontal={isMobile}
            legendTableTitle={{
              text: '',
              titleStyle: style.headingTable,
            }}
            showPieChart={true}
            showLegendTable={false}
            showAlternateRowColors={false}
            fullHeight={true}
            setIsErrorInTable={props.setIsErrorInTable}
            setIsDirty={props.setIsDirty}
          />
        </View>,
      );
      elements.push(
        <View
          className="lg:pl-5"
          key={'confirm-percent-new-allocation-chart'}
          style={style.pieChartColumn}>
          <View>
            <Components.Text style={style.piechartHeading}>
              New Allocations
            </Components.Text>
          </View>
          <InvestmentChart
            className="mt-0"
            data={newElectionsMappedData}
            accountBalance={100}
            showChildRows={false}
            pieChartHeight={pieChartSize.height}
            pieChartWidth={pieChartSize.width}
            columnWidthArr={columnWidthArr}
            columnConfig={columnConfiguration}
            assetTotal={investmentFormattedData.percentRoundTotal}
            horizontal={isMobile}
            legendTableTitle={{
              text: '',
              titleStyle: style.headingTable,
            }}
            showPieChart={true}
            showLegendTable={false}
            showAlternateRowColors={false}
            fullHeight={true}
            setIsErrorInTable={props.setIsErrorInTable}
            setIsDirty={props.setIsDirty}
          />
        </View>,
      );
    }
    return elements;
  }, [screenType, selectedTab, transferBalanceData]);

  return (
    <View>
      <div id="confirmpagetable">{renderCards}</div>
      <View
        className="flex-col md:flex-row"
        style={{
          flexDirection: props.flexDirection,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {renderCharts}
      </View>
    </View>
  );
};

export default withAuth(ConfirmPercentTransfer);
