import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import RouteConstants from '../../constants/RouteConstants';
import ContactUs from '../../screens/ContactUs';
import Home from '../../screens/Home';
import MyInvestments from '../../screens/MyInvestments';
import MyInvestmentsElections from '../../screens/MyInvestmentsElections';
import PolicyDetails from '../../screens/PolicyDetails';
import PolicyServiceRequest from '../../screens/PolicyServiceRequest';
import Profile from '../../screens/Profile';
import TransferBalance from '../../screens/TransferBalance';
import PercentTransfer from '../../screens/TransferBalance/PercentTransfer';
import DollarTransfer from '../../screens/TransferBalance/DollarTransfer';
import MyStrategies from '../../screens/MyStrategies';
import MyReports from '../../screens/MyReports';

const Stack = createStackNavigator();

const StackNavigation = ({initialState}) => {
  if (initialState === undefined) {
    return null;
  }
  return (
    <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen
        name={RouteConstants.home}
        component={Home}
        initialParams={initialState}
      />
      <Stack.Screen
        name={RouteConstants.profile}
        component={Profile}
        initialParams={initialState}
      />
      <Stack.Screen
        name={RouteConstants.policydetails}
        component={PolicyDetails}
        initialParams={initialState}
      />
      <Stack.Screen
        name={RouteConstants.myreports}
        component={MyReports}
        initialParams={initialState}
      />
      <Stack.Screen
        name={RouteConstants.policyservicerequest}
        component={PolicyServiceRequest}
        initialParams={initialState}
      />
      <Stack.Screen
        name={RouteConstants.transferbalance}
        component={TransferBalance}
        initialParams={initialState}
      />
      <Stack.Screen
        name={RouteConstants.percenttransfer}
        component={PercentTransfer}
        initialParams={initialState}
      />
      <Stack.Screen
        name={RouteConstants.dollartransfer}
        component={DollarTransfer}
        initialParams={initialState}
      />
      <Stack.Screen
        name={RouteConstants.myinvestments}
        component={MyInvestments}
        initialParams={initialState}
      />
      <Stack.Screen
        name={RouteConstants.mystrategies}
        component={MyStrategies}
        initialParams={initialState}
      />
      <Stack.Screen
        name={RouteConstants.myinvestmentelections}
        component={MyInvestmentsElections}
        initialParams={initialState}
      />
      <Stack.Screen
        name={RouteConstants.contactus}
        component={ContactUs}
        initialParams={initialState}
      />
    </Stack.Navigator>
  );
};

export default StackNavigation;
