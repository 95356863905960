import React from 'react';
import {View} from 'react-native';
import BreakpointConstants from '../../constants/BreakpointConstants';
import {Components} from '@oneamerica/dxp-ui-components';
import {trackCustomEvent} from '../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';
import {setSelectedPolicy} from '../../redux/policylists/policyListsSlice';
import {getPoliciesList} from '../../redux/policies/action';
import {CommonActions} from '@react-navigation/native';
import RouteConstants from '../../constants/RouteConstants';

const DropDownView = ({
  styles,
  width,
  state,
  dispatch,
  navigate,
  accessToken,
  linkStyle,
}) => (
  <View
    className="z-[100]"
    style={[styles.menuItem, !BreakpointConstants.isMD(width) && styles.left0]}>
    <View style={styles.cardShadow}>
      {state.list.map((policy) => (
        <View key={policy.policyPlanNumber}>
          <Components.Link
            onPress={() => {
              if (
                state.selectedPolicy.policyPlanNumber !==
                policy.policyPlanNumber
              ) {
                trackCustomEvent(
                  analyticsTags.CapturePolicySelectorDropdownTags,
                );
                dispatch(setSelectedPolicy(policy));
                dispatch(
                  getPoliciesList(
                    policy.policyPlanNumber,
                    accessToken?.accessToken,
                  ),
                );
                navigate.dispatch(
                  CommonActions.reset({
                    index: 0,
                    routes: [
                      {
                        name: RouteConstants.home,
                        params: {
                          account: policy.policyPlanNumber,
                        },
                      },
                    ],
                  }),
                );
              }
            }}
            className="py-2"
            {...linkStyle(
              state.selectedPolicy.policyPlanNumber === policy.policyPlanNumber,
            )}
            accessibilityRole="button"
            accessibilityLabel={`Select policy plan: ${policy.policyPlanNumber} with plan number ${policy.policyPlanNumber}`}>
            {`${policy.policyPlanName} (${policy.policyPlanNumber})`}
          </Components.Link>
          <View style={styles.horizontalDivider} />
        </View>
      ))}
    </View>
  </View>
);
export default DropDownView;
