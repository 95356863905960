import React from 'react';
import {Dimensions, Platform, View} from 'react-native';
import moment from 'moment';
import {Components} from '@oneamerica/dxp-ui-components';
import notes from '../../mock/notes.json';
import responsiveStyle from './style';

export const renderReportsNotes = (width) => {
  const styles = responsiveStyle(width);
  return (
    <View style={[styles.topMargin]}>
      <Components.Text style={styles.notesText}>Notes</Components.Text>
      <View className="mt-4 mb-6" style={styles.horizontalDivider} />
      <View className="mb-6">
        {notes?.data?.notesReports?.item.map((item, index) => {
          return (
            <View className="flex-row mb-2" key={`${item}_${index}`}>
              <Components.Text style={styles.bulletStyle}>
                {'\u2022'}
              </Components.Text>
              <Components.Text style={[styles.notesContent]}>
                {item}
              </Components.Text>
            </View>
          );
        })}
      </View>
    </View>
  );
};

export function generateYears(startDate: string): string[] {
  const startYear: number = new Date(startDate).getFullYear();
  const currentYear: number = new Date().getFullYear();
  const years: string[] = [];
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year.toString());
  }
  return years;
}
export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const isDateValid = (
  date: string | undefined,
  DATEFORMAT,
): Date | undefined => {
  if (!date) {
    return undefined;
  }
  const selectedDate = moment(date, DATEFORMAT, true);
  return selectedDate.isValid() ? selectedDate.toDate() : undefined;
};

export const isValidDateFormat = (date, DATEFORMAT) => {
  const dateConverted = moment(date, DATEFORMAT, true);
  return dateConverted.isValid();
};

export const isValidDateForLeapYear = (dateString, DATEFORMAT) => {
  if (isValidDateFormat(dateString, DATEFORMAT)) {
    const parts = dateString.split('/');
    const month = parseInt(parts[0], 10);
    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    if (
      month < 1 ||
      month > 12 ||
      day < 1 ||
      day > 31 ||
      ([4, 6, 9, 11].includes(month) && day === 31)
    ) {
      return false;
    }
    if (month === 2) {
      // Check for leap year
      const isLeap = year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
      if (day > 29 || (day === 29 && !isLeap)) {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const getSize = (width: number, height: number) => {
  if (width < 400 || (width >= 400 && width < 600 && height >= 400)) {
    return 'SMALL';
  }
};

export const isMobile = () => {
  const window = Dimensions.get('window');
  const screenSize = getSize(window.width, window.height);
  const isMobileDevice =
    screenSize === 'SMALL' ||
    Platform.OS === 'ios' ||
    Platform.OS === 'android';
  return isMobileDevice;
};
