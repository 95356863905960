import React, {FC} from 'react';
import {View, FlatList, StyleProp, ViewStyle, ScrollView} from 'react-native';
import {Components} from '@oneamerica/dxp-ui-components';
import responsiveStyle from './style';

export interface FlatListColProps {
  key: string;
  title: string;
  style?: ViewStyle;
  className?: string;
}

interface FlatListTableProps {
  columns: FlatListColProps[];
  data: object[];
  altRow?: boolean;
  horizontal?: boolean;
  scrollContainerStyle?: StyleProp<ViewStyle>;
}
const FlatListTable: FC<FlatListTableProps> = ({
  columns,
  data,
  altRow = false,
  horizontal = false,
  scrollContainerStyle = {},
}) => {
  const style = responsiveStyle();
  const getRowItem = ({item, index}) => (
    <View
      style={[
        style.row,
        altRow && index % 2 === 0 ? style.altRow : style.tableRow,
      ]}>
      {columns.map((column, columnIndex) => (
        <View
          key={`${column.key}-${columnIndex}`}
          style={[
            style.tableCol,
            columnIndex !== columns.length - 1 && style.columnSeparator,
            column.style,
          ]}
          className={column.className}>
          <Components.Text style={style.tableColText}>
            {item[column.key] || '-'}
          </Components.Text>
        </View>
      ))}
    </View>
  );

  const getHeaderItem = () => (
    <View style={style.tableHeader}>
      {columns.map((column, columnIndex) => (
        <View
          key={`${column.key}-${columnIndex}`}
          style={[
            style.tableHeadingCol,
            columnIndex !== columns.length - 1 && style.columnSeparator,
            column.style,
          ]}
          className={column.className}>
          <Components.Text style={style.tableHeadingColText}>
            {column.title}
          </Components.Text>
        </View>
      ))}
    </View>
  );

  return (
    <ScrollView
      horizontal={horizontal}
      contentContainerStyle={scrollContainerStyle}>
      <View style={style.container}>
        <FlatList
          data={data}
          renderItem={getRowItem}
          ListHeaderComponent={getHeaderItem}
          keyExtractor={(_item, index) => index.toString()}
          stickyHeaderIndices={[0]}
          persistentScrollbar={true}
          showsHorizontalScrollIndicator={true}
        />
      </View>
    </ScrollView>
  );
};

export default FlatListTable;
