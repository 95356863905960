import React from 'react';
import InfoWrapper from '../../../../components/InfoWrapper/InfoWrapper';
import {formatCurrency, formatDate} from '../../../../utils';
import {dividendOptions} from '../../constants';

export const GeneralPolicyData = ({
  generalPolicyDataRender,
  policyHighlights,
}) => {
  const dividents = generalPolicyDataRender?.dividendOption ? (
    <InfoWrapper
      width="w-1/2 md:w-1/3"
      title={'Dividend Option'}
      subTitle
      information={
        dividendOptions[
          policyHighlights[0]?.generalPolicyData?.dividendOption
        ] || policyHighlights[0]?.generalPolicyData?.dividendOption
      }
    />
  ) : null;

  const withdrawlsAndDividents = generalPolicyDataRender?.withdrawals ? (
    <InfoWrapper
      width="w-1/2 md:w-1/3"
      title={'Cash Withdrawals'}
      subTitle
      information={formatCurrency(
        policyHighlights[0]?.generalPolicyData?.withdrawals,
      )}
    />
  ) : (
    dividents
  );

  return generalPolicyDataRender?.expiryDate ? (
    <InfoWrapper
      width="w-1/2 md:w-1/3"
      title={'Expiry Date'}
      subTitle
      information={formatDate(
        policyHighlights[0]?.generalPolicyData?.expiryDate,
      )}
    />
  ) : (
    withdrawlsAndDividents
  );
};
