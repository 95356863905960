import axios from 'axios';
import {Platform} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import {v4 as uuidv4} from 'uuid';
import {
  MULE_API_BASE_URL,
  AEM_API_BASE_URL,
  PROFILE_API_BASE_URL,
} from '../../config/getEnvironmentVars';
import {getCookieValue} from '../../utils/cookies';

const createAxiosInstance = (baseURL, headers) => {
  const instance = axios.create({
    baseURL,
    headers,
  });
  const checkNetworkStatus = async () => {
    const state = await NetInfo.fetch();
    return state.isConnected;
  };
  instance.interceptors.request.use(async (config) => {
    if (
      config.baseURL === MULE_API_BASE_URL ||
      config.baseURL === PROFILE_API_BASE_URL
    ) {
      const newCorrelationId = uuidv4();
      config.headers['X-OA-CorrelationID'] = newCorrelationId;
    }
    if (Platform.OS !== 'web') {
      const isOnline = await checkNetworkStatus();
      if (!isOnline) {
        throw new Error('Network is not available');
      }
    }

    return config;
  });
  instance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response);
    },

    (error) => {
      if (!error.response) {
        return Promise.reject(error);
      }

      if ([401, 400].includes(error.response?.status)) {
        return Promise.reject(error.response);
      }
      return Promise.reject(error.response);
    },
  );
  return instance;
};

export const axiosInstanceAEM = createAxiosInstance(AEM_API_BASE_URL, {
  'content-Type': 'application/json',
});

const sessionId = getCookieValue('X-OA-SessionID');
export const axiosInstancePolicyHolder = createAxiosInstance(
  MULE_API_BASE_URL,
  {
    'X-OA-ClientId': 'CLIENTWEB',
    'Content-Type': 'application/json',
    'X-OA-SessionID': sessionId,
  },
);

export const axiosProfileHolder = createAxiosInstance(PROFILE_API_BASE_URL, {
  'X-OA-ClientId': 'CLIENTWEB',
  'Content-Type': 'application/json',
  'X-OA-SessionID': sessionId,
});
