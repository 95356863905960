import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  JSX,
} from 'react';
import {Text, TouchableHighlight, View, ViewStyle} from 'react-native';
import {Table, TableWrapper, Cell} from 'react-native-reanimated-table';
import {useNavigationState} from '@react-navigation/native';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper';
import responsiveStyle from './style';
import withAuth from '../../auth/withAuth';
import {AdditionalDetailCard} from './components/AdditionalDetailCard';
import InvestmentChart from './../../components/InvestmentTable';
import {
  formatDate,
  prepareInvestmentData,
  preparestrategiesData,
} from './../../utils';
import {
  getAffiliate,
  getTaxQualification,
  getTerminatedReason,
  getType,
  validateElement,
  makepolicyRelationShipData,
  getLoanItem,
  truncatedText,
  getEleSpacing,
} from './helpers';
import {useHomeSelector} from './hooks/home';
import {titleCase} from '../../utils';
import {PolicyValueCard} from './components/PolicyValueCard';
import {BillingAndPremiumCard} from './components/BillingAndPremiumCard';
import {LoansCard} from './components/LoansCard';
import {PolicyHighlightsCard} from './components/PolicyHighlightsCard';
import PolicyRelationshipsInfoModal from './components/PolicyRelationshipsInfoModal';
import PolicyRelationshipsTypeModal from './components/PolicyRelationshipsTypeModal';
import {
  columnConfiguration,
  childRowsColumnConfig,
  columnWidthArr,
  stratergyColumnConfiguration,
  columnWidthArrStrategy,
} from './columnConfig';
import {PolicyNotes} from './components/PolicyNotes';
import BreakpointConstants from '../../constants/BreakpointConstants';
import PolicyDropdown from '../../components/PolicyDropdown';
import ErrorWrapper from '../../components/ErrorWrapper';
import StrategiesInfoModal from './components/StrategiesInfoModal';
import {
  trackCustomEvent,
  trackPage,
} from '../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';

const Home = (props) => {
  const {width} = useContext(Theme.ResponsiveContext);
  const style = responsiveStyle();
  const [isModalStaretgyVisible, setIsModalStaretgyVisible] = useState(false);
  const navigationState = useNavigationState((state) => state);
  const previousRouteNameRef = useRef<string | null>(null);

  useEffect(() => {
    const currentRouteName =
      navigationState.routes[navigationState.index]?.name;
    if (
      previousRouteNameRef.current !== currentRouteName &&
      currentRouteName === 'Home'
    ) {
      trackPage(analyticsTags.createHomePageViewTags);
    }
    previousRouteNameRef.current = currentRouteName;
  }, [navigationState]);

  // State for modal visibility
  const handleModalToggle = () => {
    setIsModalStaretgyVisible(!isModalStaretgyVisible); // Function to toggle modal visibility
  };

  const linkStyle = {
    style: style.cardActionButton,
    linkStyle: style.cardActionButton,
    linkHoverStyle: style.cardActionButton,
    focusStyle: style.cardActionButtonFocus,
  };

  const getViewStyle = () => {
    return [
      style.tableCellText1,
      {
        fontSize: BreakpointConstants.isMD(width) ? 16 : 14,
      },
    ] as ViewStyle;
  };

  const linkStylePRName = {
    style: getViewStyle(),
    textStyle: getViewStyle(),
    linkStyle: getViewStyle(),
    hoverStyle: getViewStyle(),
  };
  const {
    account,
    buttonStatus,
    generalPolicyData,
    generalPolicyDataRender,
    hasTotalDeathBenefit,
    initialPolicyData,
    moreBillingAndPremium,
    morePolicyValue,
    policyHighlights,
    policyRelationshipsData,
    policyStatusButton,
    policyValue,
    policyValueCardData,
    popupData,
    popupDataAll,
    terminatedReason,
    isInfoModalVisible,
    setIsInfoModalVisible,
    infoModalContent,
    getModalDetails,
    navigateToPolicyDetails,
    setModalTitles,
    setIsModalViewAllRelationShip,
    setIsModalVisible,
    setRelationType,
    relationType,
    modalTitles,
    isModalVisible,
    showCOBInPolicyHighlights,
    cobPolicyNumber,
    cobPolicyStatus,
    cobStatusButton,
    policyTerminationDate,
    cobPolicyStatusReason,
    cobTerminationDate,
    navigateToMyInvestments,
    navigateToMyStrategies,
    navigateToCOBDetails,
    policyNotes,
  } = useHomeSelector(props);

  const trackRelationshipType = (relationType) => {
    const relationTypeIndex = {
      Annuitant: 'CaptureRelationshipsAnnuitantTags',
      'Primary Insured': 'CaptureRelationshipsPrimaryInsuredTags',
      Owner: 'CaptureRelationshipsOwnerTags',
      Payor: 'CaptureRelationshipsPayorTags',
      Beneficiary: 'CaptureRelationshipsBeneficiaryTags',
      'First Beneficiary': 'CaptureRelationshipsPrimaryBeneficiaryTags',
    };
    trackCustomEvent(analyticsTags[relationTypeIndex[relationType]]);
  };

  const getPolicyRelationshipInformation = () => {
    trackCustomEvent(analyticsTags.CapturePolicyRelationshipsCardInfoModalTags);
    getModalDetails();
  };

  const viewAllRelationShips = () => {
    trackCustomEvent(analyticsTags.CaptureViewAllRelationshipsTags);
    navigateToPolicyDetails('PolicyRelationships');
  };

  const viewStrategyDetails = () => {
    trackCustomEvent(analyticsTags.CaptureMyStrategiesCardViewDetailsTags);
    navigateToMyStrategies();
  };

  const viewInvestmentDetails = () => {
    trackCustomEvent(analyticsTags.CaptureInvestmentCardViewDetailsTags);
    navigateToMyInvestments();
  };

  const viewMyStrategies = () => {
    trackCustomEvent(analyticsTags.CaptureMyStrategiesCardInfoModalTags);
    handleModalToggle();
  };

  const seePolicyDetails = (item) => {
    trackRelationshipType(item.relationType);
    setIsModalVisible(true);
    setIsModalViewAllRelationShip(false);
    makepolicyRelationShipData(
      item.relationType,
      policyRelationshipsData,
      setModalTitles,
      setRelationType,
    );
  };

  const getPolicyHighLightCard = (fetchedEleRow, fetchedElements) => {
    const elements = fetchedElements;
    let eleRow = fetchedEleRow;
    if (Object.keys(account).length !== 0) {
      eleRow += BreakpointConstants.isLG(width) ? 1 / 2 : 1;
      elements.push(
        <PolicyHighlightsCard
          key={'policy-highlights-card'}
          className={`w-full lg:w-1/2
           ${getEleSpacing(eleRow)}`}
          getType={getType}
          validateElement={validateElement}
          getTaxQualification={getTaxQualification}
          titleCase={titleCase}
          getAffiliate={getAffiliate}
          policyStatusButton={policyStatusButton}
          policyHighlights={policyHighlights}
          buttonStatus={buttonStatus}
          getTerminatedReason={getTerminatedReason}
          account={account}
          terminatedReason={terminatedReason}
          showCOBInPolicyHighlights={showCOBInPolicyHighlights}
          cobPolicyNumber={cobPolicyNumber}
          cobPolicyStatus={cobPolicyStatus}
          cobStatusButton={cobStatusButton}
          policyTerminationDate={policyTerminationDate}
          linkStyle={linkStyle}
          navigateToCOBDetails={navigateToCOBDetails}
          row={eleRow}
          popupData={popupData}
          cobPolicyStatusReason={cobPolicyStatusReason}
          cobTerminationDate={cobTerminationDate}
          generalPolicyDataRender={generalPolicyDataRender}
        />,
      );
    }
    return {
      eleRow,
      elements,
    };
  };

  const getInvestmentsCard = (fetchedEleRow, fetchedElements) => {
    let eleRow = fetchedEleRow;
    const elements = fetchedElements;
    if (
      policyHighlights[0]?.investments &&
      policyHighlights[0]?.accountConfig?.allowMyInvestments
    ) {
      const investmentFormattedData = prepareInvestmentData(
        policyHighlights[0]?.investments,
        policyHighlights[0]?.accountBalance,
        policyHighlights[0]?.assertClass,
      );
      const mappedData = investmentFormattedData.mappedData;
      const assetTotal = investmentFormattedData.assetTotal;
      eleRow += BreakpointConstants.isLG(width) ? 1 / 2 : 1;
      elements.push(
        <View
          key={'investments-card'}
          className={`w-full lg:w-1/2 ${getEleSpacing(eleRow)}`}>
          <View style={[style.cardShadow, eleRow > 1 && style.flex]}>
            <View
              className="flex-col md:flex-row"
              testID={'investmentCardHeader'}>
              <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
                My Investments
              </Components.Text.H3>
              <Components.Text
                style={style.investmentCardDate}
                className="md:self-end">
                As of {formatDate(policyValue.valueAsOfDate)}
              </Components.Text>
            </View>
            <View className="mt-4" style={style.horizontalDivider} />
            <InvestmentChart
              data={mappedData}
              accountBalance={policyHighlights[0]?.accountBalance}
              showChildRows={false}
              pieChartHeight={191}
              pieChartWidth={191}
              columnWidthArr={columnWidthArr}
              columnConfig={columnConfiguration}
              childRowsColumnConfig={childRowsColumnConfig}
              assetTotal={assetTotal}
              legendTableTitle={{text: 'Current Balance by Asset Class'}}
              showPieChart={true}
              showLegendTable={true}
              showAlternateRowColors={true}
              showBorder={true}
            />
            <Components.Link
              className="mt-4"
              onPress={viewInvestmentDetails}
              accessibilityLabel="View Investment Details"
              accessibilityRole="button"
              {...linkStyle}>
              View Details
            </Components.Link>
          </View>
        </View>,
      );
    }
    return {
      eleRow,
      elements,
    };
  };

  const getStrategiesCard = (fetchedEleRow, fetchedElements) => {
    let eleRow = fetchedEleRow;
    const elements = fetchedElements;
    if (
      policyHighlights[0]?.investments &&
      policyHighlights[0]?.accountConfig?.allowMyStrategies
    ) {
      const investmentFormattedData = preparestrategiesData(
        policyHighlights[0]?.investments,
        policyHighlights[0]?.accountBalance,
        policyHighlights[0]?.assertClass,
      );
      const mappedData = investmentFormattedData.mappedData;
      const assetTotal = investmentFormattedData.assetTotal;
      eleRow += BreakpointConstants.isLG(width) ? 1 / 2 : 1;
      elements.push(
        <View
          key={'investments-card'}
          className={`w-full lg:w-2/4 ${getEleSpacing(eleRow)}`}>
          <View style={[style.cardShadow, eleRow > 1 && style.flex]}>
            <View
              className="flex-col md:flex-col"
              testID={'investmentCardHeader'}>
              <View style={[style.titleContainer]}>
                <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
                  My Strategies
                </Components.Text.H3>
                <TouchableHighlight
                  underlayColor="#fff"
                  style={[style.Details]}
                  accessible={true}
                  accessibilityLabel="My Strategies"
                  onPress={viewMyStrategies}>
                  <Text style={style.Details}>i</Text>
                </TouchableHighlight>
              </View>
            </View>
            <View className="mt-4" style={style.horizontalDivider} />
            <InvestmentChart
              flexDirection="column"
              horizontal={!BreakpointConstants.isLG(width)}
              data={mappedData}
              accountBalance={policyHighlights[0]?.accountBalance}
              showChildRows={false}
              pieChartHeight={191}
              pieChartWidth={191}
              columnWidthArr={columnWidthArrStrategy}
              columnConfig={stratergyColumnConfiguration}
              assetTotal={assetTotal}
              legendTableTitle={{
                text: 'Account Balance Percentage By Strategy',
              }}
              showPieChart={true}
              showLegendTable={true}
              showAlternateRowColors={true}
              showBorder={true}
            />
            <Components.Link
              className="mt-4"
              onPress={viewStrategyDetails}
              accessibilityLabel="View Strategies Details"
              accessibilityRole="button"
              {...linkStyle}>
              View Details
            </Components.Link>
          </View>
        </View>,
      );
    }
    return {
      eleRow,
      elements,
    };
  };

  const getPolicyValueCard = (fetchedEleRow, fetchedElements) => {
    let eleRow = fetchedEleRow;
    const elements = fetchedElements;

    if (policyValueCardData?.length > 0) {
      eleRow += BreakpointConstants.isLG(width) ? 1 / 4 : 1;
      elements.push(
        <PolicyValueCard
          key={'policy-value-card'}
          row={eleRow}
          className={`w-full md:w-1/2 lg:w-1/4 ${getEleSpacing(eleRow)}`}
          policyValueCardData={policyValueCardData}
          policyValue={policyValue}
          hasTotalDeathBenefit={hasTotalDeathBenefit}
          morePolicyValue={morePolicyValue}
          linkStyle={linkStyle}
          navigateToPolicyDetails={navigateToPolicyDetails}
        />,
      );
    }

    return {
      eleRow,
      elements,
    };
  };

  const getBillingAndPremiumCard = (fetchedEleRow, fetchedElements) => {
    let eleRow = fetchedEleRow;
    const elements = fetchedElements;

    if (initialPolicyData.length > 0) {
      eleRow += BreakpointConstants.isLG(width) ? 1 / 4 : 1;
      elements.push(
        <BillingAndPremiumCard
          key={'billing-card'}
          row={eleRow}
          className={`w-full md:w-1/2 lg:w-1/4 ${getEleSpacing(eleRow)}`}
          initialPolicyData={initialPolicyData}
          generalPolicyData={generalPolicyData}
          linkStyle={linkStyle}
          moreBillingAndPremium={moreBillingAndPremium}
          navigateToPolicyDetails={navigateToPolicyDetails}
        />,
      );
    }

    return {
      eleRow,
      elements,
    };
  };

  const getLoansCard = (fetchedEleRow, fetchedElements) => {
    let eleRow = fetchedEleRow;
    const elements = fetchedElements;

    if (getLoanItem(policyValue)) {
      eleRow += BreakpointConstants.isLG(width) ? 1 / 4 : 1;
      elements.push(
        <LoansCard
          key={'loans-card'}
          className={`w-full md:w-1/2 lg:w-1/4 ${getEleSpacing(eleRow)}`}
          getLoanItem={() => getLoanItem(policyValue)}
          policyValue={policyValue}
          row={eleRow}
        />,
      );
    }

    return {
      eleRow,
      elements,
    };
  };

  const getAdditionalDetailCard = (fetchedEleRow, fetchedElements) => {
    let eleRow = fetchedEleRow;
    const elements = fetchedElements;

    eleRow += BreakpointConstants.isLG(width) ? 1 / 4 : 1;
    elements.push(
      <AdditionalDetailCard
        key={'additional-details-card'}
        className={`w-full md:w-1/2 lg:w-1/4 ${getEleSpacing(eleRow)}`}
        row={eleRow}
        {...props}
      />,
    );

    return {
      eleRow,
      elements,
    };
  };

  const getRelationType = (relationType: string) => {
    if (relationType === 'First Beneficiary') {
      return 'Primary Beneficiary';
    }
    if (relationType === 'Second Beneficiary') {
      return 'Contingent Beneficiary';
    }
    return relationType;
  };

  const getPolicyRelationshipsCard = (fetchedEleRow, fetchedElements) => {
    let eleRow = fetchedEleRow;
    const elements = fetchedElements;

    eleRow += BreakpointConstants.isLG(width) ? 1 / 2 : 1;
    elements.push(
      <View
        key={'policy-relationships-card'}
        className={`w-full lg:w-1/2 ${getEleSpacing(eleRow)}`}>
        <View style={[style.cardShadow, eleRow > 1 && style.flex]}>
          <View style={style.cardHeader}>
            <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
              Policy Relationships
            </Components.Text.H3>
            <TouchableHighlight
              underlayColor="#fff"
              accessible={true}
              accessibilityLabel="Policy Relationship Information icon link"
              onPress={getPolicyRelationshipInformation}>
              <Text style={style.Details}>i</Text>
            </TouchableHighlight>
          </View>
          <View className="mt-4" style={style.horizontalDivider} />
          <View style={style.contentWrapper}>
            <View className="mt-4" style={style.policyTableContainer}>
              <Table>
                {popupData?.map((item: any, index: number) => (
                  <TableWrapper
                    key={`${item.relationType}-${index}`}
                    style={{
                      ...style.row,
                      backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F3F3F3',
                    }}>
                    <Cell
                      // @ts-ignore
                      className="flex-1 md:flex-2"
                      style={[
                        index === 0 ? style.tableHeadingCell : style.tableCell2,
                      ]}
                      data={
                        <Components.Text
                          className={`text-14${
                            index === 0 ? '' : ' md:text-16'
                          }`}
                          style={
                            index === 0
                              ? style.tableHeadingCellText
                              : style.tableCellText
                          }>
                          {getRelationType(item.relationType)}
                        </Components.Text>
                      }
                    />
                    <Cell
                      // @ts-ignore
                      className="flex-1 md:flex-3"
                      style={
                        index === 0 ? style.tableHeadingCell1 : style.tableCell1
                      }
                      data={
                        index === 0 ? (
                          <Components.Text
                            className={`text-14`}
                            style={style.tableHeadingCellText}>
                            {truncatedText(item.personDetails?.name?.first, 20)}
                          </Components.Text>
                        ) : (
                          <Components.Link
                            accessibilityRole="button"
                            accessibilityLabel={
                              'Click to see policy details of ' +
                              item.personDetails?.name?.first
                            }
                            onPress={() => seePolicyDetails(item)}
                            {...linkStylePRName}>
                            {truncatedText(item.personDetails?.name?.first, 20)}
                          </Components.Link>
                        )
                      }
                    />
                  </TableWrapper>
                ))}
              </Table>
            </View>
            {popupDataAll.length > 5 && (
              <Components.Link
                accessibilityLabel="View Policy Relationships Details"
                accessibilityRole="button"
                className="mt-4"
                onPress={viewAllRelationShips}
                {...linkStyle}>
                VIEW ALL RELATIONSHIPS
              </Components.Link>
            )}
          </View>
        </View>
      </View>,
    );

    return {
      eleRow,
      elements,
    };
  };

  const renderCards = useMemo(() => {
    let elements: JSX.Element[] = [];
    let eleRow = 0;

    const policyHighlightCard = getPolicyHighLightCard(eleRow, elements);

    eleRow = policyHighlightCard.eleRow;
    elements = policyHighlightCard.elements;

    if (!['Terminated', 'Suspended'].includes(buttonStatus)) {
      /*Investments */
      const investmentsCard = getInvestmentsCard(eleRow, elements);

      eleRow = investmentsCard.eleRow;
      elements = investmentsCard.elements;

      /*Stratergies */
      const strategiesCard = getStrategiesCard(eleRow, elements);

      eleRow = strategiesCard.eleRow;
      elements = strategiesCard.elements;

      /* Policy Values */
      const policyValueCard = getPolicyValueCard(eleRow, elements);

      eleRow = policyValueCard.eleRow;
      elements = policyValueCard.elements;

      /* Billing & Premiums */
      const billingAndPremiumCard = getBillingAndPremiumCard(eleRow, elements);

      eleRow = billingAndPremiumCard.eleRow;
      elements = billingAndPremiumCard.elements;

      /* Loans */
      const loansCard = getLoansCard(eleRow, elements);

      eleRow = loansCard.eleRow;
      elements = loansCard.elements;

      /* Additional Details */
      const additionalDetailCard = getAdditionalDetailCard(eleRow, elements);

      eleRow = additionalDetailCard.eleRow;
      elements = additionalDetailCard.elements;

      /* Policy Relationships */
      const policyRelationshipsCard = getPolicyRelationshipsCard(
        eleRow,
        elements,
      );

      // eleRow = policyRelationshipsCard.eleRow;
      elements = policyRelationshipsCard.elements;
    }
    return elements;
  }, [
    width,
    account,
    policyHighlights,
    policyValueCardData,
    initialPolicyData,
    policyValue,
  ]);

  return (
    <ScreenWrapper
      scrollFooter
      paddingTop
      paddingBottom
      paddingHorizontal={false}>
      {/* POLICY DROPDOWN */}
      <PolicyDropdown
        className={'px-5 md:px-6 lg:px-9 mb-6'}
        accessToken={props.accessToken}
      />
      <ErrorWrapper>
        <View
          className={
            !['Terminated', 'Suspended'].includes(buttonStatus)
              ? 'flex flex-row flex-wrap px-1 md:px-2 lg:px-5'
              : 'flex justify-center items-center px-1 md:px-2 lg:px-5'
          }>
          {renderCards}
        </View>
        {!['Terminated', 'Suspended'].includes(buttonStatus) &&
          policyNotes?.length > 0 && (
            <>
              <PolicyNotes policyNotes={policyNotes} shouldShowMargin={true} />
              <View
                style={{
                  flexDirection: 'row',
                  paddingBottom: 32,
                }}>
                <Components.Text
                  style={{
                    fontSize: 20,
                    lineHeight: 20,
                    marginRight: 5,
                    marginLeft: 32,
                  }}>
                  {'\u2022'}
                </Components.Text>
                <Components.Text style={[style.disclaimerText, {flex: 1}]}>
                  OneAmerica Financial<sup style={{fontSize: 9}}>SM</sup> is the
                  marketing name for the companies of OneAmerica Financial.
                  Products issued and underwritten by the following OneAmerica
                  Financial companies: American United Life Insurance Company®
                  (AUL) and The State Life Insurance Company® (State Life),
                  Indianapolis, IN.
                </Components.Text>
              </View>
            </>
          )}
        <PolicyRelationshipsInfoModal
          isInfoModalVisible={isInfoModalVisible}
          setIsInfoModalVisible={setIsInfoModalVisible}
          infoModalContent={infoModalContent}
        />
        <PolicyRelationshipsTypeModal
          relationType={relationType}
          modalTitles={modalTitles}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
        <StrategiesInfoModal
          isVisible={isModalStaretgyVisible}
          toggleModal={handleModalToggle}
        />
      </ErrorWrapper>
    </ScreenWrapper>
  );
};

export default withAuth(Home);
