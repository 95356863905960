import {Color, Font, Metrics, Responsive} from '@oneamerica/dxp-ui-components';

const styles = () => {
  return Responsive.create({
    container: {
      width: '100%',
    },
    inputHelperText: {
      alignSelf: 'flex-start',
      fontSize: Font.Small.size,
      color: Color.gray6,
      marginRight: Metrics.small,
    },
    inputHelperTextError: {
      color: Color.oaRed,
      paddingLeft: 12,
    },
  })();
};

export default styles;
