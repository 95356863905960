import React, {useContext, useEffect, useRef, useMemo, JSX} from 'react';
import {View, Pressable, Linking, Platform} from 'react-native';
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faEdit} from '@fortawesome/pro-solid-svg-icons/faEdit';
import {useNavigation, useNavigationState} from '@react-navigation/native';
import {useSelector} from 'react-redux';
import {DrawerNavigationProp} from '@react-navigation/drawer';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import responsiveStyle from './style';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper';
import withAuth from '../../auth/withAuth';
import Breadcrumbs from '../../components/Breadcrumbs';
import InvestmentChart from '../../components/InvestmentTable';
import {useHomeSelector} from '../Home/hooks/home';
import {
  columnConfiguration,
  childRowsColumnConfig,
  columnWidthArr,
  columnWidthArrMob,
} from './columnConfig';
import {
  prepareInvestmentData,
  isHomeOfficeUser,
  formatDate,
  getScreenType,
} from '../../utils';
import ColorConstants from '../../constants/ColorConstants';
import PolicyDropdown from '../../components/PolicyDropdown';
import {
  childRowsColumnConfigForIOE,
  columnConfigurationForIOE,
  widthArrIOE,
  widthArrMobIOE,
} from './columnConfigIOE';
import BreakpointConstants from '../../constants/BreakpointConstants';
import {getPieChartSize} from './helpers';
import RouteConstants from '../../constants/RouteConstants';
import ErrorWrapper from '../../components/ErrorWrapper';
import {AlertLevel} from '../../constants/UtilsConstants';
import {
  trackCustomEvent,
  trackPage,
} from '../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';

const openURL = (url: string) => {
  (async function () {
    try {
      await Linking.openURL(url);
    } catch (error) {
      console.log(error);
    }
  })();
};

const MyInvestments = (props) => {
  const {account, policyValue, policyHighlights} = useHomeSelector(props);
  const policyLists = useSelector((state: any) => state.policyLists);
  const isTransactionConfirmed = useSelector(
    (state: any) => state.investments.isTransactionConfirmed,
  );
  const navigateToTransferBalance = () => {
    navigation.navigate(RouteConstants.transferbalance);
  };
  const {width} = useContext(Theme.ResponsiveContext);
  const style = responsiveStyle(width);
  const linkStyle = {
    style: style.linkStyle,
    linkStyle: style.linkStyle,
    linkHoverStyle: style.linkStyle,
    hoverStyle: style.linkStyle,
    focusStyle: style.linkStyle,
  };
  const navigationState = useNavigationState((state) => state);
  const previousRouteNameRef = useRef<string | null>(null);
  useEffect(() => {
    const currentRouteName =
      navigationState.routes[navigationState.index]?.name;
    if (
      previousRouteNameRef.current !== currentRouteName &&
      currentRouteName === 'My-Investments'
    ) {
      trackPage(analyticsTags.createMyInvestmentPageViewTags);
    }
    previousRouteNameRef.current = currentRouteName;
  }, [navigationState]);

  const navigation = useNavigation<DrawerNavigationProp<any>>();
  const selectedTab = props?.route?.params?.tab === 'Future-Elections' ? 1 : 0;
  const screenType = getScreenType(width);

  const navigateToContactUs = () => {
    navigation.navigate(RouteConstants.contactus);
  };
  const navigateToMyReports = () => {
    navigation.navigate(RouteConstants.myreports);
  };

  const getEditTransferBalanceTextColor = () => {
    if (!policyHighlights[0]?.accountConfig.allowXferStatus) {
      return ColorConstants.grey200;
    } else {
      return ColorConstants.oaBlueTint;
    }
  };
  const renderCards = useMemo(() => {
    const elements: JSX.Element[] = [];
    if (policyHighlights[0]?.investments) {
      const investmentFormattedData = prepareInvestmentData(
        policyHighlights[0]?.investments,
        policyHighlights[0]?.accountBalance,
        policyHighlights[0]?.assertClass,
      );
      const mappedData = investmentFormattedData.mappedData;
      const assetTotal = investmentFormattedData.assetTotal;
      const totalpercentByVaule = investmentFormattedData.totalpercentByVaule;
      const asOfDate = formatDate(
        policyValue.valueAsOfDate,
        'MMM DD, YYYY',
        '-',
      );
      const pieChartSize = getPieChartSize(width);
      const isMobile = screenType === 'SM';
      const navigateToMyInvestmetElections = () => {
        navigation.navigate(RouteConstants.myinvestmentelections, {
          account: policyLists?.selectedPolicy?.policyPlanNumber,
        });
      };
      const isHomeOfcUser = isHomeOfficeUser();
      const isLargeScreen = BreakpointConstants.isLG(width);
      elements.push(
        <View className="lg:flex-row pt-6" key={'investment-tabs'}>
          <View className="w-full p-0 mt-1">
            <View style={[style.cardShadow, style.noPadding]}>
              <Components.CardTabs
                content={[
                  <View
                    style={[style.contentPadding, style.tabTitleSize]}
                    key="tab1">
                    <View style={style.componentPadding}>
                      {isHomeOfcUser && (
                        <View>
                          <Components.AlertMessage
                            className="mb-4 py-4 flex-1"
                            alertLevel={AlertLevel.INFORMATION}
                            style={style.marginBottom16}
                            testID="alertComponent"
                            fontSize={16}
                            showCloseButton={false}
                            iconSize={isLargeScreen ? 52.36 : 24}
                            title="Submitting investment updates on behalf of the user is not allowed due to home office access."
                          />
                        </View>
                      )}
                      {policyHighlights[0]?.accountConfig?.allowXferReason !==
                        null &&
                        policyHighlights[0]?.accountConfig?.allowXferReason !==
                          '' &&
                        policyHighlights[0]?.accountConfig?.allowXferReason?.indexOf(
                          'TR5',
                        ) !== -1 &&
                        Platform.OS === 'web' &&
                        // @ts-ignore
                        window.localStorage.getItem('isBannerHidden') !==
                          'true' && (
                          <Components.AlertMessage
                            showCloseButton={true}
                            onCloseMessage={() => {
                              if (Platform.OS === 'web') {
                                // @ts-ignore
                                window.localStorage.setItem(
                                  'isBannerHidden',
                                  'true',
                                );
                              }
                            }}
                            style={style.marginBottom16}
                            alertLevel={AlertLevel.ALERT}
                            fontSize={18}
                            title="You have a pending transaction in process.">
                            <Components.Text
                              style={style.alertBannerContentMargin}>
                              You can’t make any changes until it has been
                              processed.
                            </Components.Text>
                            <Components.Text>
                              {`If you have any questions, please reference the `}
                              <Components.Link
                                onPress={() => {
                                  trackCustomEvent(
                                    analyticsTags.CaptureContactUsInvestmentPendingTransactionTags,
                                  );
                                  navigateToContactUs();
                                }}
                                accessibilityLabel="Contact Us"
                                accessibilityRole="button"
                                {...linkStyle}>
                                Contact Us
                              </Components.Link>{' '}
                              {` page.`}
                            </Components.Text>
                          </Components.AlertMessage>
                        )}
                      {policyHighlights[0]?.accountConfig?.allowXferReason !==
                        null &&
                        policyHighlights[0]?.accountConfig?.allowXferReason !==
                          '' &&
                        policyHighlights[0]?.accountConfig?.allowXferReason?.indexOf(
                          'TR13',
                        ) !== -1 && (
                          <Components.AlertMessage
                            showCloseButton={true}
                            style={style.marginBottom16}
                            alertLevel={AlertLevel.ALERT}
                            fontSize={18}
                            title="This policy is owned by multiple owners.">
                            <Components.Text
                              style={style.alertBannerContentMargin}>
                              Policy changes require special authorization by
                              all owners before processing can occur.
                            </Components.Text>
                            <Components.Text>
                              {`If you have any questions, please reference the `}
                              <Components.Link
                                onPress={() => {
                                  trackCustomEvent(
                                    analyticsTags.CaptureContactUsInvestmentMultipleOwnersTags,
                                  );
                                  navigateToContactUs();
                                }}
                                accessibilityLabel="Contact Us"
                                accessibilityRole="button"
                                {...linkStyle}>
                                Contact Us
                              </Components.Link>
                              {` page.`}
                            </Components.Text>
                          </Components.AlertMessage>
                        )}
                      {policyHighlights[0]?.accountConfig?.allowXferReason !==
                        null &&
                        policyHighlights[0]?.accountConfig?.allowXferReason !==
                          '' &&
                        policyHighlights[0]?.accountConfig?.allowXferReason?.indexOf(
                          'TR14',
                        ) !== -1 && (
                          <Components.AlertMessage
                            showCloseButton={true}
                            style={style.marginBottom16}
                            alertLevel={AlertLevel.ALERT}
                            fontSize={18}
                            title="Your policy has been set up to process a scheduled financial event.">
                            <Components.Text
                              style={style.alertBannerContentMargin}>
                              Any changes could conflict with this pending
                              event.
                            </Components.Text>
                            <Components.Text>
                              {`If you have any questions, please reference the `}
                              <Components.Link
                                onPress={() => {
                                  trackCustomEvent(
                                    analyticsTags.CaptureContactUsInvestmentScheduledFinancialEventTags,
                                  );
                                  navigateToContactUs();
                                }}
                                accessibilityLabel="Contact Us"
                                accessibilityRole="button"
                                {...linkStyle}>
                                Contact Us
                              </Components.Link>
                              {` page.`}
                            </Components.Text>
                          </Components.AlertMessage>
                        )}
                      {policyHighlights[0]?.accountConfig?.allowXferReason !==
                        null &&
                        policyHighlights[0]?.accountConfig?.allowXferReason !==
                          '' &&
                        policyHighlights[0]?.accountConfig?.allowXferReason?.indexOf(
                          'TR15',
                        ) !== -1 && (
                          <Components.AlertMessage
                            showCloseButton={true}
                            style={style.marginBottom16}
                            alertLevel={AlertLevel.ALERT}
                            fontSize={18}
                            title={`Transfers are not allowed during the "Free Look" period.`}>
                            <Components.Text
                              style={style.alertBannerContentMargin}>
                              {`If you have any questions, please reference the `}
                              <Components.Link
                                onPress={() => {
                                  trackCustomEvent(
                                    analyticsTags.CaptureContactUsInvestmentFreeLookTags,
                                  );
                                  navigateToContactUs();
                                }}
                                accessibilityLabel="Contact Us"
                                accessibilityRole="button"
                                {...linkStyle}>
                                Contact Us
                              </Components.Link>
                              {` page.`}
                            </Components.Text>
                          </Components.AlertMessage>
                        )}
                      <View className="flex-col md:flex-row">
                        <Components.Text style={[style.investmentCardDate]}>
                          As of {asOfDate}
                        </Components.Text>
                        {policyHighlights[0]?.accountConfig?.allowTransfer && (
                          <View className="flex-row">
                            <Pressable
                              disabled={
                                !policyHighlights[0]?.accountConfig
                                  ?.allowXferStatus
                              }
                              onPress={() => {
                                trackCustomEvent(
                                  analyticsTags.CaptureTransferBalancePageLinkTags,
                                );
                                navigateToTransferBalance();
                              }}
                              accessibilityRole="button"
                              accessibilityLabel="Edit transfer balance">
                              <Components.Text
                                style={[
                                  style.transferBal,
                                  {
                                    color: getEditTransferBalanceTextColor(),
                                  },
                                ]}>
                                Transfer Balance
                                <FontAwesomeIcon
                                  style={
                                    style.outlineNone as FontAwesomeIconStyle
                                  }
                                  icon={faEdit as IconProp}
                                  size={16}
                                  color={getEditTransferBalanceTextColor()}
                                />
                              </Components.Text>
                            </Pressable>
                          </View>
                        )}
                      </View>
                      <View className="mt-4" style={style.horizontalDivider} />
                      <InvestmentChart
                        data={mappedData}
                        accountBalance={policyHighlights[0]?.accountBalance}
                        showChildRows={true}
                        pieChartHeight={pieChartSize.height}
                        pieChartWidth={pieChartSize.width}
                        columnWidthArr={
                          isLargeScreen ? columnWidthArr : columnWidthArrMob
                        }
                        columnConfig={columnConfiguration}
                        childRowsColumnConfig={childRowsColumnConfig}
                        assetTotal={assetTotal}
                        horizontal={isMobile}
                        legendTableTitle={{
                          text: 'View more information about the investment options by expanding the asset class and selecting the investment name.',
                          titleStyle: style.headingTable,
                        }}
                        showPieChart={true}
                        showLegendTable={true}
                        showBorder={true}
                      />
                    </View>
                  </View>,
                  <View
                    style={[style.contentPadding, style.tabTitleSize]}
                    key="tab2">
                    <View style={style.componentPadding}>
                      {isHomeOfcUser && (
                        <View>
                          <Components.AlertMessage
                            className="mb-4 py-4 flex-1"
                            alertLevel={AlertLevel.INFORMATION}
                            style={style.marginBottom16}
                            testID="alertComponent"
                            fontSize={16}
                            showCloseButton={false}
                            iconSize={isLargeScreen ? 52.36 : 24}
                            title="Submitting investment updates on behalf of the user is not allowed due to home office access."
                          />
                        </View>
                      )}
                      {isTransactionConfirmed && (
                        <View>
                          <Components.AlertMessage
                            className="my-5 mt-4 mb-4 flex-1"
                            alertLevel={AlertLevel.ALERT}
                            testID="alertComponent"
                            showCloseButton
                            fontSize={18}
                            iconSize={isLargeScreen ? 52.36 : 24}
                            title="You have a pending transaction in process">
                            <View
                              style={[
                                style.row,
                                !isLargeScreen && style.warningMessageParent,
                              ]}>
                              <Components.Text style={[style.warningText]}>
                                You can not make any changes until it has been
                                processed.
                                {BreakpointConstants.isLG(width) && '\n'}
                                If you have any questions or would like to make
                                a correction, please reference the&nbsp;
                                <Pressable
                                  onPress={() => {
                                    trackCustomEvent(
                                      analyticsTags.CaptureContactUsInvestmentPendingTransactionTags,
                                    );
                                    navigateToContactUs();
                                  }}
                                  accessibilityRole="button"
                                  accessibilityLabel="Contact Us">
                                  <Components.Text
                                    style={[
                                      style.contactUsLink,
                                      style.warningPDFLink,
                                      isLargeScreen && {
                                        marginTop: 8,
                                      },
                                    ]}>
                                    Contact Us
                                  </Components.Text>
                                </Pressable>
                                &nbsp;page.
                              </Components.Text>
                            </View>
                          </Components.AlertMessage>
                        </View>
                      )}
                      <View className="flex-col md:flex-row">
                        <Components.Text style={[style.investmentCardDate]}>
                          As of {asOfDate}
                        </Components.Text>
                        {policyHighlights[0]?.accountConfig
                          ?.allowElectionChange && (
                          <Pressable
                            onPress={() => {
                              trackCustomEvent(
                                analyticsTags.CaptureEditFutureElectionsButtonTags,
                              );
                              navigateToMyInvestmetElections();
                            }}
                            accessibilityRole="button"
                            accessibilityLabel="Edit future elections">
                            <Components.Text style={style.editFutureElections}>
                              Edit Future Elections{'  '}
                              <FontAwesomeIcon
                                style={
                                  style.outlineNone as FontAwesomeIconStyle
                                }
                                icon={faEdit as IconProp}
                                size={16}
                                color={ColorConstants.oaBlueTint}
                              />
                            </Components.Text>
                          </Pressable>
                        )}
                      </View>
                      <View className="mt-4" style={style.horizontalDivider} />
                      <InvestmentChart
                        data={mappedData}
                        accountBalance={policyHighlights[0]?.accountBalance}
                        showChildRows={true}
                        pieChartHeight={pieChartSize.height}
                        pieChartWidth={pieChartSize.width}
                        columnConfig={columnConfigurationForIOE}
                        columnWidthArr={
                          isLargeScreen ? widthArrIOE : widthArrMobIOE
                        }
                        childRowsColumnConfig={childRowsColumnConfigForIOE}
                        assetTotal={totalpercentByVaule}
                        legendTableTitle={{
                          text: 'View more information about the investment options by expanding the asset class and selecting the investment name.',
                          titleStyle: style.headingTable,
                        }}
                        showPieChart={true}
                        showLegendTable={true}
                        showBorder={true}
                      />
                    </View>
                  </View>,
                ]}
                tabs={[
                  {
                    key: 'tab1',
                    testID: 'currentbalance-tab',
                    title: 'Current Balance by Asset Class',
                  },
                  {
                    key: 'tab2',
                    testID: 'futureinvestment-tab',
                    title: 'Future Investment Option Elections',
                  },
                ]}
                selectedIndex={selectedTab}
                onPress={(index) => {
                  if (index === 1) {
                    navigation.navigate(RouteConstants.myinvestments, {
                      tab: 'Future-Elections',
                    });
                    trackCustomEvent(
                      analyticsTags.CaptureFutureInvestmentOptionElectionsTabTags,
                    );
                  } else {
                    trackCustomEvent(
                      analyticsTags.CaptureCurrentBalanceAssetClassTags,
                    );
                    navigation.navigate(RouteConstants.myinvestments, {
                      tab: 'Current-Balance',
                    });
                  }
                }}
              />
            </View>
          </View>
        </View>,
      );
    }
    return elements;
  }, [policyHighlights, screenType, selectedTab, isTransactionConfirmed]);

  return (
    <ScreenWrapper scrollFooter paddingTop paddingBottom paddingHorizontal>
      <Breadcrumbs />
      <PolicyDropdown
        className=""
        accessToken={props.accessToken}
        text={'My Investments'}
      />
      <ErrorWrapper>
        <View style={style.cardHeader}>
          <Components.Text.H2 style={[style.contentTitle, style.flex]}>
            Investment Option Performance
          </Components.Text.H2>
          <Components.Text style={[style.firstParagraph]}>
            Updated monthly, the attached performance sheet gives you timely
            information about the investment choices in your account. The
            performance sheet is available as an Adobe Acrobat® PDF.
          </Components.Text>
          <Pressable
            onPress={() => {
              trackCustomEvent(analyticsTags.CaptureVULPDFHyperlinkTags);
              openURL(policyHighlights[0].urlFundPerformance);
            }}
            testID="pdf-link"
            accessibilityRole="button"
            accessibilityLabel="VUL American Accumulator GLP Test, PDF link">
            <Components.Text style={[style.pdfLink]}>
              {account.name} (pdf)
            </Components.Text>
          </Pressable>
          <View className="w-full flex flex-row mt-4">
            <Components.Text style={[style.secondParagraph]}>
              Go to{' '}
              <Components.Text style={[style.myReportsLink]}>
                <Pressable
                  onPress={() => {
                    navigateToMyReports();
                  }}
                  accessibilityLabel="My Reports">
                  My Reports{' '}
                </Pressable>
              </Components.Text>
              to view and print details of unit values for specific timeframes.
            </Components.Text>
          </View>
        </View>
        {renderCards}
      </ErrorWrapper>
    </ScreenWrapper>
  );
};

export default withAuth(MyInvestments);
