import React, {ReactElement} from 'react';
import {Platform, ViewStyle, View} from 'react-native';

interface TextInputLabelProps {
  children: (ReactElement | undefined | boolean)[];
  style: ViewStyle;
}

export const TextInputLabel = ({children, style}: TextInputLabelProps) => {
  return Platform.OS === 'web' ? (
    <label>
      <View style={style}>{children}</View>
    </label>
  ) : (
    <View style={style}>{children}</View>
  );
};
