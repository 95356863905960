import React, {useMemo, useState, useEffect, useRef} from 'react';
import {Components} from '@oneamerica/dxp-ui-components';
import {
  NativeEventEmitter,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import authClient from './config';
import {APP_LOGOUT_URL} from '../config/getEnvironmentVars';

const useSessionInactivity = (
  authLogout: CallableFunction,
  INTERVAL_IN_MINUTES = 0,
) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [remainingSeconds, setRemainingSeconds] = useState(120);
  const INTERVAL_IN_MILLISECONDS = INTERVAL_IN_MINUTES * 60 * 1000;
  const lastLoginRef = useRef<Date | null>(null);
  const eventEmitter = new NativeEventEmitter();

  const getRemainingTime = () => {
    let minutes = Math.floor(remainingSeconds / 60).toString();
    let seconds = (remainingSeconds - Number(minutes) * 60).toString();

    if (Number(minutes) <= 9) {
      minutes = '0' + minutes;
    } else if (Number(minutes) === 0) {
      minutes = '00';
    }

    if (Number(seconds) <= 9) {
      seconds = '0' + seconds;
    } else if (Number(seconds) === 0) {
      seconds = '00';
    }
    return `${minutes}:${seconds}`;
  };

  const checkDiff = (reset = true) => {
    if (lastLoginRef.current) {
      const diff = +new Date() - +lastLoginRef.current; //comparing current time and lastActiveLogin time(saved when app going to background)

      if (diff >= INTERVAL_IN_MILLISECONDS) {
        //difference is more than or equal to given time
        !isModalVisible && setIsModalVisible(true);
        lastLoginRef.current = new Date();
      } else {
        if (reset) {
          lastLoginRef.current = new Date();
        }
      }
    }
  };

  const scrollEventListener = (event) => {
    checkDiff();
  };

  const mouseEventListener = (e) => {
    if (e instanceof MouseEvent) {
      checkDiff();
    }
  };

  const keyboardEventListener = (e) => {
    if (e instanceof KeyboardEvent) {
      checkDiff();
    }
  };

  const refreshSession = () => {
    authClient.session.refresh().catch((err) => console.warn(err));
  };

  useEffect(() => {
    const unsubscribe = eventEmitter.addListener('scroll', scrollEventListener);
    if (Platform.OS === 'web') {
      document.addEventListener('mousedown', mouseEventListener);
      document.addEventListener('keydown', keyboardEventListener);
    }
    return () => {
      if (Platform.OS === 'web') {
        document.removeEventListener('mousedown', mouseEventListener);
        document.removeEventListener('keydown', keyboardEventListener);
      }
      unsubscribe.remove();
    };
  }, []);

  useEffect(() => {
    lastLoginRef.current = new Date();
    const interval = setInterval(() => {
      checkDiff(false);
    }, INTERVAL_IN_MILLISECONDS / INTERVAL_IN_MINUTES);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let timeout;
    if (isModalVisible) {
      timeout = setTimeout(() => {
        if (remainingSeconds >= 1) {
          setRemainingSeconds((prevState) => prevState - 1);
        } else {
          authLogout?.(`${APP_LOGOUT_URL}?sessionExpired=expired`);
          clearTimeout(timeout);
        }
      }, 1000);
    } else {
      clearTimeout(timeout);
      setTimeout(() => {
        setRemainingSeconds(120);
      }, 300);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [remainingSeconds, isModalVisible]);

  const TimeoutModal = useMemo(
    () => (
      <Components.DialogModal
        width={'auto'}
        padding={20}
        visible={isModalVisible}
        Header={
          <Components.Text style={styles.headerFontSize}>
            {'Session Timeout Warning'}
          </Components.Text>
        }
        Footer={
          <View className="flex flex-row items-center flex-wrap md:mt-[13px]">
            <Components.Button
              outline={true}
              className="mx-2"
              accessibilityRole="button"
              id="btnOutline"
              theme="primary"
              onPress={() => {
                refreshSession();
                setIsModalVisible(false);
              }}>
              Stay Logged In
            </Components.Button>
            <Components.Button
              className="mx-2"
              accessibilityRole="button"
              id="btnFilled"
              theme="primary"
              onPress={() => {
                authLogout?.();
              }}>
              Log Out Now
            </Components.Button>
          </View>
        }
        onClose={() => {
          setIsModalVisible(false);
        }}>
        <View>
          <Components.Text className="mt-4" style={styles.bodyFontSize}>
            {`For your security, your session will expire\nautomatically due to inactivity.`}
          </Components.Text>
          <Components.Text className="mt-4" style={styles.bodyFontSize}>
            You will be logged out in:{' '}
            <Text style={{fontWeight: 'bold'}}>{getRemainingTime()}</Text>
          </Components.Text>
        </View>
      </Components.DialogModal>
    ),
    [remainingSeconds, isModalVisible],
  );

  return {TimeoutModal};
};

const styles = StyleSheet.create({
  headerFontSize: {fontSize: 20},
  bodyFontSize: {fontSize: 15},
});

export default useSessionInactivity;
