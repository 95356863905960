import {Platform} from 'react-native';
import {Color, Metrics, Theme} from '@oneamerica/dxp-ui-components';

const styles = (isError = false, background = 'white', optionsLength = 1) =>
  Theme.Responsive.create({
    controls: {
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
    },
    error: {
      color: Color.error.red,
    },
    pressableContainer: {
      width: '100%',
    },
    itemContainer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      top: 50,
      height: '100%',
      zIndex: 2000,
      backgroundColor: Color.white,
      overflow: optionsLength > 5 ? 'scroll' : 'visible',
      ...Color.shadow,
    },
    selectItem: {
      width: '100%',
      height: 40,
      alignContent: 'center',
      justifyContent: 'center',
      backgroundColor: background || 'white',
    },
    nativePicker: {
      paddingVertical: Platform.OS === 'ios' ? Metrics.small : 0,
      backgroundColor: 'white',
      paddingLeft: Metrics.small,
      borderRadius: Metrics.borderRadius,
      borderColor: !isError ? Color.gray3 : Color.error.red,
      borderWidth: 1,
    },
    webInputIconPosition: {
      position: 'absolute',
      right: 15,
      top: 15,
    },
  })();

export default styles;
