import React from 'react';
import {View} from 'react-native';
import {Components, Font} from '@oneamerica/dxp-ui-components';
import InfoWrapper from '../../../../components/InfoWrapper/InfoWrapper';
import responsiveStyle from '../../style';
import {SuspendedButtonView} from '../SuspendedButtonView';

export const ContractStatus = ({
  buttonStatus,
  getTerminatedReason,
  terminatedReason,
  policyStatusButton,
  policyTerminationDate,
  popupData,
  navigateToContactUs,
  policyHighlights,
  linkStyleContactUs,
}) => {
  const style = responsiveStyle();
  const insuredPersonData = popupData?.filter((item) =>
    ['Primary Insured', 'Annuitant'].includes(item.relationType),
  );
  const insuredPerson = insuredPersonData?.[0]?.personDetails?.name?.first;
  const insuredLabel =
    insuredPersonData?.[0]?.relationType === 'Primary Insured'
      ? 'Insured'
      : insuredPersonData?.[0]?.relationType;
  return (
    <View className="flex-row flex-wrap">
      <View
        className={
          buttonStatus !== 'Terminated'
            ? `pt-2 w-full md:w-1/3`
            : `pt-2 w-1/2 md:w-1/3 `
        }>
        <Components.Text className="px-2" style={style.statusButton}>
          Contract Status
        </Components.Text>
        <View
          className="px-2"
          style={{
            alignItems: 'flex-start',
            maxWidth: 150,
            paddingTop: 4,
          }}>
          <Components.Text
            style={{
              paddingHorizontal: 8,
              textAlign: 'center',
              paddingVertical: 4,
              borderRadius: 4,
              borderWidth: 1,
              borderStyle: 'solid',
              fontSize: 14,
              borderColor: policyStatusButton?.borderColor,
              backgroundColor: policyStatusButton?.backgroundColor,
            }}>
            {buttonStatus}
          </Components.Text>
        </View>
        {['Terminated', 'Back Billed'].includes(buttonStatus) ? (
          <InfoWrapper
            subTitle={['Terminated', 'Back Billed'].includes(buttonStatus)}
            information={
              buttonStatus === 'Terminated'
                ? getTerminatedReason(terminatedReason)
                : 'Premium Required'
            }
            infoTextStyle={{
              fontFamily: Font.regular.fontFamily,
              fontSize: 14,
            }}
          />
        ) : null}
      </View>
      {buttonStatus === 'Terminated' ? (
        <>
          <View className="w-1/2 md:w-1/3">
            <InfoWrapper
              title={'Termination Date'}
              subTitle
              information={policyTerminationDate}
            />
          </View>
          <View className="w-1/2 md:w-1/3">
            <InfoWrapper
              title={insuredLabel}
              subTitle
              information={insuredPerson}
            />
          </View>
        </>
      ) : (
        <SuspendedButtonView
          buttonStatus={buttonStatus}
          navigateToContactUs={navigateToContactUs}
          policyHighlights={policyHighlights}
          linkStyleContactUs={linkStyleContactUs}
        />
      )}
    </View>
  );
};
