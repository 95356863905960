import React from 'react';
import {View} from 'react-native';
import {Components} from '@oneamerica/dxp-ui-components';
import responsiveStyle from './style';
import InfoWrapper from '../../../../components/InfoWrapper/InfoWrapper';

const ViewIndexedDividend = ({viewIndexedDividendData}) => {
  const style = responsiveStyle();
  return (
    <View className="flex-col lg:flex-row pt-6">
      <View className="w-full p-0 ">
        <View style={style.cardShadow}>
          <View style={style.cardHeader}>
            <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
              Indexed Dividend Information
            </Components.Text.H3>
          </View>
          <View className="mt-4" style={style.horizontalDivider} />
          <View style={style.detailContainer} className="flex-wrap">
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}>
              {viewIndexedDividendData?.map((data, index) => {
                if (data?.value === 'N/A') {
                  return null;
                }
                return (
                  <View
                    key={`${data.title}-${index}`}
                    className="sm:w-56 md:w-1/3 basis-1/2 sm:basis-1/4"
                    style={[style.flex, style.infoWrapperInformation]}>
                    <InfoWrapper
                      title={data?.title}
                      titleTextStyle={{
                        fontSize: 14,
                      }}
                      subTitle
                      information={data.value || '-'}
                      infoTextStyle={{fontSize: 16, fontWeight: '500'}}
                    />
                  </View>
                );
              })}
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default ViewIndexedDividend;
