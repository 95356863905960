import React, {useEffect, useCallback, useContext} from 'react';
import {Platform} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useDispatch, useSelector} from 'react-redux';
import {createDrawerNavigator} from '@react-navigation/drawer';
import {Theme} from '@oneamerica/dxp-ui-components';
import responsiveStyles from './styles';
import OAHeader from '../../components/Header';
import DrawerContent from '../../components/DrawerContent';
import StackNavigation from '../Stack';
import {initializeQualtrics} from '../../utils/QualtricsUtils';
import {getPoliciesList} from '../../redux/policies/action';
import {QUALTRICS_ID, QUALTRICS_SCRIPT} from '../../config/getEnvironmentVars';

const DrawerNavigator = ({
  currentRoute,
  authLogout,
  initialState,
  accessToken,
}) => {
  const dispatch = useDispatch();
  const {top} = useSafeAreaInsets();
  const Drawer = createDrawerNavigator();
  const {width} = useContext(Theme.ResponsiveContext);
  const styles = responsiveStyles(top, width);

  const policyData = useSelector((state: any) => state.policies);
  const {getPoliciesListData = {}} = policyData || {};
  const policyList = useSelector((state: any) => state.policyLists);
  const {selectedPolicy} = policyList;
  const profileData = useSelector(
    (state: any) => state.profileData?.userDetails,
  );

  useEffect(() => {
    if (Object.keys(getPoliciesListData).length === 0 && selectedPolicy) {
      dispatch(getPoliciesList(selectedPolicy?.policyPlanNumber, accessToken));
    }
  }, [policyList]);
  useEffect(() => {
    const userData = {
      GivenName: profileData?.given_name,
      FamilyName: profileData?.family_name,
      email: profileData?.email,
      policyNumber: selectedPolicy?.policyPlanNumber,
    };
    if (QUALTRICS_SCRIPT && QUALTRICS_ID && selectedPolicy?.policyPlanNumber) {
      initializeQualtrics(QUALTRICS_SCRIPT, QUALTRICS_ID, userData);
    }
  }, [QUALTRICS_SCRIPT, QUALTRICS_ID, policyList]);
  const header = () => (
    <OAHeader
      currentRoute={currentRoute}
      isBackEnabled={false}
      collapse={false}
      headerVisible={true}
      menu={true}
      authLogout={authLogout}
    />
  );
  const Stack = useCallback(
    () => <StackNavigation initialState={initialState} />,
    [initialState],
  );

  const drawerContent = () => (
    <DrawerContent width={width} authLogout={authLogout} />
  );

  return (
    <Drawer.Navigator
      drawerContent={drawerContent}
      screenOptions={{
        header,
        drawerPosition: 'left',
        drawerType: 'front',
        swipeEnabled: false,
        lazy: true,
        overlayColor: Theme.Color.drawer.overlay,
        drawerStyle: [
          styles.drawerStyle,
          Platform.OS === 'web' && styles.drawerWeb,
        ],
      }}>
      <Drawer.Screen name="/" component={Stack} />
    </Drawer.Navigator>
  );
};
export default DrawerNavigator;
