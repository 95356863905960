import React, {useContext} from 'react';
import {View} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {Components, Theme, Font} from '@oneamerica/dxp-ui-components';
import InfoWrapper from '../../../../components/InfoWrapper/InfoWrapper';
import responsiveStyle from '../../style';
import BreakpointConstants from '../../../../constants/BreakpointConstants';
import RouteConstants from '../../../../constants/RouteConstants';
import {analyticsTags} from '../../../../utils/Analytics/AnalyticsTags';
import {trackCustomEvent} from '../../../../utils/Analytics/AdobeAnalyticsUtils';

export const COBDetails = ({
  showCOBInPolicyHighlights,
  validateElement,
  cobPolicyStatus,
  cobStatusButton,
  cobPolicyStatusReason,
  cobPolicyNumber,
  cobTerminationDate,
  linkStyle,
  navigateToCOBDetails,
}) => {
  const style = responsiveStyle();
  const {width} = useContext(Theme.ResponsiveContext);
  const navigation = useNavigation<any>();
  const navigateToContactUs = () => {
    navigation.navigate(RouteConstants.contactus);
  };
  const linkStyleContactUs = {
    style: style.linkContactUs,
    linkStyle: style.linkContactUs,
    linkHoverStyle: style.linkContactUs,
    hoverStyle: style.linkContactUsHover,
    focusStyle: style.linkContactUsFocus,
  };
  return (
    <>
      {showCOBInPolicyHighlights ? (
        <View>
          <View className="my-2" style={style.horizontalDivider} />
          <View style={style.cardHeader}>
            <Components.Text.H3
              className="px-2"
              style={[style.cardHeaderTitle, style.flex]}>
              Continuation of Benefits
            </Components.Text.H3>
          </View>
          <View className="flex-row flex-wrap">
            {cobPolicyStatus &&
              validateElement(
                cobPolicyStatus,
                <InfoWrapper
                  width="w-1/2 md:w-1/3"
                  title={'Contract Status'}
                  subTitle
                  information=""
                  statusbuttonText={cobPolicyStatus}
                  statusButtonBorder={cobStatusButton?.borderColor}
                  statusButtonBackground={cobStatusButton?.backgroundColor}
                  statusReason={cobPolicyStatusReason}
                />,
              )}
            {validateElement(
              cobPolicyNumber,
              <InfoWrapper
                width="w-1/2 md:w-1/3"
                title={'Policy Number'}
                subTitle
                information={cobPolicyNumber}
              />,
              BreakpointConstants.isMD(width) ? undefined : null,
            )}
            {!cobPolicyStatus ? (
              <View className="w-full md:w-2/3">
                <Components.Text
                  className="py-2 px-2"
                  style={style.terminatedContent}>
                  Please be aware that Continuation of Benefit (COB) for this
                  policy is not available for display. For additional
                  information, please reference the{' '}
                  <Components.Link
                    onPress={() => {
                      trackCustomEvent(analyticsTags.CaptureContactUsCOBTags);
                      navigateToContactUs();
                    }}
                    accessibilityLabel="Contact Us"
                    accessibilityRole="button"
                    {...linkStyleContactUs}>
                    Contact Us
                  </Components.Link>{' '}
                  page.
                </Components.Text>
              </View>
            ) : null}
            {cobPolicyStatus === 'Terminated' &&
              validateElement(
                cobTerminationDate,
                <InfoWrapper
                  width="w-1/2 md:w-1/3"
                  title={'Termination Date'}
                  subTitle
                  information={cobTerminationDate}
                />,
                BreakpointConstants.isMD(width) ? undefined : null,
              )}
          </View>
          {cobPolicyStatus && (
            <View className="flex-row flex-wrap">
              {cobPolicyStatus === 'Terminated' ? (
                <Components.Text
                  className="py-2 px-2"
                  style={style.terminatedContent}>
                  The status of this policy is either terminated or the premium
                  payment have discontinued. For additional information, please
                  reference the{' '}
                  <Components.Link
                    onPress={() => {
                      trackCustomEvent(
                        analyticsTags.CaptureContactUsTerminatedCOBTags,
                      );
                      navigateToContactUs();
                    }}
                    accessibilityLabel="Contact Us"
                    accessibilityRole="button"
                    {...linkStyleContactUs}>
                    Contact Us
                  </Components.Link>{' '}
                  page.
                </Components.Text>
              ) : null}
              {cobPolicyStatus === 'Suspended' ? (
                <Components.Text
                  className="py-2 px-2"
                  style={style.terminatedContent}>
                  Account Information is not currently available for this
                  policy.  For additional information, please reference the{' '}
                  <Components.Link
                    onPress={() => {
                      trackCustomEvent(
                        analyticsTags.CaptureContactUsSuspendedCOBTags,
                      );
                      navigateToContactUs();
                    }}
                    accessibilityLabel="Contact Us"
                    accessibilityRole="button"
                    {...linkStyleContactUs}>
                    Contact Us
                  </Components.Link>{' '}
                  page.
                </Components.Text>
              ) : (
                <InfoWrapper
                  subTitle
                  title=""
                  subTitleText=""
                  information="The Continuation of Benefits rider/option provides extended benefits after your base policy has been depleted through long-term care withdrawals."
                  infoTextStyle={{fontFamily: Font.regular.fontFamily}}
                />
              )}
            </View>
          )}
          {cobPolicyStatus &&
            cobPolicyStatus !== 'Terminated' &&
            cobPolicyStatus !== 'Suspended' && (
              <View className="px-2 pt-2">
                <Components.Link
                  accessibilityLabel="View Continuation of Benefits Details"
                  accessibilityRole="button"
                  onPress={() => {
                    trackCustomEvent(
                      analyticsTags.CaptureCOBCardViewDetailsTags,
                    );
                    navigateToCOBDetails('COB');
                  }}
                  {...linkStyle}>
                  View Details
                </Components.Link>
              </View>
            )}
        </View>
      ) : null}
    </>
  );
};
