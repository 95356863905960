import React from 'react';
import {Platform, TextInput as NativeInput} from 'react-native';
import {TextInput as NativeInputWeb} from 'react-native-web';
import {Color} from '@oneamerica/dxp-ui-components';

export const PlatformInput = ({
  isFocused,
  small,
  secureTextEntry,
  Input,
  scrubbedProps,
  autoComplete,
  inputRef,
  label,
  focusable,
  editable,
  styles,
  innerStyle,
  handleFocus,
  handleBlur,
  handleChange,
  isSecure,
  testID,
  selectedValue,
  id,
  contextMenuHidden,
  keyboardType,
  placeholder,
}) => {
  const placeholderTextColor =
    isFocused || label === '' ? Color.gray3 : 'transparent';
  const clearButtonMode = small || secureTextEntry ? 'never' : 'while-editing';
  return (
    <>
      {!Input &&
        (Platform.OS === 'web' ? (
          <NativeInputWeb
            {...scrubbedProps}
            autoComplete={autoComplete}
            ref={inputRef}
            accessibilityLabel={label}
            focusable={focusable && editable}
            style={[
              styles.input,
              {outlineStyle: 'none'},
              label === '' && styles.centeredInput,
              !editable && styles.disabled,
              innerStyle,
            ]}
            onFocus={handleFocus}
            onBlur={(e) => handleBlur(e)}
            onChangeText={(text) => handleChange(text)}
            secureTextEntry={isSecure}
            clearButtonMode={clearButtonMode}
            testID={testID}
            defaultValue={selectedValue}
            editable={editable}
            id={id}
            nativeID={id}
            keyboardType={keyboardType}
            placeholder={placeholder}
            placeholderTextColor={placeholderTextColor}
            contextMenuHidden={contextMenuHidden}
            onContextMenu={(e: any) => {
              if (contextMenuHidden) {
                e.preventDefault();
                return false;
              }
            }}
            onKeyPress={(e: any) => {
              if (contextMenuHidden) {
                //Windows
                if (e.nativeEvent.key === 'v' && e.ctrlKey) {
                  e.preventDefault();
                }
                //iOS
                if (['meta', 'v'].includes(e.nativeEvent.key)) {
                  e.preventDefault();
                }
              }
            }}
          />
        ) : (
          <NativeInput
            {...scrubbedProps}
            autoComplete={autoComplete}
            ref={inputRef}
            accessibilityLabel={label}
            focusable={focusable && editable}
            style={[
              styles.input,
              label === '' && styles.centeredInput,
              !editable && styles.disabled,
              innerStyle,
            ]}
            onFocus={handleFocus}
            onBlur={(e) => handleBlur(e)}
            onChangeText={(text) => handleChange(text)}
            secureTextEntry={isSecure}
            clearButtonMode={clearButtonMode}
            testID={testID}
            defaultValue={selectedValue}
            editable={editable}
            id={id}
            nativeID={id}
            keyboardType={keyboardType}
            placeholder={placeholder}
            placeholderTextColor={placeholderTextColor}
            contextMenuHidden={contextMenuHidden}
          />
        ))}
    </>
  );
};
