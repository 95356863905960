import React from 'react';
import {ScrollView, Text, TouchableHighlight, View} from 'react-native';
import {Table, TableWrapper, Cell} from 'react-native-reanimated-table';
import {Components} from '@oneamerica/dxp-ui-components';
import responsiveStyle from '../PremiumsReceived/style';
import PolicyRelationshipsInfoModal from '../../../Home/components/PolicyRelationshipsInfoModal';
import {usePolicyDetailsSelector} from '../../hooks/policyDetails';
import {
  getAddressField,
  getLimitWidthArray,
  setDOBMonth,
  truncatedTextWithLineBreaks,
} from '../../../Home/helpers';
import {trackCustomEvent} from '../../../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../../../utils/Analytics/AnalyticsTags';

const PolicyRelationships = ({...props}) => {
  const style = responsiveStyle();
  const {
    policyRelationshipData,
    infoModalContent,
    isInfoModalVisible,
    setIsInfoModalVisible,
    getModalDetails,
    maskId,
  } = usePolicyDetailsSelector(props);

  const newData: any[] = setDOBMonth(policyRelationshipData);

  const truncateCharLimit = 30;

  const widthArr: number[] = getLimitWidthArray(newData);

  const getFormattedDOB = (item) => {
    return truncatedTextWithLineBreaks(
      item.personDetails?.dob ?? '-',
      truncateCharLimit,
    );
  };
  const getFormattedSex = (item) => {
    return truncatedTextWithLineBreaks(
      item.personDetails?.sex ?? '-',
      truncateCharLimit,
    );
  };
  const parseItemId = (index, item) => {
    if (index === 0) {
      return item?.idType;
    }
    return item.id === null ? '-' : maskId(item?.id);
  };
  const getFormattedIdType = (index, item) => {
    return truncatedTextWithLineBreaks(
      parseItemId(index, item),
      truncateCharLimit,
    );
  };
  const getAddress = (item) => {
    return getAddressField(item, 'line1');
  };
  const getCity = (item) => {
    return truncatedTextWithLineBreaks(
      getAddressField(item, 'city'),
      truncateCharLimit,
    );
  };
  const getState = (item) => {
    return truncatedTextWithLineBreaks(
      getAddressField(item, 'state'),
      truncateCharLimit,
    );
  };
  const getZipcode = (item) => {
    return truncatedTextWithLineBreaks(
      getAddressField(item, 'zip'),
      truncateCharLimit,
    );
  };

  const createTableCell = (index, data, width) => {
    return (
      <Cell
        style={[
          index === 0 ? style.tableHeadingCell1 : style.tableCell1,
          {width: width},
        ]}
        data={data}
        textStyle={
          index === 0 ? style.tableHeadingCellText : style.tableCellText1
        }
      />
    );
  };

  const getRelationType = (relationType: string) => {
    if (relationType === 'First Beneficiary') {
      return 'Primary Beneficiary';
    }
    if (relationType === 'Second Beneficiary') {
      return 'Contingent Beneficiary';
    }
    return relationType;
  };

  return (
    <View className="flex-col lg:flex-row pt-6">
      <View className="w-full p-0">
        <View style={style.cardShadow}>
          <View style={style.cardHeader}>
            <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
              Policy Relationships
            </Components.Text.H3>
            <TouchableHighlight
              underlayColor="#fff"
              accessible={true}
              accessibilityLabel="Policy Relationships Information icon link"
              // style={style.Details}
              onPress={() => {
                trackCustomEvent(
                  analyticsTags.CapturePolicyRelationshipsInfoModalTags,
                );
                getModalDetails();
              }}>
              <Text style={style.Details}>i</Text>
            </TouchableHighlight>
          </View>
          <View className="mt-4" style={style.horizontalDivider} />
          <ScrollView style={style.contentWrapper1} horizontal={true}>
            <View className="mt-4">
              <Table>
                {newData?.map((item: any, index: number) => (
                  <TableWrapper
                    key={`row-${item.relationType}_${index}`}
                    style={{
                      ...style.row,
                      flexDirection: 'row',
                      backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F3F3F3',
                    }}>
                    <Cell
                      style={[
                        index === 0 ? style.tableHeadingCell : style.tableCell2,
                        {width: widthArr[0]},
                      ]}
                      data={truncatedTextWithLineBreaks(
                        getRelationType(item.relationType),
                        truncateCharLimit,
                      )}
                      textStyle={
                        index === 0
                          ? style.tableHeadingCellText
                          : style.tableCellText
                      }
                    />
                    {createTableCell(
                      index,
                      item.personDetails?.name?.first,
                      widthArr[1],
                    )}
                    {createTableCell(index, getFormattedDOB(item), widthArr[3])}
                    {createTableCell(index, getFormattedSex(item), widthArr[4])}
                    {createTableCell(
                      index,
                      getFormattedIdType(index, item),
                      widthArr[5],
                    )}
                    {createTableCell(index, getAddress(item), widthArr[6])}
                    {createTableCell(index, getCity(item), widthArr[7])}
                    {createTableCell(index, getState(item), widthArr[8])}
                    {createTableCell(index, getZipcode(item), widthArr[9])}
                  </TableWrapper>
                ))}
              </Table>
            </View>
          </ScrollView>
        </View>
      </View>

      <PolicyRelationshipsInfoModal
        isInfoModalVisible={isInfoModalVisible}
        setIsInfoModalVisible={setIsInfoModalVisible}
        infoModalContent={infoModalContent}
      />
    </View>
  );
};

export default PolicyRelationships;
