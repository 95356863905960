import React, {useCallback} from 'react';
import {View} from 'react-native';
import {Components, Font} from '@oneamerica/dxp-ui-components';
import responsiveStyle from '../../style';
import InfoWrapper from '../../../../components/InfoWrapper/InfoWrapper';
import {trackCustomEvent} from '../../../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../../../utils/Analytics/AnalyticsTags';
import {formatAddress, getRepresentative} from '../../helpers';

export const SuspendedButtonView = ({
  buttonStatus,
  navigateToContactUs,
  policyHighlights,
  linkStyleContactUs,
}) => {
  const style = responsiveStyle();
  const onContactUsLinkPress = useCallback(() => {
    trackCustomEvent(analyticsTags.CaptureContactUsPolicyHighlightsTags);
    navigateToContactUs();
  }, [trackCustomEvent, navigateToContactUs]);

  return (
    <>
      {buttonStatus === 'Suspended' ? (
        <View className="w-full md:w-2/3">
          <View style={{flexDirection: 'row', marginRight: 20}}>
            <Components.Text
              style={style.suspendedContent}
              className="py-2 px-2">
              Account information is not currently available for this policy.
              For additional information, please reference the{' '}
              <Components.Link
                onPress={onContactUsLinkPress}
                accessibilityLabel="Contact Us"
                accessibilityRole="button"
                {...linkStyleContactUs}>
                Contact Us
              </Components.Link>{' '}
              page.
            </Components.Text>
          </View>
        </View>
      ) : (
        <View className="w-full md:w-2/3">
          {getRepresentative(policyHighlights) && (
            <InfoWrapper
              subTitle
              title={'Your Representative is:'}
              subTitleText={
                getRepresentative(policyHighlights)?.personDetails?.name
                  ?.first ?? ''
              }
              information={formatAddress(policyHighlights)}
              infoTextStyle={{fontFamily: Font.regular.fontFamily}}
            />
          )}
        </View>
      )}
    </>
  );
};
