import React, {useEffect, useRef} from 'react';
import {Linking, Platform, View} from 'react-native';
import {useNavigationState} from '@react-navigation/native';
import {Components, Font} from '@oneamerica/dxp-ui-components';
import responsiveStyle from '../Home/style';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper';
import withAuth from '../../auth/withAuth';
import {useContactUsSelector} from './hook/contactUs';
import ColorConstants from '../../constants/ColorConstants';
import PolicyDropdown from '../../components/PolicyDropdown';
import Breadcrumbs from '../../components/Breadcrumbs';
import {
  trackCustomEvent,
  trackPage,
} from '../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';

interface ContactUsData {
  item: {
    contactusText1: {plaintext: string};
    contactusText2: {plaintext: string};
    americanUnitedLifeInsuranceCompanycontactAddress: CompanyContactDetails;
    stateLifeInsuranceCompanyContactAddress: CompanyContactDetails;
    stateLifeInsuranceCompanyContactForAssetBasedLtcProductsAndGoldenRuleContactAddress: CompanyContactDetails;
    mailingAddressText: string;
    mailingAddress: {plaintext: string};
    emailPolicyServiceText: string;
    policyServiceEmailID: string;
    policyChange: string;
    fax: string;
    faxText: string;
    policyChangeFaxNumber: string;
    emailPolicyChangeText: string;
    policyChangeEmailId: string;
  };
}
interface CompanyContactDetails {
  companyName: {plaintext: string};
  workingHours: string;
  tollfreeText: string;
  tollfreeNumber: string;
  phoneNumberText: string;
  phoneNumber: string;
  faxText: string;
  faxNumber: string;
}
const ContactUs = (props) => {
  const style = responsiveStyle();
  const navigationState = useNavigationState((state) => state);
  const previousRouteNameRef = useRef<string | null>(null);
  useEffect(() => {
    const currentRouteName =
      navigationState.routes[navigationState.index]?.name;
    if (
      previousRouteNameRef.current !== currentRouteName &&
      currentRouteName === 'Contact-Us'
    ) {
      trackPage(analyticsTags.createContactusPageViewTags);
    }
    previousRouteNameRef.current = currentRouteName;
  }, [navigationState]);
  const {contactUsData, isLoading} = useContactUsSelector() as {
    contactUsData: ContactUsData;
    isLoading: boolean;
    isError: boolean;
  };
  if (isLoading) {
    return <Components.LoadingIndicator loading={isLoading} />;
  }
  const renderContactInformation = (contactInfo) => {
    return (
      <View>
        <Components.Text.H6
          style={[
            style.contactUsContentText,
            {
              fontSize: 16,
              fontFamily: Font.medium.fontFamily,
              marginBottom: 4,
            },
          ]}>
          {contactInfo.companyName.plaintext}
        </Components.Text.H6>
        <Components.Text.H6 style={style.contactUsContentText}>
          {contactInfo.workingHours}
        </Components.Text.H6>
        <View style={{flexDirection: 'row'}}>
          <Components.Text.H6 style={style.contactUsContentText}>
            {`${contactInfo.tollfreeText}`}
          </Components.Text.H6>
          <Components.Link
            onPress={() => {
              if (
                contactInfo?.companyName?.plaintext?.includes('United Life')
              ) {
                trackCustomEvent(
                  analyticsTags.CaptureContactUsAULTollFreeNumberTags,
                );
              } else if (
                contactInfo?.companyName?.plaintext?.includes(
                  'contact for life insurance',
                )
              ) {
                trackCustomEvent(
                  analyticsTags.CaptureContactUsStateLifeInsuranceTollFreeNumberTags,
                );
              } else {
                trackCustomEvent(
                  analyticsTags.CaptureContactUsStateLifeInsuranceLTCTollFreeNumberTags,
                );
              }
              Linking.openURL('tel:${contactInfo.tollfreeNumber)');
            }}
            style={[
              style.contactUsContentTextBold,
              {
                fontSize: 14,
                color: ColorConstants.oaBlueTint,
              },
            ]}
            accessibilityRole="button"
            accessibilityLabel={`Call customer support at ${contactInfo.tollfreeNumber}`}>
            {`${contactInfo.tollfreeNumber}`}
          </Components.Link>
        </View>
        <View style={{flexDirection: 'row'}}>
          <Components.Text.H6 style={style.contactUsContentText}>
            {`${contactInfo.phoneNumberText}`}
          </Components.Text.H6>
          <Components.Link
            onPress={() => {
              if (
                contactInfo?.companyName?.plaintext?.includes('United Life')
              ) {
                trackCustomEvent(
                  analyticsTags.CaptureContactUsAULPhoneNumberTags,
                );
              } else if (
                contactInfo?.companyName?.plaintext?.includes(
                  'contact for life insurance',
                )
              ) {
                trackCustomEvent(
                  analyticsTags.CaptureContactUsStateLifeInsurancePhoneNumberTags,
                );
              } else {
                trackCustomEvent(
                  analyticsTags.CaptureContactUsStateLifeInsuranceLTCPhoneNumberTags,
                );
              }
              Linking.openURL('tel:${contactInfo.phoneNumber)');
            }}
            style={[
              style.contactUsContentText,
              {
                color: ColorConstants.oaBlueTint,
                fontFamily: Font.medium.fontFamily,
              },
            ]}
            accessibilityRole="button"
            accessibilityLabel={`Call ${contactInfo.phoneNumberText} at ${contactInfo.phoneNumber}`}>
            {`${contactInfo.phoneNumber}`}
          </Components.Link>
        </View>
        <Components.Text.H6
          style={[style.contactUsContentText, {marginBottom: 8}]}>
          {`${contactInfo.faxText} ${contactInfo.faxNumber}`}
        </Components.Text.H6>
      </View>
    );
  };
  const item = contactUsData.item;
  return (
    <ScreenWrapper scrollFooter paddingTop paddingBottom paddingHorizontal>
      <Breadcrumbs />
      <PolicyDropdown
        className="mb-6"
        accessToken={props.accessToken}
        text={'Contact Us'}
      />
      <View style={style.cardShadow}>
        <View className="lg:flex-row">
          <View className="w-full p-0">
            <View>
              <Components.Text.H6
                style={[style.contactUsContentText, {fontSize: 16}]}
                className="mb-1">
                {item.contactusText1.plaintext}
              </Components.Text.H6>
              <Components.Text.H6
                style={[style.contactUsContentText, {fontSize: 16}]}
                className="mb-4">
                {item.contactusText2.plaintext}
              </Components.Text.H6>
              <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
                <View className="w-full md:w-1/2 lg:w-1/3">
                  {renderContactInformation(
                    item.americanUnitedLifeInsuranceCompanycontactAddress,
                  )}
                </View>
                <View className="w-full md:w-1/2 lg:w-1/3">
                  {renderContactInformation(
                    item.stateLifeInsuranceCompanyContactAddress,
                  )}
                </View>
                <View className="w-full md:w-1/2 lg:w-1/3">
                  {renderContactInformation(
                    item.stateLifeInsuranceCompanyContactForAssetBasedLtcProductsAndGoldenRuleContactAddress,
                  )}
                </View>
              </View>
              <View className="mt-4 mr-8" style={style.horizontalDivider} />
              <View style={{}} className="mt-4 flex-row flex-wrap">
                <View className="w-full md:w-1/2 lg:w-1/3">
                  <Components.Text.H6
                    style={[
                      style.contactUsContentTextBold,
                      {
                        marginBottom: 4,
                      },
                    ]}>
                    {contactUsData.item.mailingAddressText}
                  </Components.Text.H6>
                  <Components.Text.H6 style={style.contactUsContentText}>
                    {contactUsData.item.mailingAddress.plaintext}
                  </Components.Text.H6>
                  <Components.Link
                    onPress={() => {
                      trackCustomEvent(
                        analyticsTags.CaptureMailingAddressEmailHyperlinksTags,
                      );
                      if (Platform.OS !== 'web') {
                        Linking.openURL(`mailto:${item.policyServiceEmailID}`);
                      } else {
                        // @ts-ignore
                        window.open(
                          `mailto:${item.policyServiceEmailID}`,
                          '_self',
                        );
                      }
                    }}
                    style={[
                      style.contactUsContentTextBold,
                      {
                        fontSize: 14,
                        marginBottom: 8,
                        color: ColorConstants.oaBlueTint,
                      },
                    ]}
                    accessibilityRole="button"
                    accessibilityLabel={`Send an email to ${item.emailPolicyChangeText}`}>
                    {item.emailPolicyServiceText}
                  </Components.Link>
                </View>
                <View className="w-full md:w-1/2 lg:w-1/3">
                  <Components.Text.H6
                    style={[
                      style.contactUsContentTextBold,
                      {
                        marginBottom: 4,
                      },
                    ]}>
                    {contactUsData.item.policyChange}
                  </Components.Text.H6>
                  <Components.Text.H6 style={style.contactUsContentText}>
                    {`${contactUsData.item.fax} ${contactUsData.item.policyChangeFaxNumber}`}
                  </Components.Text.H6>
                  <Components.Link
                    onPress={() => {
                      trackCustomEvent(
                        analyticsTags.CapturePolicyChangeEmailHyperlinksTags,
                      );
                      if (Platform.OS !== 'web') {
                        Linking.openURL(`mailto:${item.policyChangeEmailId}`);
                      } else {
                        // @ts-ignore
                        window.open(
                          `mailto:${item.policyChangeEmailId}`,
                          '_self',
                        );
                      }
                    }}
                    style={[
                      style.contactUsContentTextBold,
                      {
                        fontSize: 14,
                        color: ColorConstants.oaBlueTint,
                      },
                    ]}
                    accessibilityRole="button"
                    accessibilityLabel={`Email for policy changes`}>
                    {item.emailPolicyChangeText}
                  </Components.Link>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </ScreenWrapper>
  );
};
export default withAuth(ContactUs);
