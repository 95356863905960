import React from 'react';
import {View} from 'react-native';
import {useNavigation, useRoute} from '@react-navigation/native';
import {Components} from '@oneamerica/dxp-ui-components';
import responsiveStyles from './style';
import RouteConstants from '../../constants/RouteConstants';

const Breadcrumbs = () => {
  const navigation = useNavigation<any>();
  const route = useRoute();
  const styles = responsiveStyles();

  return (
    <View className="flex-row">
      <Components.Link
        accessibilityChecked
        accessibilityRole="button"
        accessibilityLabel="Navigate to home"
        onPress={() => {
          navigation.navigate(RouteConstants.home);
        }}
        pointerEvents="auto"
        linkStyle={styles.linkStyle}
        linkHoverStyle={styles.linkHoverStyle}
        hoverStyle={styles.hoverStyle}>
        HOME
      </Components.Link>
      <Components.Text style={styles.slashStyle}> /</Components.Text>
      <Components.Text style={styles.textStyle}>
        {` ${route.name.replaceAll('-', ' ').trim().toUpperCase()}`}
      </Components.Text>
    </View>
  );
};

export default Breadcrumbs;
