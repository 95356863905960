const Images = {
  bannerLogoWhite: require('./images/OANewBannerWhite.svg'),
  bannerLogo: require('./images/OABannerLogo.png'),
  barLogo: require('./images/OABarLogoWordmarkwhite.png'),
  barLogoBlue: require('./images/OABarLogoWordmarkBlue.png'),
  colorLogo: require('./images/OA_logo_red_white.png'),
  contactUsPageLogo: require('./images/OABannerLogo.png'),
  footerBg: require('./images/footer-bg.svg'),
  launchImage: require('./images/stripes_white.png'),
  noDataAvailable: require('./images/noData.png'),
  arrowUp: require('./images/arrow_up.png'),
  arrowDown: require('./images/arrow_down.png'),
  pageBG: require('./images/page-bg01.svg'),
  footerBG: require('./images/footer-bg.svg'),
  oaLogo: require('./images/OAHeaderNewLogo.svg'),
};

const ImageSrcSet = {
  bannerLogoWhite: {
    uri: Images.bannerLogoWhite,
    width: 153,
    height: 60,
    objectFit: 'cover',
  },
  bannerLogo: {
    uri: Images.bannerLogo,
    width: 200,
    height: 52,
  },
  barLogo: {
    uri: Images.barLogo,
    width: 150,
    height: 20,
  },
  barLogoBlue: {
    uri: Images.barLogo,
    width: 150,
    height: 20,
  },
  colorLogo: {
    uri: Images.colorLogo,
    width: 153,
    height: 60,
  },
  contactUsPageLogo: {
    uri: Images.contactUsPageLogo,
    width: 153,
    height: 60,
  },
  noDataAvailable: {
    uri: Images.noDataAvailable,
    width: 153,
    height: 60,
  },
  arrowUp: {
    uri: Images.arrowUp,
  },
  arrowDown: {
    uri: Images.arrowDown,
  },
};

export {ImageSrcSet, Images};
