import React, {useState, useEffect, FC, useRef} from 'react';
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  Linking,
} from 'react-native';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import {faChevronUp} from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import {faChevronSquareDown} from '@fortawesome/pro-solid-svg-icons/faChevronSquareDown';
import {faChevronSquareUp} from '@fortawesome/pro-solid-svg-icons/faChevronSquareUp';
import {Color, OATable} from '@oneamerica/dxp-ui-components';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import responsiveStyle from './style';
import {Printable} from '../../components/Printable';
import { NoEmitOnErrorsPlugin } from 'webpack';
import { isNonNullExpression } from 'typescript';

const {width} = Dimensions.get('window');
const firstColumnWidth = width * 0.5;
const secondColumnWidth = width * 0.5;
const styles = responsiveStyle(width);

interface UnitValue {
  unitValueDate: string;
  value: number | null;
  changeFromLastDate: number;
}

interface Investment {
  investmentId: string;
  investmentNameShort: string | null;
  investmentName: string;
  assetClassName: string;
  assetClassValue: string;
  investmentStyle: string | null;
  investmentSheetURL: string;
  investmentDisclosureURL: string | null;
  disclaimerLocalKey: string[];
  restriction: string[];
  regType: string | null;
  vendorPriceId: string | null;
  securityId: string | null;
  value: number;
  percentByTotalValue: number;
  frozen: boolean;
  retired: boolean;
  closed: boolean;
  invTypeValue: string | null;
  type: number;
  planDefaultElectionPercentage: number;
}

interface InvestmentUnitValueItem {
  investment: Investment;
  unitValues: UnitValue[];
}

interface InvestmentUnitValuesProps {
  investmentData: InvestmentUnitValueItem[];
  month?: string; // Add month as optional
  year?: string; // Add year as optional
}

const InvestmentUnitValues: FC<InvestmentUnitValuesProps> = ({
  investmentData = [],
  month,
  year,
}) => {
  const [expandedFunds, setExpandedFunds] = useState<{[key: string]: boolean}>(
    {},
  );
  const [isVisible, setIsVisible] = useState(false);
  const [monthYear, setMonthYear] = useState('');

  useEffect(() => {
    if (investmentData.length > 0) {
      const monthName = month ?? 'Unknown Month';
      const yearValue = year ?? 'Unknown Year';
      setMonthYear(`${monthName} ${yearValue}`);
    }
  }, [month, year, investmentData]);

  const toggleExpand = (fundId: string) => {
    setExpandedFunds((prev) => ({
      ...prev,
      [fundId]: !prev[fundId],
    }));
  };
  const contentRef = useRef(null);
  const toggleVisibility = () => {
    setIsVisible((prevVisibility) => {
      const shouldExpandAll = !prevVisibility;
      setExpandedFunds(
        shouldExpandAll
          ? Object.fromEntries(
              investmentData.map((_, index) => [`fund${index}`, true]),
            )
          : {},
      );

      return shouldExpandAll;
    });
  };

  const renderFundItem = (fund: InvestmentUnitValueItem, fundIndex: number) => {
    const isExpanded = expandedFunds[`fund${fundIndex}`] || false;

    function convertDateFormat(dateString) {
      const [year, month, day] = dateString.split('-');
      return `${month}/${day}/${year}`;
    }
    const tableData = fund.unitValues?.map((item) => {
      return {
        cells: [
          convertDateFormat(item.unitValueDate),
          item.value !== null
            ? item.value === 0
              ? item.value.toFixed(0)
              : item.value.toFixed(6)
            : 'N/A',
        ],
      };
    });

    return (
      <View key={`fund${fundIndex}`} style={styles.fundContainer}>
        <View className="flex flex-row justify-between w-full">
          <TouchableOpacity
            style={[styles.fundTitle, styles.fundHeader]}
            className="w-[65%] sm:w-[83%] lg:w-[85%]"
            onPress={() => toggleExpand(`fund${fundIndex}`)}>
            <FontAwesomeIcon
              icon={(isExpanded ? faChevronUp : faChevronDown) as IconProp}
              style={{
                borderWidth: 0,
                borderColor: 'transparent',
                flexShrink: 0, // Prevent the icon from shrinking
                width: 14, // You can adjust the size here
                height: 14,
                //@ts-ignore
                outline: 'none',
              }}
              color={Color.oaBlue}
            />
            <Text style={styles.fundTitle}>
              {fund.investment?.investmentName || 'Unnamed Fund'}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            className="flex-row justify-end pr-4 w-[35%] sm:w-[17%] lg:w-[15%]"
            style={[styles.fundHeader]}
            onPress={() => Linking.openURL(fund.investment.investmentSheetURL)}>
            <Text style={styles.seeDetailsText}>See details</Text>
          </TouchableOpacity>
        </View>
        {isExpanded && (
          <View style={styles.tableContainer}>
            <OATable
              hasNoData={tableData.length > 0}
              headerColumns={tableData.length > 0 ? ['Date', 'Value'] : []}
              tableData={
                tableData.length > 0
                  ? tableData.map((row) => row.cells)
                  : [
                      [
                        'Values not available for this timeframe. Please adjust your criteria.',
                      ],
                    ]
              }
              horizontalScroll={false}
              widthArr={[firstColumnWidth, secondColumnWidth]}
              style={styles.border}
              blueRows={true}
            />
          </View>
        )}
      </View>
    );
  };
  const onPrint = () => {};
  // Sort investmentData by investmentName
  const sortedInvestmentData = investmentData.toSorted((a, b) => {
    const nameA = a.investment.investmentName.toUpperCase();
    const nameB = b.investment.investmentName.toUpperCase();
    return nameA.localeCompare(nameB);
  });

  return (
    <View style={styles.container}>
      <>
        <Printable
          actionComponent={'link'}
          className={
            'absolute top-24 w-35 rounded-md bg-[#D1E5FA] py-2 px-2 no-underline z-10 hover:no-underline md:top-7 md:right-0'
          }
          size={14}
          color={'#2B4F8E'}
          hoverStyle={'none'}
          contentRef={contentRef}
          onPrint={onPrint}
          label={'Print report'}
          id="btnDisabled"
          theme="tertiary"
        />
      </>
      <View ref={contentRef}>
        <View className="pb-12 md:flex-row justify-between md:pb-2 mb-2">
          <Text style={styles.title}>Investment Unit Values - {monthYear}</Text>
        </View>
        <View style={styles.underline} />
        <View
          style={styles.tableView}
          className="w-full mt-6 sm:mt-10 lg:w-4/5">
          <TouchableOpacity
            style={styles.toggleButton}
            onPress={toggleVisibility}>
            <FontAwesomeIcon
              icon={
                (isVisible
                  ? faChevronSquareUp
                  : faChevronSquareDown) as IconProp
              }
              color={Color.oaBlue}
              style={{
                borderWidth: 0,
                borderColor: 'transparent',
               // @ts-ignore
               outline:'none',
              }}
            />
            <Text style={styles.toggleButtonText}>Investment Option</Text>
          </TouchableOpacity>

          <View style={styles.childContainer}>
            <ScrollView>
              {sortedInvestmentData.map((fund, index) =>
                renderFundItem(fund, index),
              )}
            </ScrollView>
          </View>
        </View>
      </View>
    </View>
  );
};

export default InvestmentUnitValues;
