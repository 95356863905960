import {Theme, Font, Color} from '@oneamerica/dxp-ui-components';
import BreakpointConstants from '../../constants/BreakpointConstants';
import ColorConstants from '../../constants/ColorConstants';
import {getButtonMonthAndYearWidth} from '../../utils';

export default (width: number) =>
  Theme.Responsive.create({
    horizontalDivider: {
      height: 1,
      width: '100%',
      backgroundColor: ColorConstants.grey200,
    },
    contentTitle: {
      color: ColorConstants.iron,
      fontSize: 20,
      fontFamily: Font.soleil.regular.fontFamily,
      marginTop: Theme.Metrics.small,
      marginBottom: Theme.Metrics.xSmall,
      lineHeight: 25,
    },
    ReportBy: {
      color: ColorConstants.iron,
      fontSize: 15,
      fontFamily: Font.regular.fontFamily,
      marginRight: 10,
    },
    topMargin: {
      marginTop: Theme.Metrics.xLarge,
      position: 'relative',
      zIndex: -1,
    },
    notesText: {
      fontSize: 18,
      color: ColorConstants.iron,
      fontFamily: Font.soleil.regular.fontFamily,
      marginTop: Theme.Metrics.medium,
    },
    notesContent: {
      flex: 1,
      fontSize: 15,
      color: ColorConstants.iron,
      fontFamily: Font.regular.fontFamily,
      lineHeight: 22,
    },
    investmentnotes: {
      flex: 1,
      fontSize: 12,
      color: ColorConstants.iron,
      fontFamily: Font.regular.fontFamily,
      lineHeight: 14.06,
    },
    bulletStyle: {
      fontSize: 20,
      lineHeight: 20,
      marginRight: 5,
    },
    smModalSize: {
      margin: BreakpointConstants.isMD(width) ? '365' : 'auto',
      maxHeight: BreakpointConstants.isMD(width) ? '' : '190px',
      maxWidth: BreakpointConstants.isMD(width) ? '' : '90%',
    },
    linkContactUs: {
      color: ColorConstants.oaBlueTint,
      fontSize: 15,
      fontFamily: Font.medium.fontFamily,
      fontWeight: '500',
      //@ts-ignore
      display: 'inline-flex',
    },
    linkContactUsFocus: {
      textDecorationLine: 'underline',
      textDecorationColor: Theme.Color.linkFocus,
    },
    linkContactUsHover: {
      color: ColorConstants.oaBlueTint,
      fontSize: 15,
      fontFamily: Font.medium.fontFamily,
      fontWeight: '500',
    },
    modalTitle: {
      fontFamily: Font.soleil.regular.fontFamily,
      fontSize: 20,
      fontWeight: '400',
    },
    //Styles for Table - Month and Year
    container: {
      paddingTop: 30,
      backgroundColor: Color.white,
    },
    tableView: {
      paddingTop: 10,
      marginTop: 25,
      marginHorizontal: 'auto',
    },
    title: {
      fontSize: 22,
      color: Color.input.text,
      fontWeight: '600',
      fontFamily: Font.soleil.semibold.fontFamily,
      lineHeight: 27.94,
    },
    underline: {
      height: 2,
      backgroundColor: Color.gray0,
      marginVertical: 4,
    },
    fundContainer: {
      borderColor: Color.gray0,
      backgroundColor: 'transparent',
      borderWidth: 1,
    },
    fundHeader: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 10,
    },
    fundHeaderDay: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    fundTitle: {
      fontSize: 15,
      fontWeight: '500',
      marginLeft: 8,
      lineHeight: 22,
      fontFamily: Font.regular.fontFamily,
      color: Theme.Color.gray6,
    },
    seeDetailsText: {
      fontSize: 16,
      fontWeight: '400',
      lineHeight: 18.75,
      fontFamily: Font.regular.fontFamily,
      color: Color.oaBlueLight,
      textAlign: 'right',
    },
    TextDayReport: {
      fontSize: 15,
      fontWeight: '400',
      fontFamily: Font.regular.fontFamily,
      color: Color.oaBlueLight,
      textAlign: 'left',
    },
    childContainer: {
      backgroundColor: Color.white,
      borderColor: Color.gray0,
      borderWidth: 1,
    },
    tableContainer: {
      borderColor: Color.white,
      borderRadius: 4,
    },
    toggleButton: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 8,
      paddingHorizontal: 12,
      backgroundColor: Color.gray0,
    },
    toggleButtonText: {
      marginLeft: 8,
      fontSize: 15,
      fontFamily: Font.regular.fontFamily,
      fontWeight: '600',
    },
    noBorder: {
      borderWidth: 0,
      borderColor: 'transparent',
    },
    border: {
      borderWidth: 1,
      borderColor: Color.gray0,
      width: '100%',
    },
    buttonWidth: {
      width: BreakpointConstants.isSM(width) ? 'auto' : '100%',
    },
    buttonSpaceLeft: {
      marginLeft: 20,
    },
    buttonTop: {
      marginLeft: 0,
      marginTop: 10,
      alignSelf: 'center',
      alignItems: 'center',
    },
    buttonMonthAndYearWidth: {
      width: getButtonMonthAndYearWidth(width),
    },
    textStyleRows: {
      fontSize: 15,
      fontWeight: '400',
      fontFamily: Font.regular.fontFamily,
      color: ColorConstants.iron,
      textAlign: 'left',
    },
  })();
