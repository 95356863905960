import React from 'react';
import {View, Text} from 'react-native';
import {Components, Font} from '@oneamerica/dxp-ui-components';
import responsiveStyle from '../../style';
import InfoWrapper from '../../../../components/InfoWrapper/InfoWrapper';
import ColorConstants from '../../../../constants/ColorConstants';
import {formatCurrency, formatDate} from '../../../../utils';

export const LoansCard = ({className, getLoanItem, policyValue, row}) => {
  const style = responsiveStyle();
  return getLoanItem() ? (
    <View className={className}>
      <View style={[style.cardShadow, row > 1 && style.flex]}>
        <View style={style.cardHeader}>
          <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
            Loans
          </Components.Text.H3>
        </View>
        <Text
          style={{
            fontSize: 14,
            fontFamily: Font.light.fontFamily,
            color: ColorConstants.iron,
          }}>
          As of {formatDate(policyValue.valueAsOfDate, 'MMM DD, YYYY', '-')}
        </Text>
        <View className="mt-4" style={style.horizontalDivider} />
        <View style={style.contentWrapper}>
          <View className="py-2" style={style.row}>
            <View className="items-center" style={style.flex}>
              <InfoWrapper
                title={getLoanItem().type}
                information={formatCurrency(getLoanItem().amount)}
                boldInfo
                centerAlign
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  ) : null;
};
