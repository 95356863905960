import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Pressable, View, ViewStyle } from 'react-native';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { icons, Theme } from '@oneamerica/dxp-ui-components';
import SelectItem from './components/SelectItem.index';
import responsiveStyles from './styles';
import { ErrorListProps, TextInput } from '../TextInput';

export type SelectOptions = {
  label: string;
  value: string;
  disabled?: boolean;
};

export interface SelectInputProps {
  className?: string;
  disabled?: boolean;
  dropdownHeight?: number;
  errorList?: ErrorListProps[];
  label?: string;
  onPress?: (val: string | number) => void;
  options: SelectOptions[];
  placeholder?: string;
  style?: ViewStyle;
  testID?: string;
  value?: string;
  optionsLength?: number;
  background?: string;
}

export const Controls = ({ disabled, isOpen }) => {
  const styles = responsiveStyles(disabled);
  return (
    <View style={[styles.webInputIconPosition]}>
      <FontAwesomeIcon
        icon={icons.caretDown as IconProp}
        size={20}
        color={Theme.Color.black}
        style={{
          transform: [{ rotate: isOpen ? '180deg' : '0deg' }], // Rotate when dropdown is open
        }}
      />
    </View>
  );
};

const SelectInput = (props: SelectInputProps): ReactElement | null => {
  const {
    className,
    disabled = false,
    dropdownHeight,
    errorList = [],
    label,
    onPress = () => {},
    options,
    placeholder,
    style,
    testID,
    value,
    optionsLength,
    background,
  } = props;
  const styles = responsiveStyles(disabled, background, optionsLength);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  // Ref to detect touch outside the component
  const dropdownRef = useRef<View>(null);

  const onOpen = () => setIsOpen(!isOpen);

  const onPressItem = (item: SelectOptions) => {
    setSelectedValue(item.label);
    setIsOpen(false);
    onPress(item.value);
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    // Add event listener to detect clicks outside
    const handleClick = (e: any) => {
      //@ts-ignore
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    // Listen to clicks on the document during the capture phase
    document.addEventListener('click', handleClick, true); // Capture phase

    return () => {
      document.removeEventListener('click', handleClick, true); // Cleanup on unmount
    };
  }, []); // Only set up the event listener once

  return (
    <View
      className={className}
      pointerEvents={disabled ? 'none' : 'auto'}
      style={styles.container}
      testID={testID}>
      <Pressable
        accessibilityRole="link"
        onPress={onOpen}
        style={[styles.pressableContainer, style]}>
        <TextInput
          Controls={<Controls disabled={disabled} isOpen={isOpen} />}
          ErrorList={errorList}
          focusable={false}
          editable={!disabled}
          label={label}
          placeholder={placeholder}
          pointerEvents="none"
          value={selectedValue}
        />
      </Pressable>
      {isOpen && options && (
        <View
          ref={dropdownRef}
          style={[
            styles.itemContainer,
            {
              height: dropdownHeight ?? options.length * 30,
            },
          ]}>
          {options.map((o) => (
            <SelectItem
              key={o.label}
              label={o.label}
              disabled={o.disabled}
              onPress={() => onPressItem(o)}
              selectedValue={selectedValue}
            />
          ))}
        </View>
      )}
    </View>
  );
};

export { SelectInput };
