import {Theme, Font} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../constants/ColorConstants';
import BreakpointConstants from '../../constants/BreakpointConstants';

export default (width: number) =>
  Theme.Responsive.create({
    cardShadow: {
      borderRadius: 5,
      shadowRadius: 6,
      backgroundColor: Theme.Color.background,
      shadowColor: Theme.Color.backgroundShadow,
      shadowOffset: {width: 0, height: 3},
      paddingHorizontal: Theme.Metrics.small,
      paddingVertical: Theme.Metrics.small,
      flex: 1,
    },
    cardHeader: {
      marginHorizontal: -Theme.Metrics.medium,
      marginTop: Theme.Metrics.small,
      paddingHorizontal: Theme.Metrics.medium,
      borderRadius: 4,
    },
    cardHeaderTitle: {color: Theme.Color.oaBlue},
    contentTitle: {
      color: ColorConstants.iron,
      fontSize: 20,
      fontFamily: Font.soleil.regular.fontFamily,
      marginTop: 24,
    },
    firstParagraph: {
      color: ColorConstants.iron,
      fontSize: 15,
      fontFamily: Font.regular.fontFamily,
      marginTop: 16,
    },
    secondParagraph: {
      color: ColorConstants.iron,
      fontSize: 15,
      fontFamily: Font.regular.fontFamily,
    },
    myReportsLink: {
      color: ColorConstants.oaBlueTint,
      fontFamily: Font.regular.fontFamily,
      fontSize: 15,
      //@ts-ignore
      cursor: 'pointer',
      fontWeight: '500',
    },
    pdfLink: {
      color: ColorConstants.oaBlueTint,
      marginTop: 8,
      fontFamily: Font.regular.fontFamily,
      fontSize: 14,
      //@ts-ignore
      cursor: 'pointer',
      textTransform: 'uppercase',
    },
    noPadding: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingHorizontal: 0,
    },
    tabTitleSize: {
      fontSize: BreakpointConstants.isMD(width) ? 20 : 16,
    },
    horizontalDivider: {
      backgroundColor: ColorConstants.grey200,
      height: 1,
      width: '100%',
    },
    investmentCardDate: {
      color: ColorConstants.iron,
      fontSize: 16,
      lineHeight: 27,
      fontFamily: Font.regular.fontFamily,
      alignSelf: 'flex-start',
      flex: 1,
    },
    transferBal: {
      color: ColorConstants.oaBlueTint,
      lineHeight: 27,
      fontFamily: Font.regular.fontFamily,
      fontSize: 16,
      alignSelf: 'flex-start',
      display: 'flex',
    },
    editFutureElections: {
      color: ColorConstants.oaBlueTint,
      lineHeight: 27,
      fontFamily: Font.regular.fontFamily,
      fontSize: 16,
      alignSelf: 'flex-start',
      flexDirection: 'row',
    },
    componentPadding: {
      paddingHorizontal: 16,
      paddingVertical: 16,
    },
    outlineNone: {
      outlineWidth: 0,
      marginLeft: 10,
      marginTop: 5,
    },
    headingTable: {
      color: ColorConstants.iron,
      fontSize: 14,
      fontFamily: Font.regular.fontFamily,
    },
    linkStyle: {
      color: ColorConstants.oaBlueTint,
      fontSize: 16,
      fontFamily: Font.medium.fontFamily,
      //@ts-ignore
      display: 'inline-flex',
    },
    warningText: {
      color: ColorConstants.iron,
      fontSize: 15,
      fontFamily: Font.regular.fontFamily,
      marginTop: 8,
      lineHeight: 17.58,
    },
    warningMessageParent: {
      marginTop: 8,
    },
    warningPDFLink: {
      fontWeight: '700',
    },
    contactUsLink: {
      color: ColorConstants.oaBlueTint,
      marginTop: 0,
      fontFamily: Font.regular.fontFamily,
      fontSize: 15,
      //@ts-ignore
      cursor: 'pointer',
    },
    marginBottom16: {marginBottom: 16},
    alertBannerContentMargin: {marginTop: 10, marginBottom: 10},
  })();
