import React from 'react';
import {View} from 'react-native';
import {Components} from '@oneamerica/dxp-ui-components';
import responsiveStyle from '../PolicyValue/style';
import InfoWrapper from '../../../../components/InfoWrapper/InfoWrapper';

const IncomeInformation = ({IncomeInformationData}) => {
  const style = responsiveStyle();
  return (
    <View className="lg:flex-row pt-6">
      <View className="w-full p-0">
        <View style={style.cardShadow}>
          <View style={style.cardHeader}>
            <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
              Income Information
            </Components.Text.H3>
          </View>
          <View className="mt-4" style={style.horizontalDivider} />
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
            className="mt-4 w-full">
            {IncomeInformationData.map((data, index) => {
              return (
                <React.Fragment key={`${data.title}-${index}`}>
                  {index === 3 && (
                    <View
                      style={style.innerCardHeader}
                      className="w-full mt-4 mb-2 md:mb-4 md:mt-6 lg:pl-2">
                      <Components.Text.H6
                        style={[
                          style.innerCardHeaderTitle,
                          {
                            paddingVertical: 10,
                            marginBottom: 0,
                            paddingHorizontal: 8,
                          },
                        ]}>
                        Available Income Options
                      </Components.Text.H6>
                    </View>
                  )}
                  <View className="w-1/2 sm:w-1/2 md:w-1/4 lg:pl-2">
                    <InfoWrapper
                      title={data.title}
                      subTitle
                      information={data.value || '-'}
                      infoTextStyle={{
                        fontWeight: '500',
                        marginTop: 8,
                      }}
                    />
                  </View>
                </React.Fragment>
              );
            })}
          </View>
        </View>
      </View>
    </View>
  );
};

export default IncomeInformation;
