import React, {RefObject} from 'react';
import {StyleProp, TextStyle} from 'react-native';
import {useReactToPrint} from 'react-to-print';
import {Button, Components} from '@oneamerica/dxp-ui-components';
interface PrintableProps {
  label?: string;
  style?: StyleProp<TextStyle>;
  theme?: string;
  id?: string;
  contentRef?: RefObject<Element | Text>;
  onPrint?: () => void;
  documentTitle?: string;
  printStyle?: string;
  className?: string;
  size?: number;
  color?: string;
  actionComponent?: 'button' | 'link';
  // html?: string;
}
export const Printable = ({
  className,
  actionComponent = 'button',
  // html,
  label = 'Print',
  style = null,
  theme = 'tertiary',
  id = 'printBtn',
  contentRef,
  onPrint,
  documentTitle,
  printStyle = '',
  size,
  color,
}: PrintableProps) => {
  const pageStyle =
    `
    @media print {
    ::-webkit-scrollbar {
      display: none;
    }
  
    html, body {
      margin: 0 !important;
      padding: 0 !important;
      background-color: white;
      -webkit-print-color-adjust: exact; 
      print-color-adjust: exact;
    }
    .page-break {
      display: none;
      page-break-before: auto;
    }
   
    .fund-container {
      page-break-inside: avoid;
      break-inside: avoid;
    }
      
  .account-table {
    page-break-inside: avoid;
    break-inside: avoid;
  }

  .current-elections-container {
    page-break-before: always;
    break-before: page;
  }
  }
  /* Safari specific print styling */
  @media print and (-webkit-min-device-pixel-ratio:0){
    .page-break {
      page-break-before: avoid;
    }
  }
  
  @page {
    size: auto;
    margin: 2mm 10mm;
    width: 100%;
  }
  ` + printStyle;

  const handlePrint = useReactToPrint({
    contentRef,
    documentTitle,
    pageStyle,
  });
  const print = () => {
    onPrint?.();
    handlePrint();
  };
  return (
    <>
      {actionComponent === 'link' ? (
        <Components.Link
          className={className}
          style={style}
          hoverStyle={{textDecorationLine: 'none'}}
          textStyle={{fontWeight: 500}}
          size={size}
          color={color}
          accessibilityRole="link"
          id={id}
          onPress={print}
          theme={theme}>
          {label}
        </Components.Link>
      ) : (
        <Button
          className={className}
          style={style}
          accessibilityRole="button"
          id={id}
          onPress={print}
          theme={theme}>
          {label}
        </Button>
      )}
    </>
  );
};
