import React, {useContext} from 'react';
import {View} from 'react-native';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import withAuth from '../../../auth/withAuth';
import responsiveStyle from '../style';
import transferBalanceRules from '../transferBalanceRules.json';

const TransferRule = () => {
  const {width} = useContext(Theme.ResponsiveContext);
  const style = responsiveStyle(width);

  return (
    <View style={style.transferBalanceRulesBox}>
      <View>
        <Components.Text style={style.ruleText}>Rules</Components.Text>
      </View>
      {transferBalanceRules?.data?.transferBalanceRules?.item?.map(
        (item, index) => {
          return (
            <View key={`${item}_${index}`} style={style.bulletPoint}>
              <Components.Text style={style.bulletSymbol}>
                {'\u2022'}
              </Components.Text>
              <Components.Text style={style.rulesContent}>
                {item}
              </Components.Text>
            </View>
          );
        },
      )}
    </View>
  );
};

export default withAuth(TransferRule);
