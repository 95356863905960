import {Theme} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../constants/ColorConstants';

const styles = (topInset: number) =>
  Theme.Responsive.create({
    infoHeader: {
      backgroundColor: Theme.Color.oaBlue,
    },
    header: {
      backgroundColor: Theme.Color.header.background,
      shadowColor: Theme.Color.ice2,
      shadowOffset: Theme.Color.shadow.shadowOffset,
      shadowOpacity: 0.4,
      shadowRadius: 3,
      // paddingTop: 18,
      // borderColor: Theme.Color.oaBlue,
      // borderTopWidth: topInset + 5,
    },
    navHover: {
      textDecorationLine: 'none',
      borderColor: Theme.Color.ice4,
    },
    cardShadow: {
      borderRadius: 5,
      shadowRadius: 6,
      backgroundColor: Theme.Color.background,
      shadowColor: Theme.Color.backgroundShadow,
      shadowOffset: {width: 0, height: 3},
      paddingHorizontal: Theme.Metrics.medium,
      paddingVertical: Theme.Metrics.small,
    },
    menuItem: {
      position: 'absolute',
      top: '100%',
      width: '150%',
      left: '-50%',
    },
    cursorPointer: {
      //@ts-ignore
      cursor: 'pointer',
    },
    horizontalDivider: {
      backgroundColor: ColorConstants.grey200,
      height: 1,
      width: '100%',
    },
    outlineNone: {
      outlineWidth: 0,
    },
  })();

export default styles;
