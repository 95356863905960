import React from 'react';
import {View} from 'react-native';
import responsiveStyle from './style';
import {PieChart} from './PieChart';
import ChartLegend from './ChartLegend';

const InvestmentChart = (props) => {
  const mappedData = props.data;
  const assetTotal = props.assetTotal;
  const totalpercentByVaule = props.totalpercentByValue ?? 0;

  const style = responsiveStyle();
  return (
    <View
      className="flex-col md:flex-row"
      style={{flexDirection: props.flexDirection}}>
      {props.showPieChart && (
        <View
          style={style.pieChartColumn}
          className={props.showLegendTable && 'md:pr-4'}>
          <PieChart
            data={mappedData}
            height={props.pieChartHeight}
            testID="pieChart"
            isDonut
            showDollarSymbol={props.showDollarSymbol}
            width={props.pieChartWidth}
            isLegendDisabled={true}
            LegendTableTitle="Current Balance by Asset Class"
            showPercentColumn={true}
            animate={false}
          />
        </View>
      )}
      {props.showLegendTable && (
        <View
          style={
            props.showPiechart ? style.tableColumn : style.tableColumnFullWidth
          }>
          <ChartLegend
            mappedData={mappedData}
            assetTotal={assetTotal}
            totalPercentageByValue={totalpercentByVaule}
            showChildRows={props.showChildRows}
            columnConfig={props.columnConfig}
            childRowsColumnConfig={props.childRowsColumnConfig}
            columnWidthArr={props.columnWidthArr}
            legendTableTitle={props.legendTableTitle}
            horizontal={props.horizontal}
            showAlternateRowColors={props.showAlternateRowColors}
            showBorder={props.showBorder}
            fullHeight={props.fullHeight}
            setIsErrorInTable={props.setIsErrorInTable}
            setIsDirty={props.setIsDirty}
            getEditedData={props.getEditedData}
            footerRibbonText={props.footerRibbonText}
            fromPreviewscreen={props?.previousClicked}
            isMobileTableFooter={props.isMobileTableFooter}
            showPercentLeftToTransfer={props.showPercentLeftToTransfer}
            isTotalAccountValueVisible={props.isTotalAccountValueVisible}
            totalValue={props.totalValue}
          />
        </View>
      )}
    </View>
  );
};

export default InvestmentChart;
