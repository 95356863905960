export const ChangePasswordContents = {
  changePasswordToggleLabel: 'Change Password',
  passwordHeader: 'Password',
  currentPasswordLabel: 'Current Password',
  incorrectPasswordText: 'Incorrect password',
  newPasswordLabel: 'New Password',
  weakLabel: 'Weak',
  fairLabel: 'Fair',
  goodLabel: 'Good',
  greatLabel: 'Great',
  strongLabel: 'Strong',
  passwordMustBeMinimum8CharactersText: 'Password must be minimum 8 characters',
  confirmNewPasswordLabel: 'Confirm New Password',
  passwordDoesNotMatchText: 'Password does not match',
  passwordRequirementsHeader: 'Password Requirements',
  passwordRequirementsTextLine1: '8 characters minimum',
  passwordRequirementsTextLine2: '20 characters maximum',
  passwordRequirementsTextLine3: 'Must contain at least 3 of the following:',
  passwordRequirementsTextLine4: 'Upper case letter',
  passwordRequirementsTextLine5: 'Lower case letter',
  passwordRequirementsTextLine6: 'Number',
  passwordRequirementsTextLine7: 'Special character',
  passwordRequirementsTextLine8:
    'Allowable special characters include: ~ ! @ # % ^ & ( ) - _ + =',
  passwordRequirementsTextLine9: 'Cannot contain User ID',
  passwordRequirementsTextLine10: 'Cannot contain spaces',
  confirmPasswordChangesText: 'Your password has successfully been updated',
};
