import {
  axiosInstancePolicyHolder,
  axiosInstanceAEM,
  axiosProfileHolder,
} from './wrapper/apiwrapper';
import {isHomeOfficeUser, getAccountDetailsTokenCookie} from '../utils';

export const NetworkManager = {
  getPolicyDetails: (policyNumber: string | null, token: string) => {
    return axiosInstancePolicyHolder.get(
      `policyholder/policies/${policyNumber}/policysummary`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...(isHomeOfficeUser() && {
            'x-oa-statetoken': `${getAccountDetailsTokenCookie()}`,
          }),
        },
      },
    );
  },
  getPolicyLists: (token: string) => {
    return axiosInstancePolicyHolder.get(`policyholder/policies/policylist`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(isHomeOfficeUser() && {
          'x-oa-statetoken': `${getAccountDetailsTokenCookie()}`,
        }),
      },
    });
  },
  getTransferTransaction: ({token, policyNumber, transactiontype}) => {
    return axiosInstancePolicyHolder.get(
      `policyholder/policies/${policyNumber}/transfer/${transactiontype}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...(isHomeOfficeUser() && {
            'x-oa-statetoken': `${getAccountDetailsTokenCookie()}`,
          }),
        },
      },
    );
  },
  getReportMonthAndYear: ({
    token,
    policyNumber,
    month,
    year,
    investmentIds,
  }) => {
    const investmentIdsQuery = Array.isArray(investmentIds)
      ? investmentIds
          .map((id) => `investmentIds=${encodeURIComponent(id)}`)
          .join('&&')
      : investmentIds;
    const url = `policyholder/policies/${policyNumber}/report/unitvalues/bymonth/${month}/${year}?${investmentIdsQuery}`;
    return axiosInstancePolicyHolder.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(isHomeOfficeUser() && {
          'x-oa-statetoken': `${getAccountDetailsTokenCookie()}`,
        }),
      },
    });
  },

  getReportByDay: ({token, policyNumber, day}) => {
    const url = `policyholder/policies/${policyNumber}/report/unitvalues/byday/${day}`;
    return axiosInstancePolicyHolder.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(isHomeOfficeUser() && {
          'x-oa-statetoken': `${getAccountDetailsTokenCookie()}`,
        }),
      },
    });
  },

  sendTransferTransaction: ({
    token,
    policyNumber,
    transactiontype,
    payload,
  }) => {
    return axiosInstancePolicyHolder.post(
      `policyholder/policies/${policyNumber}/transfer/${transactiontype}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...(isHomeOfficeUser() && {
            'x-oa-statetoken': `${getAccountDetailsTokenCookie()}`,
          }),
        },
      },
    );
  },
  getPolicyRelationshipModal: () => {
    return axiosInstanceAEM.get(
      'graphql/execute.json/ILFS-Policy-Holder/policyRelationshipModal;path=/content/dam/ilfs-policy-holder-cf/policy-relationship-model-cf',
    );
  },
  getContactUsContent: () => {
    return axiosInstanceAEM.get(
      'graphql/execute.json/ILFS-Policy-Holder/contactUsPage;path=/content/dam/ilfs-policy-holder-cf/contact-us/contactus',
    );
  },
  getPolicyServiceRequest: () => {
    return axiosInstanceAEM.get(
      `graphql/execute.json/ILFS-Policy-Holder/policyServiceRequestPage;path=/content/dam/ilfs-policy-holder-cf/policy-service-request-page-cf`,
    );
  },
  getStrategiesModal: () => {
    return axiosInstanceAEM.get(
      'graphql/execute.json/ILFS-Policy-Holder/strategyInformationModelByPath;path=/content/dam/ilfs-policy-holder-cf/strategy-information-cf',
    );
  },
  getAEMNotification: () => {
    return axiosInstanceAEM.get(
      '/content/experience-fragments/dxp-oa/us/en/site/header/ilfs-policyholder-portal.model.json',
    );
  },
  getAEMGenericNotification: () => {
    return axiosInstanceAEM.get(
      '/content/experience-fragments/dxp-oa/us/en/site/header/generic-notification.model.json',
    );
  },
  getProfileData: (token) => {
    return axiosProfileHolder.get(`profile/user`, {
      headers: {Authorization: `Bearer ${token}`},
    });
  },
  updateProfileData: (data, token) => {
    return axiosProfileHolder.put(`profile/user/contactinfo`, data, {
      headers: {Authorization: `Bearer ${token}`},
    });
  },
  checkUserId: (token, newUserId) => {
    return axiosProfileHolder.get(`profile/user/available`, {
      headers: {Authorization: `Bearer ${token}`, 'x-oa-newLogonid': newUserId},
    });
  },
  getInvestmentOptions: (policyNumber: string | null, token: string) => {
    return axiosInstancePolicyHolder.get(
      `policyholder/policies/${policyNumber}/investmentoptions`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...(isHomeOfficeUser() && {
            'x-oa-statetoken': `${getAccountDetailsTokenCookie()}`,
          }),
        },
      },
    );
  },
  sendInvestmentOptionElection: ({token, policyNumber, payload}) => {
    return axiosInstancePolicyHolder.post(
      `policyholder/policies/${policyNumber}/investmentoptions`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          ...(isHomeOfficeUser() && {
            'x-oa-statetoken': `${getAccountDetailsTokenCookie()}`,
          }),
        },
      },
    );
  },
};
