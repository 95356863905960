import React, {useRef, useContext, useState} from 'react';
import {View, Platform, ViewStyle} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigation} from '@react-navigation/core';
import {faAngleDown} from '@fortawesome/pro-solid-svg-icons/faAngleDown';
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {Components, Theme, Font} from '@oneamerica/dxp-ui-components';
import responsiveStyles from './styles';
import BreakpointConstants from '../../constants/BreakpointConstants';
import {getDecodedCookie, getGreetings} from '../../utils/cookies';
import DropDownView from '../DropDownView';

const PolicyDropdown = ({
  className,
  accessToken,
  text,
}: {
  className: string;
  accessToken: string;
  text?: string;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const {width} = useContext(Theme.ResponsiveContext);
  const styles = responsiveStyles(width);
  const state = useSelector((state: any) => state.policyLists);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigation<any>();

  const linkStyle = (selected = false) => {
    const style = [
      {
        fontSize: 14,
        fontFamily: selected ? Font.bold.fontFamily : Font.regular.fontFamily,
        color: BreakpointConstants.isMD(width)
          ? Theme.Color.text
          : Theme.Color.white,
      },
      styles.cursorPointer,
    ] as ViewStyle;
    return {
      hoverStyle: style,
      linkHoverStyle: style,
      linkStyle: style,
    };
  };

  const webEvents = {
    onMouseEnter: () => {
      !isHovered && setIsHovered(true);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    },
    onMouseLeave: () => {
      timerRef.current = setTimeout(() => {
        isHovered && setIsHovered(false);
      }, 250);
    },
  };

  const touchEvents = {
    onClick: () => {
      setIsHovered(!isHovered);
    },
  };

  const getSelectedPolicy = () =>
    `${state.selectedPolicy.policyPlanName} (${state.selectedPolicy.policyPlanNumber})`;

  const user = getDecodedCookie('ACCOUNT_DETAILS');
  const firstName = user?.firstName || '';
  const greetingsMessage = getGreetings(firstName);

  return (
    <>
      {Array.isArray(state.list) && (state.list as Array<any>).length > 1 && (
        <View
          {...(Platform.OS === 'web' && webEvents && touchEvents)}
          className="flex md:hidden z-50"
          style={styles.mobileDropdownWrapper}>
          <Components.Text className="text-14 flex-1" style={styles.mobileText}>
            {getSelectedPolicy()}
          </Components.Text>
          <FontAwesomeIcon
            style={[
              styles.outlineNone as FontAwesomeIconStyle,
              {
                transform: [{rotateX: isHovered ? '180deg' : '0deg'}],
              },
            ]}
            icon={faAngleDown as IconProp}
            size={16}
            color={Theme.Color.white}
          />
          {isHovered && (
            <DropDownView
              styles={styles}
              width={width}
              state={state}
              dispatch={dispatch}
              navigate={navigate}
              accessToken={accessToken}
              linkStyle={linkStyle}
            />
          )}
        </View>
      )}
      <View className={`z-auto md:z-50 ${className}`} style={styles.row}>
        <Components.Text.H1
          className="flex-1 text-sm md:text-lg"
          style={styles.colorBlue}>
          {text ?? greetingsMessage}
        </Components.Text.H1>
        {text !== 'My Profile' &&
          Array.isArray(state.list) &&
          (state.list as Array<any>).length > 1 && (
            <View className="hidden md:flex" style={styles.row}>
              <Components.Text
                className="text-14 mr-3"
                style={[styles.mobileText, styles.colorBlue]}>
                {getSelectedPolicy()}
              </Components.Text>
              <View
                style={styles.cursorPointer}
                {...(Platform.OS === 'web' && webEvents)}>
                <View style={styles.iconWrapper}>
                  <FontAwesomeIcon
                    style={styles.outlineNone as FontAwesomeIconStyle}
                    icon={faAngleDown as IconProp}
                    size={16}
                    color={Theme.Color.oaBlue}
                  />
                </View>
                {isHovered && (
                  <DropDownView
                    styles={styles}
                    width={width}
                    state={state}
                    dispatch={dispatch}
                    navigate={navigate}
                    accessToken={accessToken}
                    linkStyle={linkStyle}
                  />
                )}
              </View>
            </View>
          )}
      </View>
    </>
  );
};

export default PolicyDropdown;
