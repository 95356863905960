import React, {ReactElement, useState, useEffect} from 'react';
import {View} from 'react-native';
import {useSelector} from 'react-redux';
import {Components} from '@oneamerica/dxp-ui-components';
import {analyticsTags} from '../../../../utils/Analytics/AnalyticsTags';
import {trackCustomEvent} from '../../../../utils/Analytics/AdobeAnalyticsUtils';

export interface SaveChangesProps {
  className?: string;
  disabled?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  isLoading?: boolean;
  showChangePassword?: boolean;
  setValue?: any;
  isError?: boolean;
  userSuccess?: boolean;
  activeMenu?: string;
  Index?: number;
  handleClick?: (value) => void;
}

const SaveChanges = ({
  className,
  disabled = true,
  onCancel = () => {},
  onSave = () => {},
  isLoading = false,
  showChangePassword = true,
  setValue,
  userSuccess,
  isError,
  activeMenu,
  Index,
  handleClick = (value) => {},
}: SaveChangesProps): ReactElement | null => {
  const [canSave, setCanSave] = useState(false);
  const [password, setPassword] = useState('');
  const profileData = useSelector((state: any) => state.profileData);
  useEffect(() => {
    if (
      profileData.isProfileUpdated ||
      profileData.isError ||
      isError ||
      userSuccess
    ) {
      setInputValue('');
    }
  }, [profileData, userSuccess, isError]);

  const setInputValue = (val: string) => {
    setPassword(val);
    // Not storing password in state, just check for min chars and enable save button
    if (val.length > 7) {
      setCanSave(true);
      setValue(val);
    } else {
      setCanSave(false);
    }
  };
  handleClick(() => {
    if (password !== '' && password.length > 7) {
      if (showChangePassword) {
        return !canSave || disabled;
      } else {
        return isLoading || disabled;
      }
    } else {
      return true;
    }
  });
  const buttonClick = () => {
    onSave();
  };
  return (
    <View className={`pt-3 pb-2 lg:flex-row lg:justify-end ${className}`}>
      <View className="w-full mb-3 lg:w-[330px] lg:mr-4 lg:mb-0">
        {showChangePassword && (
          <Components.TextInput
            secureTextEntry
            placeholder="Enter Current Password To Save"
            value={password}
            autoComplete="off"
            onChangeText={(val) => setInputValue(val)}
            testID="saveContent-password"
          />
        )}
      </View>
      <View className="mb-3 lg:mr-4 lg:mb-0">
        <Components.Button
          theme="primary"
          outline
          onPress={() => {
            if (activeMenu === 'contact') {
              trackCustomEvent(
                analyticsTags.CaptureCancelMyProfileContactInformationTags,
              );
            } else if (activeMenu === 'password') {
              if (Index === 0) {
                trackCustomEvent(
                  analyticsTags.CaptureCancelMyProfileChangePasswordIDTags,
                );
              }
            } else if (activeMenu === 'security') {
              trackCustomEvent(
                analyticsTags.CaptureCancelMyProfileSecurityQuestionTags,
              );
            } else {
              trackCustomEvent(analyticsTags.CaptureChangeUserIDCancelTags);
            }
            onCancel();
            setPassword('');
          }}
          testID="saveContent-cancel"
          className="w-full mb-2 lg:auto lg:mb-0">
          Cancel
        </Components.Button>
      </View>
      <View>
        <Components.Button
          className="w-full lg:auto"
          disabled={
            showChangePassword ? !canSave || disabled : isLoading || disabled
          }
          loading={isLoading}
          onPress={() => {
            if (activeMenu === 'contact') {
              trackCustomEvent(
                analyticsTags.CaptureSaveMyProfileContactInformationTags,
              );
            } else if (activeMenu === 'password') {
              if (Index === 0) {
                trackCustomEvent(
                  analyticsTags.CaptureSaveMyProfileChangePasswordIDTags,
                );
              }
            } else if (!activeMenu) {
              trackCustomEvent(analyticsTags.CaptureChangeUserIDSaveTags);
            } else {
              trackCustomEvent(
                analyticsTags.CaptureSaveMyProfileSecurityQuestionTags,
              );
            }
            buttonClick();
          }}
          testID="saveContent-save"
          theme="primary">
          Save
        </Components.Button>
      </View>
    </View>
  );
};

export default SaveChanges;
