import React, {ComponentProps} from 'react';
import {View} from 'react-native';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryPie,
} from 'victory-native';
import {VictoryTooltip} from 'victory-tooltip';
import {Theme} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../../constants/ColorConstants';
const {Font} = Theme;

type ExtendedPieChartProps = ComponentProps<typeof VictoryPie>;

type GraphDataProps = {
  y?: number | string;
  x?: string | number;
  amount?: number;
  color?: string;
};

type PieChartProps = ExtendedPieChartProps & {
  animate?: boolean;
  data: GraphDataProps[];
  donutLabel?: string;
  donutLabelFontSize?: number;
  height?: number;
  isDonut?: boolean;
  legendPosition?: 'bottom' | 'right';
  showLabel?: boolean;
  showLegend?: boolean;
  testID?: string;
  width?: number;
  isLegendDisabled?: boolean;
  LegendTableTitle?: string;
  showPercentColumn?: boolean;
  assetTotal?: number;
  showDollarSymbol?: boolean;
};

export const PieChart = ({
  animate = true,
  data,
  donutLabel,
  donutLabelFontSize = 20,
  height = 400,
  isDonut = false,
  legendPosition = 'right',
  testID,
  showLabel = false,
  showLegend = false,
  width = 400,
  isLegendDisabled = false,
  LegendTableTitle = '',
  showPercentColumn = false,
  assetTotal = 0,
  showDollarSymbol = false,
  ...props
}: PieChartProps) => {
  const pieChartData = data.flat().filter((item) => item.y !== 0);
  return (
    <View testID={testID}>
      <View style={{height, width, zIndex: 1}}>
        <VictoryChart
          height={height}
          width={width}
          padding={{top: 20, right: 20, left: 20}}>
          <VictoryPie
            {...props}
            innerRadius={isDonut ? (width * 16) / 100 : 0}
            labels={({datum}) =>
              showDollarSymbol
                ? `$${datum.y + '\n' + datum.x}`
                : `${datum.y + '%' + '\n' + datum.x}`
            }
            standalone={false}
            style={{
              labels: {
                fill: () => (showLabel ? 'black' : 'transparent'),
                fontFamily: Font.regular.fontFamily,
              },
              data: {
                fill: ({datum}) => datum.color,
                fillOpacity: 0.9,
                stroke: 'white',
                strokeWidth: 3,
              },
            }}
            data={pieChartData}
            labelComponent={
              <VictoryTooltip
                style={{
                  fontSize: 11,
                  fontFamily: Font.light.fontFamily,
                  color: ColorConstants.iron,
                }}
                flyoutStyle={{
                  fill: Theme.Color.white,
                  stroke: 'none',
                  boxShadow: Theme.Color.backgroundShadow,
                }}
                constrainToVisibleArea
                renderInPortal={false}
              />
            }
            animate={
              // NOTE: Piechart only animates when the data changes.
              animate && {
                easing: 'exp',
                duration: 2000,
                onLoad: {duration: 1000},
              }
            }
          />
          {isDonut && donutLabel && (
            <VictoryLabel
              textAnchor="middle"
              style={{fontSize: donutLabelFontSize}}
              x={height / 2}
              y={height / 2}
              text={donutLabel}
            />
          )}
          <VictoryAxis
            // Removes the Axis from the chart
            style={{
              axis: {stroke: 'transparent'},
              ticks: {stroke: 'transparent'},
              tickLabels: {fill: 'transparent'},
            }}
          />
        </VictoryChart>
      </View>
    </View>
  );
};
