import React from 'react';
import {Animated} from 'react-native';
import {Color} from '@oneamerica/dxp-ui-components';

export const AnimatedLabel = ({
  styles,
  focusAnim,
  small,
  label,
  isFocused,
  editable,
  ErrorList,
}) => {
  //label disabled background color transition to white when animated
  const disabledBg = editable
    ? Color.input.background
    : Color.input.disabledBackground;
  return (
    <Animated.View
      style={[
        styles.labelContainer,
        {
          top: focusAnim.interpolate({
            inputRange: [0, 1],
            outputRange: [0, -6],
          }),
          transform: [
            {
              scale: focusAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [1, 0.75],
              }),
            },
            {
              translateY: focusAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [small ? 7 : 16, -2],
              }),
            },
            {
              translateX: focusAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [small ? 7 : 16, 10],
              }),
            },
          ],
        },
      ]}>
      <Animated.Text
        style={[
          styles.label,
          isFocused && styles.labelFocused,
          ErrorList.length > 0 && styles.labelError,
          !editable && styles.label,
          {
            fontSize: focusAnim.interpolate({
              inputRange: [0, 1],
              outputRange: [15, 13],
            }),
            backgroundColor: focusAnim.interpolate({
              inputRange: [0, 1],
              outputRange: [disabledBg, Color.input.background],
            }),
          },
        ]}>
        {label}
      </Animated.Text>
    </Animated.View>
  );
};
