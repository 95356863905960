import {Platform} from 'react-native';
import {Color, Font, Metrics, Responsive} from '@oneamerica/dxp-ui-components';

const HORIZONTAL = Metrics.input.horizontal;
const VERTICAL = Metrics.input.vertical;

const styles = (
  border = true,
  editable = true,
  small = false,
  rounded = true,
) =>
  Responsive.create({
    container: {
      width: '100%',
    },
    inputContainer: {
      flexDirection: 'row',
      paddingHorizontal: Platform.OS === 'android' ? 0 : HORIZONTAL,
      paddingVertical: VERTICAL,
    },
    inputStack: {
      flex: 1,
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    label: {
      color: Color.input.label,
    },
    labelFocused: {
      color: Color.input.focused,
    },
    labelError: {
      color: Color.input.error,
    },
    labelContainer: {
      position: 'absolute',
      top: -6,
      backgroundColor: Color.input.background,
      zIndex: 1,
    },
    sideControlText: {
      alignSelf: 'flex-end',
      paddingVertical: Metrics.xSmall,
      paddingLeft: Metrics.small,
    },
    input: {
      position: 'relative',
      ...Font.regular,
      color: Color.input.label,
      fontSize: small ? Font.Small.size : Font.Body.size || 15,
      paddingVertical: small ? 0 : VERTICAL,
      paddingRight: small ? 0 : HORIZONTAL,
      paddingLeft: small ? 6 : HORIZONTAL,
      width: '100%',
      height: small ? 30 : 50,
      backgroundColor: editable
        ? Color.input.background
        : Color.button.secondary.focused.background,
    },

    centeredInput: {
      fontSize: Font.Body.size || 16,
    },
    border: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      ...(border && {
        shadowColor: 'transparent',
        borderWidth: Metrics.input.borderWidth,
        borderColor: Color.input.border,
        borderRadius: rounded ? Metrics.input.borderRadius : 0,
      }),
    },
    borderFocused: {
      ...(border && {
        borderColor: Color.ice5,
        borderWidth: Metrics.input.borderWidth,
        shadowColor: Color.ice5,
        shadowOffset: {width: 0, height: 0},
        shadowRadius: 4,
      }),
    },
    borderError: {
      ...(border && {
        borderColor: Color.input.error,
        shadowColor: 'transparent',
      }),
    },
    disabled: {
      backgroundColor: Color.button.secondary.focused.background,
    },
    innerInputContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
    },
    iconLink: {
      position: 'absolute',
      right: 0,
      paddingHorizontal: 10,
      height: '100%',
      maxHeight: 50,
    },
    helperContainer: {
      paddingTop: 4,
      paddingLeft: 12,
    },
    errorContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: Metrics.xxSmall,
      minHeight: 18,
    },
  })();

export default styles;
