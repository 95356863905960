import React, {useContext} from 'react';
import {View} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import responsiveStyle from '../../style';
import InfoWrapper from '../../../../components/InfoWrapper/InfoWrapper';
import BreakpointConstants from '../../../../constants/BreakpointConstants';
import RouteConstants from '../../../../constants/RouteConstants';
import {COBDetails} from '../COBDetails';
import {formatDate} from '../../../../utils';
import {analyticsTags} from '../../../../utils/Analytics/AnalyticsTags';
import {trackCustomEvent} from '../../../../utils/Analytics/AdobeAnalyticsUtils';
import {GeneralPolicyData} from '../GeneralPolicyData';
import {ContractStatus} from '../ContractStatus';

export const PolicyHighlightsCard = ({
  className,
  getType,
  validateElement,
  getTaxQualification,
  getAffiliate,
  policyStatusButton,
  policyHighlights,
  generalPolicyDataRender,
  buttonStatus,
  getTerminatedReason,
  terminatedReason,
  titleCase,
  account,
  showCOBInPolicyHighlights,
  cobPolicyNumber,
  cobPolicyStatus,
  cobStatusButton,
  policyTerminationDate,
  cobPolicyStatusReason,
  cobTerminationDate,
  linkStyle,
  navigateToCOBDetails,
  row,
  popupData,
}) => {
  const style = responsiveStyle();
  const {width} = useContext(Theme.ResponsiveContext);
  const navigation = useNavigation<any>();
  const navigateToContactUs = () => {
    navigation.navigate(RouteConstants.contactus);
  };
  const linkStyleContactUs = {
    style: style.linkContactUs,
    linkStyle: style.linkContactUs,
    linkHoverStyle: style.linkContactUs,
    hoverStyle: style.linkContactUsHover,
    focusStyle: style.linkContactUsFocus,
  };

  return Object.keys(account).length !== 0 ? (
    <View className={className}>
      <View
        style={[
          style.cardShadow,
          style.paddingHorizontalXSmall,
          row > 1 && style.flex,
        ]}>
        <View style={style.cardHeader}>
          <Components.Text.H3
            className="px-2"
            style={[style.cardHeaderTitle, style.flex]}>
            Policy Highlights
          </Components.Text.H3>
        </View>
        <View className="my-4" style={style.horizontalDivider} />
        <View style={style.contentWrapper}>
          <Components.Text.H1 className="px-2" style={style.contentHeader}>
            {getType(account?.type, account?.affiliate)}
          </Components.Text.H1>
          <View className="pt-2" style={style.column}>
            <View className="flex-row flex-wrap">
              {validateElement(
                account?.name,
                <InfoWrapper
                  width="w-1/2 md:w-1/3"
                  title={'Product Name'}
                  subTitle
                  information={account?.name ?? ''}
                />,
              )}
              {validateElement(
                account?.number,
                <InfoWrapper
                  width="w-1/2 md:w-1/3"
                  title={'Policy Number'}
                  subTitle
                  information={account?.number ?? ''}
                />,
                BreakpointConstants.isMD(width) ? undefined : null,
              )}
              {['Terminated', 'Suspended'].includes(buttonStatus)
                ? validateElement(
                    getAffiliate(account?.affiliate),
                    <InfoWrapper
                      width="w-1/2 md:w-1/3"
                      title={'Company Name'}
                      subTitle
                      information={getAffiliate(account?.affiliate)}
                    />,
                  )
                : validateElement(
                    getTaxQualification(policyHighlights),
                    <InfoWrapper
                      width="w-1/2 md:w-1/3"
                      title={'Tax Qualification'}
                      subTitle
                      information={getTaxQualification(policyHighlights)}
                    />,
                  )}
            </View>
            <View className="my-2" style={style.horizontalDivider} />
            {!['Terminated', 'Suspended'].includes(buttonStatus) && (
              <>
                <View className="flex-row flex-wrap">
                  {validateElement(
                    getAffiliate(account?.affiliate),
                    <InfoWrapper
                      width="w-1/2 md:w-1/3"
                      title={'Company Name'}
                      subTitle
                      information={getAffiliate(account?.affiliate)}
                    />,
                  )}
                  {validateElement(
                    generalPolicyDataRender?.issueDate,
                    <InfoWrapper
                      width="w-1/2 md:w-1/3"
                      title={'Issue Date'}
                      subTitle
                      information={formatDate(
                        policyHighlights[0]?.generalPolicyData?.issueDate,
                      )}
                    />,
                  )}
                  <GeneralPolicyData
                    generalPolicyDataRender={generalPolicyDataRender}
                    policyHighlights={policyHighlights}
                  />
                </View>
                <View className="my-2" style={style.horizontalDivider} />
              </>
            )}

            <ContractStatus
              buttonStatus={buttonStatus}
              getTerminatedReason={getTerminatedReason}
              terminatedReason={terminatedReason}
              policyStatusButton={policyStatusButton}
              policyTerminationDate={policyTerminationDate}
              popupData={popupData}
              navigateToContactUs={navigateToContactUs}
              policyHighlights={policyHighlights}
              linkStyleContactUs={linkStyleContactUs}
            />

            {buttonStatus === 'Terminated' ? (
              <View>
                <View className="my-2" style={style.horizontalDivider} />
                <View className="flex-row flex-wrap">
                  <Components.Text
                    className="py-2 px-2"
                    style={style.terminatedContent}>
                    The status of this policy is either terminated or the
                    premium payments have discontinued, and the policy is now in
                    its non-forfeiture option. For additional information,
                    please reference the{' '}
                    <Components.Link
                      onPress={() => {
                        trackCustomEvent(
                          analyticsTags.CaptureContactUsPolicyHighlightsTerminatedTags,
                        );
                        navigateToContactUs();
                      }}
                      accessibilityLabel="Contact Us"
                      accessibilityRole="button"
                      {...linkStyleContactUs}>
                      Contact Us
                    </Components.Link>{' '}
                    page.
                  </Components.Text>
                </View>
              </View>
            ) : null}
            <COBDetails
              showCOBInPolicyHighlights={showCOBInPolicyHighlights}
              validateElement={validateElement}
              cobPolicyStatus={cobPolicyStatus}
              cobStatusButton={cobStatusButton}
              cobPolicyStatusReason={cobPolicyStatusReason}
              cobPolicyNumber={cobPolicyNumber}
              cobTerminationDate={cobTerminationDate}
              linkStyle={linkStyle}
              navigateToCOBDetails={navigateToCOBDetails}
            />
          </View>
        </View>
      </View>
    </View>
  ) : null;
};
