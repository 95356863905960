export const columnConfiguration = [
  {
    columnName: 'Investment',
    format: 'string',
    showColumn: true,
    isTotal: false,
    textAlign: 'left',
    isCurrency: false,
    isPercentage: false,
    isEditable: false,
    showPdfIcon: true,
  },
  {
    columnName: 'Asset Class',
    format: 'string',
    showColumn: true,
    isTotal: false,
    textAlign: 'left',
    isCurrency: false,
    isPercentage: false,
    isEditable: false,
  },
  {
    columnName: 'Current Value',
    format: 'string',
    showColumn: true,
    isTotal: true,
    textAlign: 'right',
    isCurrency: true,
    isPercentage: false,
    isEditable: false,
  },
  {
    columnName: 'Transfer from',
    format: 'decimal',
    showColumn: true,
    isTotal: false,
    textAlign: 'right',
    isCurrency: false,
    isPercentage: false,
    isEditable: false,
  },
  {
    columnName: 'Transfer to',
    format: 'decimal',
    showColumn: true,
    isTotal: false,
    textAlign: 'right',
    isCurrency: false,
    isPercentage: false,
    isEditable: false,
  },
  {
    columnName: 'Reallocated Balance',
    format: 'decimal',
    showColumn: true,
    isTotal: false,
    textAlign: 'right',
    isCurrency: false,
    isPercentage: false,
    isEditable: false,
  },
];

export const columnWidthArrMob = ['30%', '20%', '11%', '12%', '12%', '15%'];
export const columnWidthArr = ['30%', '20%', '11%', '12%', '12%', '15%'];

export default {
  grey200: '#D6D6D6',
  iron: '#333333',
  lightGreen: '#EFF4E7',
  darkGreen: '#438300',
  sun90: 'rgba(244, 181, 49, 0.9)',
  sun30: 'rgba(244, 181, 49, 0.3)',
  modalBG: '#474747CC',
  grey: '#FAFAFA',
  darkRed: '#E23D28',
  lightRed: '#F8D3CF',
  darkBlue: '#69869A',
  lightBlue: '#EEF7FC',
  lightGrey: '#EAEAEA',
  darkGrey: '#707070',
  oaBlueTint: '#2B4F8E',
  oaBlue: '#002c77',
  oaIceBlue: '#8BBFF3',
};
