import React, {Fragment} from 'react';
import {TextInput, View, Linking, StyleProp, TextStyle} from 'react-native';
import {faFilePdf} from '@fortawesome/pro-regular-svg-icons/faFilePdf';
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {Components, Font, Color} from '@oneamerica/dxp-ui-components';
import responsiveStyles from './styles';
import ColorConstants from '../../../constants/ColorConstants';
import {formatCurrency, formatDecimal} from '../../../utils';

const style = responsiveStyles();

export const getTextInputValue = (columnName, item) => {
  if (!isTextInputEditable(columnName, item)) {
    return '';
  }
  switch (columnName) {
    case '%':
      return item?.y + '%';
    case '$':
      return formatCurrency(item?.amount);
    case 'New %':
    case 'Current %':
      return item?.newPercentage;
    case 'Transfer from':
      return item?.currentValue === 0 ? '' : item?.fromValue;
    case 'Transfer to':
      if (item.currentValue > 0 && item.fromValue > 0) {
        return '';
      } else if (item.currentValue >= 0) {
        return item.toValue;
      } else if (item.fromValue >= 0 && item.toValue > 0) {
        return '';
      } else {
        return '0.00';
      }
    default:
      return item?.value;
  }
};

export const isTextInputEditable = (columnName, item) => {
  if (
    columnName === 'Transfer to' &&
    item?.currentValue > 0 &&
    +item?.fromValue === 0
  ) {
    return true;
  } else if (columnName === 'Transfer from' && item?.currentValue === 0) {
    return false;
  } else if (
    columnName === 'Transfer from' &&
    item?.currentValue > 0 &&
    item?.toValue > 0
  ) {
    return false;
  } else if (columnName === 'Transfer from' && item?.currentValue > 0) {
    return true;
  } else if (columnName === 'Transfer to' && item?.fromValue > 0) {
    return false;
  } else if (columnName === 'Transfer to') {
    return true;
  }
  return true;
};

export const isPercentVisible = (columnName) => {
  switch (columnName) {
    case 'Transfer from':
    case 'Transfer to':
      return false;

    default:
      return true;
  }
};

export const getObjectKey = (columnName) => {
  switch (columnName) {
    case 'New %':
      return 'newPercentage';
    case 'Transfer from':
      return 'fromValue';
    case 'Transfer to':
      return 'toValue';
    default:
      return undefined;
  }
};

export const getReallocatedValue = (item?: any) => {
  const {toValue, fromValue, currentValue} = item;

  return Number(currentValue) - Number(fromValue) + Number(toValue);
};

export const getNewPercentErrorMsg = (item) => {
  if (
    item.newPercentage !== '' &&
    item.minPercentByTotalValue === item.maxPercentByTotalValue &&
    Number(item.newPercentage) !== Number(item.minPercentByTotalValue)
  ) {
    return (
      'The value for this investment option must be exactly ' +
      item.minPercentByTotalValue +
      '%'
    );
  } else if (
    item.newPercentage !== '' &&
    item.minPercentByTotalValue !== item.maxPercentByTotalValue &&
    (Number(item.newPercentage) < Number(item.minPercentByTotalValue) ||
      Number(item.newPercentage) > Number(item.maxPercentByTotalValue))
  ) {
    return (
      'The value for this investment option must be between ' +
      item.minPercentByTotalValue +
      '% and ' +
      item.maxPercentByTotalValue +
      '%'
    );
  }
  return '';
};

const getTransferFromErrMsg = (item) => {
  if (
    Number(item.fromValue) > 0 &&
    Number(item.fromValue) < Number(item.minValue)
  ) {
    return `The value for this investment option cannot be less than $${Number(
      item.minValue,
    )}`;
  } else if (item.fromValue > item.currentValue) {
    return `The value for this investment option must be between $${item.minValue} and $${item.currentValue}`;
  }
  return '';
};

const getTransferToErrMsg = (item) => {
  if (item.toValue > item.maxValue) {
    return `The value for this investment option must be between $${
      item.minValue
    } and $${(Number(item.maxValue) - Number(item.currentValue)).toFixed(2)}`;
  }
  return '';
};

const getErrorMessage = (colName, item) => {
  let errMsg = '';
  switch (colName) {
    case 'New %':
      errMsg = getNewPercentErrorMsg(item);
      return errMsg;
    case 'Transfer from':
      errMsg = getTransferFromErrMsg(item);
      return errMsg;
    case 'Transfer to':
      errMsg = getTransferToErrMsg(item);
      return errMsg;
    default:
      return errMsg;
  }
};

const getInputMode = (arr) => {
  if (arr.format === 'decimal') {
    return 'decimal';
  } else {
    return 'none';
  }
};
const getMinMaxValidationsMsg = (colName, item, styles) => {
  const errorMsg = getErrorMessage(colName, item);
  return (
    <View>
      <Components.Text style={[styles.validationMsg]}>
        {errorMsg}
      </Components.Text>
    </View>
  );
};

export const showOtherAssetColumns = (showOtherAssetColumnsParams) => {
  const {
    arr,
    columnWidthArr,
    styles,
    item,
    index,
    fullHeight,
    // idx,
    showBorder,
    // handleEdit,
    // isDollarOrPercent,
  } = showOtherAssetColumnsParams;

  if (arr.showColumn && arr.isEditable) {
    return prepareEditableColumn(showOtherAssetColumnsParams);
  } else if (arr.showColumn) {
    const pdfColParams = {styles, item};
    const colParams = {arr, item, index};
    return (
      <View
        key={`${item.y}_${index}`}
        className={getTextAlignment(arr?.textAlign)}
        style={[
          {
            ...styles.legendRows,
            ...styles.leftBorder,
            width: columnWidthArr[index],
            fontFamily: item.isExpanded
              ? Font.medium.fontFamily
              : Font.regular.fontFamily,
            flexDirection: 'row',
          },
          fullHeight && styles.cellPadding,
          arr.columnName === 'New %' &&
            item.y !== +item.newPercentage &&
            item?.newPercentage >= 0 && {
              backgroundColor: ColorConstants.lightGrayishBlue,
              fontWeight: '600',
            },
          arr.columnName === 'Transfer from' &&
            item.z !== item.fromValue && {
              backgroundColor: ColorConstants.lightGrayishBlue,
            },
          arr.columnName === 'Transfer to' &&
            item.y !== item.toValue && {
              backgroundColor: ColorConstants.lightGrayishBlue,
            },
          arr.columnName === 'Reallocated Balance' &&
            (item.z !== item.fromValue || item.y !== item.toValue) && {
              backgroundColor: ColorConstants.lightGrayishBlue,
              fontWeight: '600',
            },
          !showBorder && styles.noVerticalBorder,
        ]}>
        {arr?.showPdfIcon
          ? prepareColumnWithPDFIcon(pdfColParams)
          : prepareNonEditableColumn(colParams)}
      </View>
    );
  }
};

export const getAmountTextInputValue = (arr, item) => {
  if (
    arr.columnName.includes('Current Allocations for Index Period') ||
    arr.columnName.includes('Scheduled Allocations for Index Period')
  ) {
    return ` ${formatDecimal(item.newPercentage)}%`;
  }
  switch (arr.columnName) {
    case 'Rate Type':
      return item.rateType;
    case 'Current Rate':
      return ` ${formatDecimal(item.currentRate)}%`;
    case 'Account Balance Percentage':
      return ` ${formatDecimal(item.newPercentage)}%`;
    case 'Account Balance':
      return formatCurrency(item.amount);
    case 'Current Value':
      return formatCurrency(item.currentValue);
    case '%':
    case 'Current %':
      return arr.format === 'decimal'
        ? formatDecimal(item.y) + '%'
        : item.y + '%';
    case 'New %':
      return item.newPercentage + '%';
    case 'Transfer from':
      return formatCurrency(item?.fromValue);
    case 'Transfer to':
      return formatCurrency(item?.toValue);
    case '$':
      return formatCurrency(item.amount);
    case 'Reallocated Balance':
      return formatCurrency(getReallocatedValue(item));
    default:
      return item.investmentName;
  }
};

export const showAssetClassnameColumn = (columnParameters) => {
  const {
    item,
    arr,
    styles,
    showChildRows,
    index,
    returnArrowImage,
    fullHeight,
    showBorder,
  } = columnParameters;
  return (
    <>
      {arr.showColumn && (
        <View
          key={index}
          style={[
            fullHeight
              ? {
                  ...styles.legendRows,
                  ...styles.leftBorder,
                  ...styles.cellPadding,
                  //width: arr.columnWidth,
                  flex: 1,
                  flexDirection: 'row',
                }
              : {
                  ...styles.legendRows,
                  //width: arr.columnWidth,
                  flex: 1,
                  flexDirection: 'row',
                  paddingLeft: 12,
                },
            !showBorder && style.noVerticalBorder,
          ]}>
          <View
            style={{
              ...styles.assetClassColor,
              backgroundColor: item.color,
            }}
          />
          {arr.showColumn && showChildRows && (
            <View style={styles.arrowImageView}>{returnArrowImage(item)}</View>
          )}
          <Components.Text
            style={{
              fontSize: 14,
              lineHeight: 22,
              fontFamily: item.isExpanded
                ? Font.medium.fontFamily
                : Font.regular.fontFamily,
            }}>
            {item.x}
          </Components.Text>
        </View>
      )}
    </>
  );
};
const openURL = (url: string) => {
  (async function () {
    try {
      await Linking.openURL(url);
    } catch (error) {
      console.log(error);
    }
  })();
};

export const getChildRowHeaderItems = (
  childRowsColumnConfig,
  columnWidthArr,
  columnConfig,
  showChildRows,
) => {
  return (
    showChildRows && (
      <View style={{...style.row}} testID="childRowHeading">
        {columnConfig.map((arr, index) => {
          const {columnName} = arr;
          if (index === 0) {
            return (
              <View
                key={columnName}
                style={{...style.row, width: columnWidthArr[index]}}>
                {[
                  childRowsColumnConfig.childTableColumnHeadings.map(
                    (heading, index) => {
                      if (index === 0) {
                        return (
                          childRowsColumnConfig.showChildColumns[index] && (
                            <View
                              key={columnName}
                              style={{
                                ...style.childRowHeadingCell,
                                paddingLeft: 36,
                                width:
                                  childRowsColumnConfig.columnWidths[index],
                              }}>
                              <Components.Text style={{fontSize: 14}}>
                                {heading}
                              </Components.Text>
                            </View>
                          )
                        );
                      } else {
                        return (
                          childRowsColumnConfig.showChildColumns[index] && (
                            <View
                              key={columnName}
                              style={{
                                ...style.childRowHeadingCell,
                                ...style.cellRight,
                                width:
                                  childRowsColumnConfig.columnWidths[index],
                              }}>
                              <Components.Text style={{fontSize: 14}}>
                                {heading}
                              </Components.Text>
                            </View>
                          )
                        );
                      }
                    },
                  ),
                ]}
                {arr.showColumn && (
                  <Components.Text
                    style={{
                      ...style.leftBorder,
                      ...style.childRowHeadingCell,
                      fontSize: 14,
                      lineHeight: 23,
                      width: childRowsColumnConfig.columnWidths[index], //arr?.childTableColumnWidths[1],
                    }}
                  />
                )}
                {arr.showColumn && (
                  <Components.Text
                    style={{
                      ...style.leftBorder,
                      ...style.childRowHeadingCell,
                      fontSize: 14,
                      lineHeight: 23,
                      width: childRowsColumnConfig.columnWidths[index], //arr?.childTableColumnWidths[2],
                    }}
                  />
                )}
              </View>
            );
          } else {
            return (
              <Components.Text
                key={arr.columnName}
                className={
                  arr.textAlign === 'right' ? 'text-right' : 'text-left'
                }
                style={{
                  ...style.legendRows,
                  ...style.childRowHeadingCell,
                  ...style.leftBorder,
                  width: columnWidthArr[index],
                }}>
                {' '}
              </Components.Text>
            );
          }
        })}
      </View>
    )
  );
};

export const createChildRows = (
  showChildRows,
  columnWidthArr,
  columnConfig,
  childRows,
  childRowsColumnConfig,
) => {
  const cleanInvestmentName = childRows?.investmentName?.split('<')[0]?.trim();
  return (
    <>
      {showChildRows && (
        <View className="flex-row">
          {columnConfig.map((arr, index) => {
            if (index === 0) {
              return (
                <View
                  key={arr.columnName}
                  style={{
                    ...style.row,
                    ...style.topBorder,
                    width: columnWidthArr[index],
                  }}>
                  {childRowsColumnConfig.showChildColumns[0] &&
                    childRowsColumnConfig.isHyperlink[0] && (
                      <View
                        style={{
                          width: childRowsColumnConfig.childRowsParentWidths[0],
                          marginHorizontal: 0,
                          paddingLeft: 36,
                          height: 'auto',
                        }}>
                        {childRows?.investmentSheetURL ? (
                          <Components.Link
                            style={style.linkStyle}
                            className="py-2 pr-1"
                            accessibilityRole="button"
                            linkStyle={style.linkStyle}
                            linkHoverStyle={style.linkHoverStyle}
                            onPress={() =>
                              openURL(childRows?.investmentSheetURL)
                            }>
                            {cleanInvestmentName}
                          </Components.Link>
                        ) : (
                          <Components.Text
                            className="py-2 pr-1"
                            style={style.linkTextStyle}>
                            {cleanInvestmentName}
                          </Components.Text>
                        )}
                      </View>
                    )}
                  {!childRowsColumnConfig.isHyperlink[0] && (
                    <Components.Text
                      style={{
                        ...style.leftBorder,
                        fontSize: 14,
                        lineHeight: 22,
                        padding: 8,
                        paddingLeft: 36,
                        width: childRowsColumnConfig.childRowsParentWidths[0],
                      }}>
                      {cleanInvestmentName}
                    </Components.Text>
                  )}
                  {childRowsColumnConfig.showChildColumns[1] && (
                    <Components.Text
                      style={{
                        ...style.leftBorder,
                        textAlign: 'right',
                        padding: 8,
                        fontSize: 14,
                        lineHeight: 22,
                        width: childRowsColumnConfig.childRowsParentWidths[1],
                      }}>
                      {childRows.units || ' '}
                    </Components.Text>
                  )}
                  {childRowsColumnConfig.showChildColumns[2] && (
                    <Components.Text
                      style={{
                        ...style.leftBorder,
                        textAlign: 'right',
                        padding: 8,
                        fontSize: 14,
                        lineHeight: 22,
                        width: childRowsColumnConfig.childRowsParentWidths[2],
                      }}>
                      {childRows.unitValues || ' '}
                    </Components.Text>
                  )}
                </View>
              );
            } else {
              return (
                arr.showColumn && (
                  <Components.Text
                    key={arr.columnName}
                    className={
                      arr.textAlign === 'right' ? 'text-right' : 'text-left'
                    }
                    style={{
                      ...style.legendRows,
                      ...style.leftBorder,
                      width: columnWidthArr[index],
                    }}>
                    {getAmountOrPercentValue(childRows, arr)}
                  </Components.Text>
                )
              );
            }
          })}
        </View>
      )}
    </>
  );
};

const getAmountOrPercentValue = (childRows, arr) => {
  if (childRows.y && arr.isPercentage) {
    return childRows.y + '%';
  } else if (childRows.amount && arr.isCurrency) {
    return formatCurrency(childRows.amount);
  } else {
    return childRows.amount;
  }
};

const getHeaderStyles = (
  index,
  fullHeight,
  columnWidthArr,
): StyleProp<TextStyle> => {
  let styles: StyleProp<TextStyle> = fullHeight
    ? {
        // base styles if fullHeight is true
        ...style.fullHeightHeadingCell,
        ...style.bottomBorderBlack,
        width: columnWidthArr[index],
      }
    : {
        // base styles if fullHeight is false
        ...style.headingCell,
        width: columnWidthArr[index],
      };

  if (index <= 0) {
    // adding flex-row if index is lt 0
    styles = {...styles, ...style.row};
  }
  if (index > 0 && !fullHeight) {
    // adding leftBorder when neither index is 0 nore fullHeight is true
    styles = {
      ...styles,
      ...style.leftBorder,
    };
  }
  return styles;
};

const getTextAlignment = (textAlign) => {
  switch (textAlign) {
    case 'left':
    case 'right':
    case 'center':
      return `text-${textAlign}`;
    default:
      return 'text-center';
  }
};

export const createHeader = (columnConfig, columnWidthArr, fullHeight) => {
  return columnConfig.map((arr, index) =>
    arr.showColumn ? (
      <Components.Text
        key={arr.columnName}
        className={getTextAlignment(arr?.textAlign)}
        style={[
          getHeaderStyles(index, fullHeight, columnWidthArr),
          {textAlign: arr.textAlign},
        ]}>
        {arr.columnName}
      </Components.Text>
    ) : null,
  );
};

const getBackgroundColor = (
  fromPreviewscreen,
  editedRowsLength,
  isAllocationTotalError,
) => {
  if (isAllocationTotalError) {
    return '#E40032';
  } else if (!fromPreviewscreen && !editedRowsLength) {
    return '#EBEBEB';
  } else {
    return '#4A7729';
  }
};

const getFooterTextColor = (
  fromPreviewscreen,
  editedRowsLength,
  isAllocationTotalError,
) => {
  if (!fromPreviewscreen && !editedRowsLength && !isAllocationTotalError) {
    return '#333333';
  } else {
    return '#FFFFFF';
  }
};
const getFooterStyle = (footerParameters): StyleProp<TextStyle> => {
  const {
    isAllocationTotalError,
    columnConfig,
    editedRowsLength,
    isDollarTransfer,
    footerRibbonText,
    isMobileTableFooter,
    showPercentLeftToTransfer,
    fromPreviewscreen,
  } = footerParameters;
  const styles: StyleProp<TextStyle> = footerParameters.fullHeight
    ? {
        ...style.totalCellFullHeight,
        ...style.footerLabel,
        width: getFooterColumnWidth(
          columnConfig[0].columnName,
          columnConfig[6]?.showColumn,
          true,
          isDollarTransfer,
          footerRibbonText,
          isMobileTableFooter,
          showPercentLeftToTransfer,
        ),
        backgroundColor: getBackgroundColor(
          fromPreviewscreen,
          editedRowsLength,
          isAllocationTotalError,
        ),
        color: getFooterTextColor(
          fromPreviewscreen,
          editedRowsLength,
          isAllocationTotalError,
        ),
        textAlign: !isMobileTableFooter ? 'right' : 'left',
        borderLeftWidth: !isMobileTableFooter ? 1 : 0,
      }
    : {
        ...style.totalCell,
        ...style.footerLabel,
        width: getFooterColumnWidth(
          columnConfig[0].columnName,
          columnConfig[6]?.showColumn,
          false,
          isDollarTransfer,
          footerRibbonText,
          isMobileTableFooter,
          showPercentLeftToTransfer,
        ),
        paddingLeft: 12,
        textAlign: 'left',
        borderLeftWidth: !isMobileTableFooter ? 1 : 0,
      };
  return styles;
};

const getTotalValueStyle = (totalValueParameters): StyleProp<TextStyle> => {
  const {
    fullHeight,
    editedRowsLength,
    isAllocationTotalError,
    columnWidthArr,
    showAlternateRowColors,
    index,
    isMobileTableFooter,
    isDollarTransfer,
    footerRibbonText,
    showPercentLeftToTransfer,
    fromPreviewscreen,
  } = totalValueParameters;
  let styles: StyleProp<TextStyle> = fullHeight
    ? {
        ...style.totalCellFullHeight,
        width: getTotalValueWidth(
          isMobileTableFooter,
          isDollarTransfer,
          showPercentLeftToTransfer,
          footerRibbonText,
          columnWidthArr,
          index,
        ),
        backgroundColor: getBackgroundColor(
          fromPreviewscreen,
          editedRowsLength,
          isAllocationTotalError,
        ),
        color: getFooterTextColor(
          fromPreviewscreen,
          editedRowsLength,
          isAllocationTotalError,
        ),
        borderLeftWidth: !isMobileTableFooter ? 1 : 0,
      }
    : {
        ...style.totalCell,
        width: columnWidthArr[index],
      };

  styles = {
    ...styles,
    textAlign: getTextAlignmentStyle(
      editedRowsLength,
      isMobileTableFooter,
      showAlternateRowColors,
    ),
  };

  return styles;
};

export const getTextAlignmentStyle = (
  editedRowsLength,
  isMobileTableFooter,
  showAlternateRowColors,
) => {
  if (isMobileTableFooter || (!editedRowsLength && !showAlternateRowColors)) {
    return 'right';
  } else if (!isMobileTableFooter || showAlternateRowColors) {
    return 'center';
  }
};

export const getFooterColumnWidth = (
  columnName,
  showColumnFlag,
  fullHeight,
  isDollarTransfer,
  footerRibbonText,
  isMobileTableFooter,
  showPercentLeftToTransfer,
) => {
  if (columnName === 'Strategy' && showColumnFlag) {
    return '48%';
  } else if (
    isMobileTableFooter &&
    (isDollarTransfer || showPercentLeftToTransfer)
  ) {
    return '52%';
  } else if (
    !isMobileTableFooter &&
    (isDollarTransfer || showPercentLeftToTransfer) &&
    !footerRibbonText
  ) {
    return '50%';
  } else if (fullHeight) {
    return '85%';
  } else {
    return '70%';
  }
};

export const getTotalValueWidth = (
  isMobileTableFooter,
  isDollarTransfer,
  showPercentLeftToTransfer,
  footerRibbonText,
  columnWidthArr,
  index,
) => {
  if (isMobileTableFooter) {
    if (isDollarTransfer || showPercentLeftToTransfer) {
      return '48%';
    } else {
      return '25%';
    }
  } else if (
    (isDollarTransfer || showPercentLeftToTransfer) &&
    !footerRibbonText
  ) {
    return columnWidthArr[2];
  } else if (index === 4) {
    return '12%';
  } else {
    return '15%';
  }
};

export const getTotalValueByIndex = (
  index,
  isDollarTransfer,
  assetTotal,
  totalPercentageByValue,
) => {
  switch (index) {
    case 2:
    case 4:
      return formatCurrency(assetTotal);
    case 5:
    case 6:
      return totalPercentageByValue + '%';
    case 1:
    default:
      return isDollarTransfer ? '$' + assetTotal : assetTotal + '%';
  }
};
export const createFooter = (footerParameters) => {
  const {
    columnConfig,
    columnWidthArr,
    totalValue,
    totalPercentageByValue,
    fullHeight,
    isAllocationTotalError,
    editedRowsLength,
    footerRibbonText,
    isDollarTransfer,
    showAlternateRowColors,
    isMobileTableFooter,
    showPercentLeftToTransfer,
    fromPreviewscreen,
  } = footerParameters;
  return (
    <>
      <Components.Text style={getFooterStyle(footerParameters)}>
        {footerRibbonText !== null &&
        footerRibbonText !== undefined &&
        footerRibbonText !== ''
          ? footerRibbonText
          : 'Total Account Value'}
      </Components.Text>
      {columnConfig.map((arr, index) => {
        if (
          index > 0 &&
          columnConfig[index].showColumn &&
          columnConfig[index].isTotal
        ) {
          const totalValueParameters = {
            fullHeight,
            editedRowsLength,
            isAllocationTotalError,
            columnWidthArr,
            showAlternateRowColors,
            index,
            isMobileTableFooter,
            isDollarTransfer,
            footerRibbonText,
            showPercentLeftToTransfer,
            fromPreviewscreen,
          };
          return (
            <Fragment key={arr.columnName}>
              <Components.Text
                className="text-right"
                style={getTotalValueStyle(totalValueParameters)}>
                {getTotalValueByIndex(
                  index,
                  isDollarTransfer,
                  totalValue,
                  totalPercentageByValue,
                )}
              </Components.Text>
              {(isDollarTransfer || showPercentLeftToTransfer) &&
                !footerRibbonText &&
                !isMobileTableFooter && (
                  <Components.Text
                    style={
                      fullHeight
                        ? {
                            ...style.totalCellFullHeight,
                            width: showPercentLeftToTransfer ? '27%' : '39%',
                          }
                        : {
                            ...style.totalCell,
                            width: showPercentLeftToTransfer ? '27%' : '39%',
                          }
                    }>
                    {' '}
                  </Components.Text>
                )}
            </Fragment>
          );
        }
      })}
    </>
  );
};
const prepareColumnWithPDFIcon = (pdfColParams) => {
  const {styles, item} = pdfColParams;
  const cleanInvestmentName = item?.investmentName?.split('<')[0].trim();
  return (
    <>
      {item?.investmentSheetURL && (
        <FontAwesomeIcon
          testID="fpdIcon"
          style={styles.faFilePdfIcon as FontAwesomeIconStyle}
          icon={faFilePdf as IconProp}
          color={ColorConstants.iceBlue}
        />
      )}
      <View style={{flex: 1}}>
        {item?.investmentSheetURL ? (
          <Components.Link
            style={styles.linkStyle}
            linkStyle={{
              ...styles.linkStyle,
              fontFamily: item.isExpanded
                ? Font.medium.fontFamily
                : Font.regular.fontFamily,
            }}
            linkHoverStyle={styles.linkHoverStyle}
            onPress={() => openURL(item?.investmentSheetURL)}>
            {/* {item.investmentName} */}
            {cleanInvestmentName}
          </Components.Link>
        ) : (
          <Components.Text
            style={{
              ...styles.nolinkTextStyle,
              fontFamily: item.isExpanded
                ? Font.medium.fontFamily
                : Font.regular.fontFamily,
            }}>
            {/* {item.investmentName} */}
            {cleanInvestmentName}
          </Components.Text>
        )}
      </View>
    </>
  );
};

const prepareEditableColumn = (showOtherAssetColumnsParams) => {
  const {
    arr,
    columnWidthArr,
    styles,
    item,
    index,
    idx,
    showBorder,
    handleEdit,
    isDollarOrPercent,
  } = showOtherAssetColumnsParams;
  return (
    <View
      key={index}
      style={[
        styles.tableEditCell,
        styles.leftBorder,
        styles.rightBordergrey,
        styles.cellPadding,
        {
          width: columnWidthArr[index],
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        },
        !showBorder && styles.noVerticalBorder,
      ]}>
      <View
        style={[
          {
            display: 'flex',
            flexDirection: 'row',
          },
        ]}>
        <TextInput
          editable={isTextInputEditable(arr.columnName, item)}
          selectTextOnFocus={isTextInputEditable(arr.columnName, item)}
          key={index}
          inputMode={getInputMode(arr)}
          value={getTextInputValue(arr.columnName, item)}
          onChangeText={(value) => {
            if (
              (isNaN(Number(value.trim())) && value.trim() !== '') ||
              value.split('.')[1]?.length > 2
            ) {
              return;
            } else {
              let parsedValue;
              if (value.trim() === '') {
                parsedValue = value.trim();
              } else if (
                value.trim().endsWith('.') ||
                value.trim().endsWith('.0')
              ) {
                parsedValue = value.trim();
              } else if (arr.format === 'decimal') {
                parsedValue = Number.parseFloat(value.trim());
              } else {
                parsedValue = Number.parseInt(value.trim());
              }
              handleEdit(item, parsedValue, idx, getObjectKey(arr.columnName));
            }
          }}
          className={getTextAlignment(arr?.textAlign)}
          style={getEditableTextInputStyle(
            item,
            isDollarOrPercent,
            arr.columnName,
          )}
        />
        {isPercentVisible(arr.columnName) && (
          <Components.Text style={styles.textStyle}>%</Components.Text>
        )}
      </View>
      {item.isError && getMinMaxValidationsMsg(arr.columnName, item, styles)}
    </View>
  );
};

const getEditableTextInputStyle = (
  item,
  isDollarOrPercent,
  columnName,
): StyleProp<TextStyle> => {
  let styles: StyleProp<TextStyle> =
    item.isError && isDollarOrPercent
      ? {
          ...style.textErrorStyle,
          backgroundColor: isTextInputEditable(columnName, item)
            ? '#FFFFFF'
            : Color.table.row.oddBackground,
        }
      : {
          ...style.textInputStyle,
          backgroundColor: isTextInputEditable(columnName, item)
            ? '#FFFFFF'
            : Color.table.row.oddBackground,
        };

  if (
    !isDollarOrPercent &&
    isTextInputEditable(columnName, item) &&
    (getTextInputValue(columnName, item) === '' ||
      getErrorMessage(columnName, item) !== '')
  ) {
    styles = {...styles, ...style.textErrorStyle};
  }
  return styles;
};

const prepareNonEditableColumn = (colParams) => {
  const {arr, item, index} = colParams;

  return (
    <Components.Text
      key={index}
      className={getTextAlignment(arr?.textAlign)}
      style={{
        width: '100%',
        fontSize: [
          'Current %',
          'Current Value',
          'Transfer from',
          'Transfer to',
          'Reallocated Balance',
          'New %',
        ].includes(arr.columnName)
          ? 15
          : 14,
        fontFamily: item.isExpanded
          ? Font.medium.fontFamily
          : Font.regular.fontFamily,
        fontWeight:
          (arr.columnName === 'New %' &&
            item?.y !== +item?.newPercentage &&
            item?.newPercentage >= 0) ||
          (arr.columnName === 'Reallocated Balance' &&
            (item.z !== item.fromValue || item.y !== item.toValue))
            ? '700'
            : '400',
      }}>
      {getAmountTextInputValue(arr, item)}
    </Components.Text>
  );
};
