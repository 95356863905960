import React, {useState} from 'react';
import {Linking, View, ViewStyle} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import {DrawerNavigationProp} from '@react-navigation/drawer';
import {useSelector} from 'react-redux';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faChevronDown} from '@fortawesome/pro-light-svg-icons/faChevronDown';
import {faChevronUp} from '@fortawesome/pro-light-svg-icons/faChevronUp';
import {Components, Theme} from '@oneamerica/dxp-ui-components';
import responsiveStyles from './style';
import RouteConstants from '../../constants/RouteConstants';
import {getDecodedCookie} from '../../utils/cookies';
import {
  AEM_API_BASE_URL,
  ACCOUNT_NAVIGATOR_URL_PH,
} from '../../config/getEnvironmentVars';
import {trackCustomEvent} from '../../utils/Analytics/AdobeAnalyticsUtils';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';

interface DrawerContentProps {
  width: number;
  authLogout: () => void;
}

const DrawerContent = ({width, authLogout}: DrawerContentProps) => {
  const style = responsiveStyles(width);
  const navigation = useNavigation<DrawerNavigationProp<any>>();

  const {...policyData} = useSelector((state: any) => state.policies);
  const strategiesData =
    policyData.getPoliciesListData?.data?.policySummary?.policyHighlights[0]
      ?.accountConfig.allowMyStrategies === true;

  const investmentsData =
    policyData.getPoliciesListData?.data?.policySummary?.policyHighlights[0]
      .investments;
  const investmentFlag =
    policyData.getPoliciesListData?.data?.policySummary?.policyHighlights[0]
      ?.accountConfig.allowMyInvestments === true;

  const accountNavMenu = getDecodedCookie('ACCOUNT_DETAILS');
  const accountNavHeader = accountNavMenu?.BacktoAccNav;

  const [showManageAccountDropdown, setShowManageAccountDropdown] =
    useState(false);

  const reportFlag =
    policyData.getPoliciesListData?.data?.policySummary?.policyHighlights[0]
      ?.accountConfig.allowReports === true;

  type DropdownItem = {
    title: string;
    route: string;
    bold: boolean;
    params?: {[key: string]: string};
  };

  type DropdownDivider = {
    divider: true;
    title: 'string';
  };

  const manageAccountDropdown: (DropdownItem | DropdownDivider)[] = [];

  if (investmentFlag && investmentsData && investmentsData.length > 0) {
    manageAccountDropdown.push(
      {
        title: 'MY INVESTMENTS',
        route: RouteConstants.myinvestments,
        bold: false,
      },
      {
        title: 'CURRENT BALANCE',
        route: RouteConstants.myinvestments,
        bold: false,
        params: {tab: 'Current-Balance'},
      },
      {
        title: 'FUTURE ELECTIONS',
        route: RouteConstants.myinvestments,
        bold: false,
        params: {tab: 'Future-Elections'},
      },
    );
  }

  if (strategiesData) {
    manageAccountDropdown.push({
      title: 'MY STRATEGIES',
      route: RouteConstants.mystrategies,
      bold: true,
    });
  }
  if (reportFlag) {
    manageAccountDropdown.push({
      title: 'MY REPORTS',
      route: RouteConstants.myreports,
      bold: true,
    });
  }
  manageAccountDropdown.push({
    title: 'POLICY SERVICE REQUEST',
    route: RouteConstants.policyservicerequest,
    bold: true,
  });
  const openURL = (url) => {
    Linking.openURL(url).catch((err) =>
      console.error('An error occurred', err),
    );
  };

  return (
    <View style={{backgroundColor: Theme.Color.oaBlue, height: '100%'}}>
      <Components.Link
        className="mx-4 md:mx-8 py-2 lg:h-[45px]"
        accessibilityRole="button"
        textStyle={[style.oaButtonStyle, style.buttonStyle] as ViewStyle}
        hoverStyle={style.linkHoverStyle}
        focusStyle={style.linkHoverStyle}
        onPress={() => navigation.navigate(RouteConstants.home)}>
        HOME
      </Components.Link>
      <View style={style.divider} />
      <Components.Link
        className="mx-4 md:mx-8 py-2 lg:h-[45px]"
        accessibilityRole="button"
        textStyle={style.buttonStyle}
        hoverStyle={style.linkHoverStyle}
        focusStyle={style.linkHoverStyle}
        onPress={() => navigation.navigate(RouteConstants.profile)}>
        MY PROFILE
      </Components.Link>
      <View style={style.divider} />
      <Components.Link
        className="mx-4 md:mx-8 py-2 lg:h-[45px]"
        accessibilityRole="button"
        textStyle={style.buttonStyle}
        hoverStyle={style.linkHoverStyle}
        focusStyle={style.linkHoverStyle}
        onPress={() => navigation.navigate(RouteConstants.policydetails)}>
        POLICY DETAILS
      </Components.Link>
      <View style={style.divider} />
      <Components.Link
        className="mx-4 md:mx-8 py-2 lg:h-[45px]"
        accessibilityRole="button"
        textStyle={style.buttonStyle}
        hoverStyle={style.linkHoverStyle}
        focusStyle={style.linkHoverStyle}
        icon={
          showManageAccountDropdown
            ? (faChevronUp as IconProp)
            : (faChevronDown as IconProp)
        }
        iconSide="right"
        onPress={() =>
          setShowManageAccountDropdown(!showManageAccountDropdown)
        }>
        MANAGE ACCOUNT
      </Components.Link>
      {showManageAccountDropdown && (
        <View style={style.dropdownMenu}>
          {manageAccountDropdown
            .filter(
              (item, index, self) =>
                self.findIndex(
                  (t) => 'title' in t && t.title === item.title,
                ) === index,
            )
            .map((item, index) => {
              if ('divider' in item) {
                // Render a divider
                return (
                  <View key={`${item.title}_${index}`} style={style.divider} />
                );
              } else if ('title' in item) {
                // Render a link
                return (
                  <Components.Link
                    key={`${item.title}_${index}`}
                    className="mx-4 md:mx-8 py-2 lg:h-[45px]"
                    accessibilityRole="button"
                    textStyle={style.buttonStyle}
                    hoverStyle={style.linkHoverStyle}
                    focusStyle={style.linkHoverStyle}
                    style={style.spacingLeft}
                    onPress={() => {
                      if (item.route) {
                        navigation.navigate(item.route, item.params);
                      }
                    }}>
                    {item.title}
                  </Components.Link>
                );
              } else {
                return null;
              }
            })}
        </View>
      )}
      <View style={style.divider} />
      <Components.Link
        className="mx-4 md:mx-8 py-2 lg:h-[45px]"
        accessibilityRole="button"
        textStyle={style.buttonStyle}
        hoverStyle={style.linkHoverStyle}
        focusStyle={style.linkHoverStyle}
        onPress={() => {
          navigation.navigate(RouteConstants.contactus);
          trackCustomEvent(analyticsTags.CaptureContactUsDrawerContentTags);
        }}>
        CONTACT US
      </Components.Link>
      <View style={style.divider} />
      <Components.Link
        className="mx-4 md:mx-8 py-2 lg:h-[45px]"
        accessibilityRole="button"
        textStyle={style.buttonStyle}
        hoverStyle={style.linkHoverStyle}
        focusStyle={style.linkHoverStyle}
        onPress={() => openURL(AEM_API_BASE_URL)}>
        ONEAMERICA.COM
      </Components.Link>
      <View style={style.divider} />

      {accountNavHeader ? (
        <>
          <Components.Link
            className="mx-4 md:mx-8 py-2 lg:h-[45px]"
            accessibilityRole="button"
            textStyle={style.buttonStyle}
            hoverStyle={style.linkHoverStyle}
            focusStyle={style.linkHoverStyle}
            onPress={() => openURL(ACCOUNT_NAVIGATOR_URL_PH)}>
            ACCOUNT NAVIGATOR
          </Components.Link>
          <View style={style.divider} />
        </>
      ) : null}
      <Components.Link
        className="mx-4 md:mx-8 py-2 lg:h-[45px]"
        accessibilityRole="button"
        textStyle={style.buttonStyle}
        hoverStyle={style.linkHoverStyle}
        focusStyle={style.linkHoverStyle}
        onPress={authLogout}>
        LOG OUT
      </Components.Link>
      <View style={style.divider} />
    </View>
  );
};
export default DrawerContent;
