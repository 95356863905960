import React, {useState, useEffect} from 'react';
import {Platform} from 'react-native';
import {
  NavigationContainer,
  useNavigationContainerRef,
} from '@react-navigation/native';
import {useDispatch} from 'react-redux';
import {Theme} from '@oneamerica/dxp-ui-components';
import getLinks from './linking';
import DrawerNavigator from './Drawer/Drawer';
import withAuth from '../auth/withAuth';
import RouteConstants from '../constants/RouteConstants';
import {titleCase} from '../utils';
import {GET_POLICY_LIST_DATA} from '../redux/policylists/types';
import useAuthRefresh from '../auth/useAuthRefresh';
import useSessionTimeout from '../auth/useSessionTimeout';
import useSessionInactivity from '../auth/useSessionInactivity';

const Navigation = (props) => {
  const [currentRoute, setCurrentRoute] = useState(RouteConstants.home);
  const navigationRef = useNavigationContainerRef();
  const dispatch = useDispatch();
  const {accessToken, authLogout} = props;
  const TOKEN_EXPIRY_RELATIVE_TIME_IN_MINUTES =
    Math.floor(
      (+new Date(accessToken?.expiresAt * 1000) - +new Date()) / (1000 * 60),
    ) || 13;

  useAuthRefresh(TOKEN_EXPIRY_RELATIVE_TIME_IN_MINUTES);
  useSessionTimeout(authLogout, TOKEN_EXPIRY_RELATIVE_TIME_IN_MINUTES);
  const {TimeoutModal} = useSessionInactivity(authLogout, 13);

  const setNavState = (state) => {
    const route = state?.routeNames?.[state?.index];
    if (route) {
      route !== currentRoute && setCurrentRoute(route);
    }
  };

  const onReady = () => {
    setNavState(navigationRef?.current?.getRootState());
  };

  // set nav info on change
  const onStateChange = (state) => {
    // dispatch redux action to force regular token refresh check
    setNavState(state);
  };

  useEffect(() => {
    dispatch({
      type: GET_POLICY_LIST_DATA,
      payload: {
        token: props?.accessToken?.accessToken,
        selectedPolicy: props?.initialState?.account,
      },
    });
  }, [props.accessToken]);

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={{
        prefixes: ['localhost:8080', '127.0.0.1:8080'],
        config: {
          screens: {'/': {screens: getLinks()}},
        },
      }}
      documentTitle={{
        formatter: (options, route) =>
          `${
            options?.documentTitle ??
            options?.title ??
            titleCase(route?.name.replaceAll('-', ' '))
          } - OneAmerica Financial`,
      }}
      onStateChange={onStateChange}
      onReady={onReady}>
      <Theme.ResponsiveProvider>
        <>
          <DrawerNavigator
            currentRoute={currentRoute}
            authLogout={() => {
              if (Platform.OS === 'web') {
                // @ts-ignore
                window.localStorage.removeItem('isBannerHidden');
              }
              authLogout();
            }}
            initialState={props.initialState}
            accessToken={props?.accessToken?.accessToken}
          />
          {TimeoutModal}
        </>
      </Theme.ResponsiveProvider>
    </NavigationContainer>
  );
};

export default withAuth(Navigation);
