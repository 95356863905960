export default {
  home: 'Home',
  profile: 'My-Profile',
  policydetails: 'Policy-Details',
  policyservicerequest: 'Policy-Service-Request',
  myinvestments: 'My-Investments',
  mystrategies: 'My-Strategies',
  myinvestmentelections: 'My-Investment-Elections',
  contactus: 'Contact-Us',
  transferbalance: 'Transfer-Balance',
  percenttransfer: 'Percent-Transfer',
  dollartransfer: 'Dollar-Transfer',
  myreports: 'My-Reports',
} as const;
