import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getProfileData} from '../../../redux/profileDetails/action';

export const useProfileDetailsSelector = (props) => {
  const dispatch = useDispatch();
  const profileData = useSelector((state: any) => state.profileData);
  const {
    getProfileResponsiveData = {},
    isProfileUpdated = false,
    isError = false,
    loading = true,
    error = {},
  } = profileData || {};
  useEffect(() => {
    if (Object.keys(getProfileResponsiveData).length === 0) {
      dispatch(getProfileData(props?.accessToken?.accessToken));
    }
  }, []);
  return {
    isError,
    error,
    loading,
    profileData,
    getProfileResponsiveData,
    isProfileUpdated,
  };
};
