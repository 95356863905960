import React from 'react';
import {View} from 'react-native';
import {Components} from '@oneamerica/dxp-ui-components';
import responsiveStyle from './style';
import {getPolicyNotes} from '../../helpers';
import {useHomeSelector} from '../../hooks/home';

export const PolicyNotes = ({policyNotes, shouldShowMargin = false}, props) => {
  const style = responsiveStyle();
  const {generalPolicyData, generalPolicyDataRender} = useHomeSelector(props);
  const generalNote = generalPolicyDataRender?.policyNotes
    ? getPolicyNotes(generalPolicyData?.policyNotes)
    : null;

  return (
    <View
      style={[
        style.notesContent,
        {marginHorizontal: shouldShowMargin ? 32 : 0},
      ]}>
      <Components.Text style={style.notesText}>Notes</Components.Text>
      <View className="mt-4 mb-6" style={style.horizontalDivider} />
      {generalPolicyDataRender?.policyNotes && generalNote && (
        <View
          style={{
            flexDirection: 'row',
            marginBottom: 8,
          }}>
          <Components.Text
            style={{fontSize: 20, lineHeight: 20, marginRight: 5}}>
            {'\u2022'}
          </Components.Text>
          <Components.Text style={[style.disclaimerText, {flex: 1}]}>
            {getPolicyNotes(generalPolicyData?.policyNotes)}
          </Components.Text>
        </View>
      )}

      {policyNotes.map((item, index) => {
        if (item.disclaimerText) {
          const regex = /Requested Specified Amount\s+([\d,]+\.?\d*)/g;
          const formattedText = item.disclaimerText.replace(
            regex,
            'Requested Specified Amount $$$1',
          );
          return (
            <View
              key={`${item.disclaimerText}_${index}`}
              style={{
                flexDirection: 'row',
                marginBottom: 8,
              }}>
              <Components.Text
                style={{fontSize: 20, lineHeight: 20, marginRight: 5}}>
                {'\u2022'}
              </Components.Text>
              <Components.Text style={[style.disclaimerText, {flex: 1}]}>
                {formattedText}
              </Components.Text>
            </View>
          );
        }
      })}
    </View>
  );
};
