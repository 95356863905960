import React, {useEffect, useState} from 'react';
import {Linking, View} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import {Components, Button} from '@oneamerica/dxp-ui-components';
import withAuth from '../../auth/withAuth';
import ScreenWrapper from '../../components/ScreenWrapper/ScreenWrapper';
import Breadcrumbs from '../../components/Breadcrumbs';
import responsiveStyle from './style';
import {GET_POLICY_SERVICE_REQUEST_DATA} from '../../redux/policyservicerequest/types';
import data from '../../mock/aemPolicyServiceRequest.json';
import {useNavigation} from '@react-navigation/native';
import RouteConstants from '../../constants/RouteConstants';
import PolicyDropdown from '../../components/PolicyDropdown';
import ErrorWrapper from '../../components/ErrorWrapper';
import {analyticsTags} from '../../utils/Analytics/AnalyticsTags';
import {
  trackCustomEvent,
  trackPage,
} from '../../utils/Analytics/AdobeAnalyticsUtils';
import {PSR_IFRAME_SRC} from '../../config/getEnvironmentVars';

const PolicyServiceRequest = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.policyServiceRequest);
  const [content, setContent] = useState([]);
  const styles = responsiveStyle();
  const navigation = useNavigation<any>();
  const navigateToContactUs = () => {
    navigation.navigate(RouteConstants.contactus);
  };
  const linkStyleContactUs = {
    style: styles.linkContactUs,
    linkStyle: styles.linkContactUs,
    linkHoverStyle: styles.linkContactUs,
    hoverStyle: styles.linkContactUsHover,
    focusStyle: styles.linkContactUsFocus,
  };
  const parseAemContent = (jsonData) => {
    const contentItems =
      jsonData?.map((item, index) => {
        const string = (item?.plaintext as string).split(':');
        return (
          <Components.Text key={`${item?.plaintext}_${index}`}>
            <Components.Text style={[styles.robotoMedium, styles.font14]}>
              {string[0]}:
            </Components.Text>
            <Components.Text style={styles.font14}>
              {string.splice(1, string.length).join(':')}
            </Components.Text>
          </Components.Text>
        );
      }) ?? [];
    setContent(contentItems);
  };

  const getPageTitle = () => {
    return data.data.policyServiceRequestPageByPath.item.pageTitle;
  };

  const getCardTitle = () => {
    return data.data.policyServiceRequestPageByPath.item.cardTitle;
  };

  useEffect(() => {
    trackPage(analyticsTags.createPolicyServiceRequestPageViewTags);
    dispatch({type: GET_POLICY_SERVICE_REQUEST_DATA});
  }, []);

  useEffect(() => {
    parseAemContent(
      state?.data
        ? state?.data?.data.policyServiceRequestPageByPath.item
            .policyServiceRequestInformation
        : data.data.policyServiceRequestPageByPath.item
            .policyServiceRequestInformation,
    );
  }, [state]);

  return (
    <ScreenWrapper scrollFooter paddingTop paddingBottom paddingHorizontal>
      <Breadcrumbs />
      <PolicyDropdown
        className={'my-6'}
        accessToken={props.accessToken}
        text={getPageTitle()}
      />
      <ErrorWrapper>
        <Components.Card>
          <Components.Text style={styles.font16}>
            {getCardTitle()}
          </Components.Text>
          <Components.List flatList={content} className="mt-4" />
          <View style={[styles.horizontalDivider, styles.marginVertical16]} />
          <Button
            accessibilityRole="button"
            onPress={() => {
              trackCustomEvent(analyticsTags.CaptureStartRequestLinkTags);
              Linking.openURL(PSR_IFRAME_SRC);
            }}>
            Start the Policy Request Process
          </Button>
          <View style={[styles.horizontalDivider, styles.marginVertical16]} />
          <View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
            <Components.Text style={styles.contactUsText}>
              For additional assistance, please reference the{' '}
              <Components.Link
                onPress={() => {
                  trackCustomEvent(analyticsTags.CaptureContactUsPSRTags);
                  navigateToContactUs();
                }}
                accessibilityLabel="Contact Us"
                accessibilityRole="button"
                {...linkStyleContactUs}>
                Contact Us
              </Components.Link>{' '}
              page.
            </Components.Text>
          </View>
        </Components.Card>
      </ErrorWrapper>
    </ScreenWrapper>
  );
};

export default withAuth(PolicyServiceRequest);
