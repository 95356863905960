import React, {useEffect} from 'react';
import {View} from 'react-native';
import {useSelector, useDispatch} from 'react-redux';
import {Components} from '@oneamerica/dxp-ui-components';
import {AlertLevel} from '../../constants/UtilsConstants';
import {getNotificationRequest} from '../../redux/notification/actions';
import {getGenericNotificationRequest} from '../../redux/genericnotification/actions';

export const Notification = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNotificationRequest());
    dispatch(getGenericNotificationRequest());
  }, []);

  const notificationState = useSelector(
    (state: any) => state.notification?.notificationData?.notificationItems,
  );
  const genericNotificationState = useSelector(
    (state: any) =>
      state.genericNotification?.genericNotificationData?.notificationItems,
  );

  const displayNotification = (startDateVal, endDateVal) => {
    const now = new Date();
    const nowTime = new Date(
      now.toLocaleString('en-US', {timeZone: 'America/New_york'}),
    );
    const startDate: Date = new Date(startDateVal);
    const start = new Date(
      startDate.toLocaleString('en-US', {timeZone: 'America/New_york'}),
    );
    const endDate = new Date(endDateVal);
    const end = new Date(
      endDate.toLocaleString('en-US', {timeZone: 'America/New_york'}),
    );

    if (startDateVal && !endDateVal) {
      return nowTime >= start;
    }

    if (endDateVal && !startDateVal) {
      return nowTime <= end;
    }

    if (startDateVal && endDateVal) {
      return nowTime >= start && nowTime <= end;
    }
  };

  return (
    <View
      className={`flex flex-col ${
        genericNotificationState?.some(
          (notification) => notification?.priority === 'true',
        ) &&
        notificationState?.some(
          (notification) => notification?.priority !== 'true',
        )
          ? 'flex-col-reverse'
          : ''
      }`}>
      <View>
        {notificationState
          ? Object.keys(notificationState).map((key) => {
              const notification = notificationState[key];
              const isActive = displayNotification(
                notification?.startDateAndTime,
                notification?.endDateAndTime,
              );
              if (
                isActive &&
                notification?.displayNotification === 'true' &&
                notification?.applications?.includes('policy-holder')
              ) {
                if (notification?.alertType === 'info') {
                  return (
                    <Components.AlertMessage
                      key={key}
                      alertLevel={AlertLevel.INFORMATION}
                      title={notification.notificationMessage}
                      showCloseButton={true}
                    />
                  );
                } else if (notification?.alertType === 'warning') {
                  return (
                    <Components.AlertMessage
                      key={key}
                      alertLevel={AlertLevel.ALERT}
                      title={notification.notificationMessage}
                      showCloseButton={true}
                    />
                  );
                } else if (notification?.alertType === 'error') {
                  return (
                    <Components.AlertMessage
                      key={key}
                      alertLevel={AlertLevel.ERROR}
                      title={notification.notificationMessage}
                      showCloseButton={true}
                    />
                  );
                } else if (notification?.alertType === 'holiday') {
                  return (
                    <Components.AlertMessage
                      key={key}
                      alertLevel={AlertLevel.SUCCESS}
                      title={notification.notificationMessage}
                      showCloseButton={true}
                    />
                  );
                }
              }
            })
          : null}
      </View>
      <View>
        {genericNotificationState
          ? Object.keys(genericNotificationState).map((key) => {
              const genericNotification = genericNotificationState[key];
              const isActive = displayNotification(
                genericNotification?.startDateAndTime,
                genericNotification?.endDateAndTime,
              );
              if (
                isActive &&
                genericNotification?.displayNotification === 'true' &&
                genericNotification?.applications?.includes('all-application')
              ) {
                if (genericNotification?.alertType === 'info') {
                  return (
                    <Components.AlertMessage
                      key={key}
                      alertLevel={AlertLevel.INFORMATION}
                      title={genericNotification?.notificationMessage}
                      showCloseButton={true}
                    />
                  );
                } else if (genericNotification?.alertType === 'warning') {
                  return (
                    <Components.AlertMessage
                      key={key}
                      alertLevel={AlertLevel.ALERT}
                      title={genericNotification?.notificationMessage}
                      showCloseButton={true}
                    />
                  );
                } else if (genericNotification?.alertType === 'error') {
                  return (
                    <Components.AlertMessage
                      key={key}
                      alertLevel={AlertLevel.ERROR}
                      title={genericNotification?.notificationMessage}
                      showCloseButton={true}
                    />
                  );
                } else if (genericNotification?.alertType === 'holiday') {
                  return (
                    <Components.AlertMessage
                      key={key}
                      alertLevel={AlertLevel.SUCCESS}
                      title={genericNotification.notificationMessage}
                      showCloseButton={true}
                    />
                  );
                }
              }
            })
          : null}
      </View>
    </View>
  );
};
