import React, {useState} from 'react';
import {Pressable, View} from 'react-native';
import {Text, Theme, Metrics} from '@oneamerica/dxp-ui-components';
import responsiveStyles from '../styles';

export type SelectItemProps = {
  label: string;
  onPress: (val: string) => void;
  selectedValue?: string;
  testID?: string;
  disabled?: boolean;
};

const backgroundColor = (
  isHover: boolean,
  selectedValue: string | undefined,
  label: string,
): string => {
  let _backgroundColor = 'white';

  if (isHover || selectedValue === label) {
    _backgroundColor = Theme.Color.backgroundGray;
  }

  return _backgroundColor;
};

const SelectItem = ({
  label,
  selectedValue,
  onPress,
  testID,
  disabled,
}: SelectItemProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const styles = responsiveStyles(
    false,
    backgroundColor(isHover, selectedValue, label),
  );

  return (
    <Pressable
      onHoverIn={() => setIsHover(true)}
      onHoverOut={() => setIsHover(false)}
      onPress={() => {
        if (!disabled) {
          onPress(label); // Pass the selected item label to onPress in parent
        }
      }}
      testID={testID}
      disabled={disabled}>
      <View style={[styles.selectItem]}>
        <Text
          style={{
            paddingHorizontal: Metrics.medium,
            paddingVertical: Metrics.medium,
            color: disabled ? '#999999' : '#333333',
          }}>
          {label}
        </Text>
      </View>
    </Pressable>
  );
};

export default SelectItem;
