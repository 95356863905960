import React, {useContext} from 'react';
import {View, Platform} from 'react-native';
import {Components} from '@oneamerica/dxp-ui-components';
import {AuthContext} from './AuthProvider';

export default (Component) => {
  const WithAuth = (props) => {
    const {
      isAuthenticated,
      accessToken,
      authClient,
      authLogin,
      authLogout,
      idToken,
      user,
      initialState,
    } = useContext(AuthContext);

    const checkLocalStorage = async () => {
      if (!(await authClient.session.exists())) {
        if (Platform.OS === 'web') {
          // @ts-ignore
          window.localStorage.removeItem('isBannerHidden');
        }
      }
    };

    if (!isAuthenticated) {
      if (!authClient.isLoginRedirect()) {
        authLogin();
        checkLocalStorage();
      }
      //return view for unauthenticated user
      return (
        <View style={{flex: 1, justifyContent: 'center'}}>
          <Components.LoadingIndicator />
        </View>
      );
    }

    return (
      <Component
        {...props}
        isAuthenticated={isAuthenticated}
        authLogin={authLogin}
        authLogout={authLogout}
        accessToken={accessToken}
        idToken={idToken}
        user={user}
        // authClient={authClient}
        initialState={initialState}
      />
    );
  };

  return WithAuth;
};
