import React, {useContext, useMemo, JSX} from 'react';
import {View} from 'react-native';
import {useSelector} from 'react-redux';
import {
  Components,
  Theme,
  Accordion,
  List,
} from '@oneamerica/dxp-ui-components';
import responsiveStyle from './../../style';
import withAuth from '../../../../auth/withAuth';
import BreakpointConstants from '../../../../constants/BreakpointConstants';
import {
  columnConfiguration,
  childRowsColumnConfig,
  columnWidthArr,
} from './columnConfig';
import {prepareCurrentElectionsData} from '../../../../utils';
import InvestmentChart from '../../../../components/InvestmentTable';
import notes from '../../../../mock/notes.json';

const ReviewNewAllocations = (props) => {
  const {width} = useContext(Theme.ResponsiveContext);
  const style = responsiveStyle(width);
  const investments = useSelector(
    (state: any) => state?.investments?.getInvestmentOptionData,
  );
  const selectedTab = props?.route?.params?.tab === 'Future-Elections' ? 1 : 0;
  const isLargeScreen = BreakpointConstants.isLG(width);
  const isMediumScreen = BreakpointConstants.isMD(width);
  let screenType;
  if (isLargeScreen) {
    screenType = 'LG';
  } else if (isMediumScreen) {
    screenType = 'MD';
  } else {
    screenType = 'SM';
  }

  const getPieChartSize = () => {
    if (isLargeScreen) {
      return {height: 319, width: 319};
    }
    return {height: 290, width: 290};
  };
  const renderCards = useMemo(() => {
    const elements: JSX.Element[] = [];
    if (investments?.data?.items[0]?.investments?.length) {
      const investmentFormattedData = prepareCurrentElectionsData(
        props.editedData,
        false,
      );
      props.previousData = investmentFormattedData;
      const mappedData = investmentFormattedData.AllAssets;
      elements.push(
        <InvestmentChart
          className="mt-0"
          key={'review-ioe-table'}
          data={mappedData}
          accountBalance={100}
          showChildRows={false}
          columnWidthArr={columnWidthArr}
          columnConfig={columnConfiguration}
          childRowsColumnConfig={childRowsColumnConfig}
          assetTotal={investmentFormattedData.percentRoundTotal}
          horizontal={!isLargeScreen}
          legendTableTitle={{
            text: '',
            titleStyle: style.headingTable,
          }}
          showPieChart={false}
          showLegendTable={true}
          showAlternateRowColors={false}
          showBorder={false}
          fullHeight={true}
          setIsErrorInTable={props.setIsErrorInTable}
          setIsDirty={props.setIsDirty}
          isMobileTableFooter={!isLargeScreen}
        />,
      );
    }
    return elements;
  }, [screenType, selectedTab, investments]);

  const renderCharts = useMemo(() => {
    const elements: JSX.Element[] = [];
    if (investments?.data?.items[0]?.investments?.length) {
      const tempEditedData = [...props.editedData];
      const investmentFormattedData = prepareCurrentElectionsData(
        props.editedData,
        false,
      );
      const newElectionsData = prepareCurrentElectionsData(
        tempEditedData,
        true,
      );
      props.previousData = investmentFormattedData;
      const mappedData = investmentFormattedData.mappedData;
      const newElectionsMappedData = newElectionsData.mappedData;
      const pieChartSize = getPieChartSize();
      const isMobile = screenType === 'SM';
      elements.push(
        <View
          className="lg:pr-5"
          key={'review-ioe-current-elections-chart'}
          style={style.pieChartColumn}>
          <View>
            <Components.Text style={style.piechartHeading}>
              Current Elections
            </Components.Text>
          </View>
          <InvestmentChart
            className="mt-0"
            data={mappedData}
            accountBalance={100}
            showChildRows={false}
            pieChartHeight={pieChartSize.height}
            pieChartWidth={pieChartSize.width}
            columnWidthArr={columnWidthArr}
            columnConfig={columnConfiguration}
            childRowsColumnConfig={childRowsColumnConfig}
            assetTotal={investmentFormattedData.percentRoundTotal}
            horizontal={isMobile}
            legendTableTitle={{
              text: '',
              titleStyle: style.headingTable,
            }}
            showPieChart={true}
            showLegendTable={false}
            showAlternateRowColors={false}
            fullHeight={true}
            setIsErrorInTable={props.setIsErrorInTable}
            setIsDirty={props.setIsDirty}
          />
        </View>,
      );
      elements.push(
        <View
          className="lg:pl-5"
          key={'review-ioe-new-elections-chart'}
          style={style.pieChartColumn}>
          <View>
            <Components.Text style={style.piechartHeading}>
              New Elections
            </Components.Text>
          </View>
          <InvestmentChart
            className="mt-0"
            data={newElectionsMappedData}
            accountBalance={100}
            showChildRows={false}
            pieChartHeight={pieChartSize.height}
            pieChartWidth={pieChartSize.width}
            columnWidthArr={columnWidthArr}
            columnConfig={columnConfiguration}
            childRowsColumnConfig={childRowsColumnConfig}
            assetTotal={investmentFormattedData.percentRoundTotal}
            horizontal={isMobile}
            legendTableTitle={{
              text: '',
              titleStyle: style.headingTable,
            }}
            showPieChart={true}
            showLegendTable={false}
            showAlternateRowColors={false}
            fullHeight={true}
            setIsErrorInTable={props.setIsErrorInTable}
            setIsDirty={props.setIsDirty}
          />
        </View>,
      );
    }
    return elements;
  }, [screenType, selectedTab, investments]);

  return (
    //Review Page change
    <View>
      <Components.Text.H2
        style={[
          style.tableTitle,
          isLargeScreen ? style.topMargin : style.noTopMargin,
        ]}>
        Review Your Changes
      </Components.Text.H2>
      <Components.Text style={style.placeholderText}>
        Review the details of your future contribution investment elections
        below to confirm that it is set as you intended.
      </Components.Text>
      {/* Tablet and Mobile Notes */}
      <View style={[style.hideLg, style.topMargin]}>
        <Accordion open title="Notes" style={style.accordionBg}>
          <List
            textSize={14}
            style={style.bulletListStyle}
            flatList={notes.data.noteslistReviewIOE.item}
          />
        </Accordion>
      </View>
      <View>
        {renderCards}
        <View
          className="flex-col md:flex-row"
          style={{
            flexDirection: props.flexDirection,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          {renderCharts}
        </View>
      </View>
    </View>
  );
};

export default withAuth(ReviewNewAllocations);
