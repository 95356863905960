import {Platform} from 'react-native';
import {Theme} from '@oneamerica/dxp-ui-components';
import ColorConstants from '../../constants/ColorConstants';
import BreakpointConstants from '../../constants/BreakpointConstants';
const {Color, Metrics, Responsive} = Theme;

const styles = (topInset: number, width: number) => {
  let marginTop: number;
  if (Platform.OS === 'android') {
    marginTop = 50 + topInset;
  } else {
    marginTop = 100 + topInset;
  }

  const getDrawerWebMaxWidth = (width: number) => {
    if (BreakpointConstants.isLG(width)) {
      return '25%';
    }
    if (BreakpointConstants.isMD(width)) {
      return '33.33%';
    }
    return '100%';
  };

  return Responsive.create({
    drawerStyle: {
      width: '100%',
      height: '100%',
      backgroundColor: BreakpointConstants.isMD(width)
        ? ColorConstants.drawerBG
        : Theme.Color.header.background,
      borderColor: Color.drawer.border,
      borderRightWidth: Metrics.borderWidth,
      // Slight pixel discrepancy between web mobile and app
      marginTop,
      shadowColor: Color.backgroundShadow,
      shadowOffset: {width: -2, height: 10},
      shadowRadius: 8,
    },
    drawerWeb: {
      maxWidth: getDrawerWebMaxWidth(width),
    },
  })();
};

export default styles;
