import React from 'react';
import {View, Text} from 'react-native';
import {Components, Font} from '@oneamerica/dxp-ui-components';
import responsiveStyle from '../../style';
import InfoWrapper from '../../../../components/InfoWrapper/InfoWrapper';
import ColorConstants from '../../../../constants/ColorConstants';
import {analyticsTags} from '../../../../utils/Analytics/AnalyticsTags';
import {trackCustomEvent} from '../../../../utils/Analytics/AdobeAnalyticsUtils';
import {formatDate} from '../../../../utils';

export const PolicyValueCard = ({
  className,
  policyValueCardData,
  policyValue,
  hasTotalDeathBenefit,
  morePolicyValue,
  linkStyle,
  navigateToPolicyDetails,
  row,
}) => {
  const style = responsiveStyle();
  return policyValueCardData?.length > 0 ? (
    <View className={className}>
      <View style={[style.cardShadow, row > 1 && style.flex]}>
        <View style={style.cardHeader}>
          <Components.Text.H3 style={[style.cardHeaderTitle, style.flex]}>
            Policy Values
          </Components.Text.H3>
        </View>
        <Text
          style={{
            fontSize: 14,
            fontFamily: Font.light.fontFamily,
            color: ColorConstants.iron,
          }}>
          As of {formatDate(policyValue.valueAsOfDate, 'MMM DD, YYYY', '-')}
        </Text>
        <View className="mt-4" style={style.horizontalDivider} />
        <View style={style.contentWrapper}>
          <View className="py-2" style={style.row}>
            <View className="items-center" style={style.flex}>
              {(policyValueCardData as Array<any>).map((item, index) => {
                return (
                  <View key={`${item.value}_${index}`}>
                    <InfoWrapper
                      key={`${item.value}_${index}`}
                      title={item.title}
                      information={item?.value}
                      subTitle={index !== 0}
                      boldInfo={index === 0}
                      titleTextStyle={{textAlign: 'center'}}
                      centerAlign
                    />
                    {index === 0 && (
                      <View className="my-2" style={style.horizontalDivider} />
                    )}
                  </View>
                );
              })}
            </View>
          </View>
        </View>
        {morePolicyValue.length > 3 && (
          <Components.Link
            accessibilityRole="button"
            accessibilityLabel="View Policy Values Details"
            onPress={() => {
              trackCustomEvent(
                analyticsTags.CapturePolicyValueCardViewDetailsTags,
              );
              navigateToPolicyDetails('PolicyValues');
            }}
            {...linkStyle}>
            View Details
          </Components.Link>
        )}
      </View>
    </View>
  ) : null;
};
