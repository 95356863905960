import React, {useContext, useMemo, JSX} from 'react';
import {View} from 'react-native';
import {useSelector} from 'react-redux';
import {Theme} from '@oneamerica/dxp-ui-components';
import responsiveStyle from './../../style';
import withAuth from '../../../../auth/withAuth';
import BreakpointConstants from '../../../../constants/BreakpointConstants';
import {columnConfiguration, columnWidthArr} from './columnConfig';
import {
  getScreenType,
  prepareTransferInvestmentOptionsData,
} from '../../../../utils';
import InvestmentChart from '../../../../components/InvestmentTable';

const EditTransfer = (props) => {
  const {width} = useContext(Theme.ResponsiveContext);
  const style = responsiveStyle(width);
  const transferBalanceResponseData = useSelector(
    (state: any) => state.transferBalance.getTransferBalanceData,
  );
  const transferBalanceData =
    transferBalanceResponseData.data?.items[0].investmentOptionChanges ?? [];
  const screenType = getScreenType(width);

  const renderCards = useMemo(() => {
    const elements: JSX.Element[] = [];
    if (transferBalanceData.length) {
      const investmentFormattedData =
        prepareTransferInvestmentOptionsData(transferBalanceData);
      const mappedData = investmentFormattedData.AllAssets;
      elements.push(
        <InvestmentChart
          className="mt-0"
          key={'edit-percent-transfer-table'}
          data={props.previousData?.length ? props.previousData : mappedData}
          accountBalance={100}
          showChildRows={false}
          columnWidthArr={columnWidthArr}
          columnConfig={columnConfiguration}
          assetTotal={investmentFormattedData.percentRoundTotal}
          horizontal={!BreakpointConstants.isLG(width)}
          legendTableTitle={{
            text: '',
            titleStyle: style.headingTable,
          }}
          showPieChart={false}
          showLegendTable={true}
          showAlternateRowColors={true}
          fullHeight={true}
          setIsErrorInTable={props.setIsErrorInTable}
          setIsDirty={props.setIsDirty}
          getEditedData={props.getEditedData}
          footerRibbonText={'Percentage left to transfer'}
          previousClicked={props.previousClicked}
          isMobileTableFooter={!BreakpointConstants.isLG(width)}
        />,
      );
    }
    return elements;
  }, [screenType, transferBalanceData]);

  return <View>{renderCards}</View>;
};

export default withAuth(EditTransfer);
